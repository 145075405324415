import PageWithTitle from "@/components/templates/PageWithTitle";
import PageLoader from "@/components/ui/PageLoader";
import { demoteHeadings } from "@/lib/utils";
import { useQuery } from "@tanstack/react-query";

export default function Changelog() {
  const { isLoading, isError, data } = useQuery({
    queryKey: ["changelog"],
    queryFn: changelogQueryFunction,
  });
  return (
    <PageWithTitle title="Changelog">
      {isLoading ? (
        <PageLoader />
      ) : isError ? (
        <p>Error loading page. Please refresh.</p>
      ) : (
        <div className="mx-auto prose xl:prose-lg prose-slate">
          <div
            dangerouslySetInnerHTML={{
              __html: demoteHeadings(data.data.attributes.content),
            }}
          />
        </div>
      )}
    </PageWithTitle>
  );
}

function changelogQueryFunction() {
  return fetch(`${import.meta.env.VITE_STRAPI_URL}/api/changelog`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${import.meta.env.VITE_STRAPI_TOKEN}`,
    },
  }).then((res) => res.json());
}
