import { TypographyH3 } from "@/components/ui/Typography";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { zStringRequired } from "@/lib/schemas/helpers";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import AlertDialogWithActions from "./AlertDialogWithActions";

type ApproveRejectFormProps = {
  sendApproval: () => void;
  sendRejection: (reason: string) => void;
  title?: string;
  rejectionReason?: string;
};

export default function ApproveRejectForm({
  sendApproval,
  sendRejection,
  title,
  rejectionReason,
}: ApproveRejectFormProps) {
  const rejectionForm = useForm({
    resolver: zodResolver(z.object({ reason: zStringRequired })),
    defaultValues: { reason: "" },
  });
  function handleApproveRequest() {
    sendApproval();
  }

  const handleRejection = rejectionForm.handleSubmit(({ reason }) => {
    sendRejection(reason);
  });
  return (
    <div className="max-w-2xl w-full rounded-lg space-y-6">
      {!!title && <TypographyH3>{title}</TypographyH3>}
      {rejectionReason ? (
        <div className="my-4 space-y-3">
          <p>Rejected:</p>
          <p>{rejectionReason}</p>
        </div>
      ) : null}
      <AlertDialogWithActions
        title="Approve"
        action={handleApproveRequest}
        triggerText="Approve"
      />
      {!rejectionReason && (
        <div className="bg-accent p-4 rounded-lg mt-12">
          <TypographyH3 className="text-xl text-accent-foreground font-semibold mb-6">
            Reject With Reason
          </TypographyH3>
          <Form {...rejectionForm}>
            <form className="space-y-2" onSubmit={handleRejection}>
              <FormField
                name="reason"
                control={rejectionForm.control}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Reason For Rejection</FormLabel>
                      <FormControl>
                        <Textarea {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <AlertDialogWithActions
                title="Reject"
                action={handleRejection}
                triggerText="Reject"
                buttonProps={{
                  variant: "destructive",
                  disabled: !rejectionForm.formState.isValid,
                }}
              />
            </form>
          </Form>
        </div>
      )}
    </div>
  );
}
