import PageWithTitle from "@/components/templates/PageWithTitle";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import useUserSignup from "@/lib/hooks/mutations/useUserSignup";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import * as z from "zod";
import type { UserSignupCommand } from "../../../crates/iam/bindings/UserSignupCommand";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

const formSchema = z.object({
  first_name: z.string().min(1).max(80).describe("First Name"),
  last_name: z.string().min(1).max(100).describe("Last Name"),
  email: z.string().email().describe("Email"),
  phone: z.string().optional(),
});

export default function Signup() {
  const signupMutation = useUserSignup();
  const signupForm = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
    },
  });

  const handleSubmit = signupForm.handleSubmit(async function (data) {
    const body: UserSignupCommand = {
      ...data,
      phone: data.phone || null,
      signed_up_at: new Date().toISOString(),
    };
    signupMutation.mutate(body);
  });
  const auth = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate("/dashboard");
    }
  }, [auth]);

  return (
    <PageWithTitle title="Sign Up">
      <div className="mx-auto prose xl:prose-lg prose-slate">
        <p>
          Welcome to the AccountAbility E-licensing platform. To find out more
          about how to use the platform and what to expect when you create an
          account, see the <Link to="/help-and-info">help page</Link>.
        </p>
        <div className="mt-12">
          <Form {...signupForm}>
            <form
              onSubmit={handleSubmit}
              className="space-y-6 max-w-lg mx-auto"
            >
              <FormField
                name="email"
                control={signupForm.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="first_name"
                control={signupForm.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>First Name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="last_name"
                control={signupForm.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Last Name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                name="phone"
                control={signupForm.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Phone</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button
                type="submit"
                disabled={
                  !signupForm.formState.isDirty || signupMutation.isLoading
                }
                variant="outline"
              >
                Continue
              </Button>
            </form>
          </Form>
        </div>
      </div>
    </PageWithTitle>
  );
}
