import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";
import { trpc } from "@/lib/trpc";
import { defaultError, wait } from "@/lib/utils";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "sonner";
import { LicenseApplicationAddCustomAgreementCommand } from "../../../../../crates/e-licensing/bindings/LicenseApplicationAddCustomAgreementCommand";

export default function useLicenseApplicationAddCustomAgreement(
  org_id: string
) {
  const utils = trpc.useUtils();
  const { axiosInstance: axios } = useAuthTokenContext();
  return useMutation({
    mutationFn: (data: LicenseApplicationAddCustomAgreementCommand) => {
      return axios(
        `${
          import.meta.env.VITE_ELIC_API_URL
        }/license-application/add-custom-agreement`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        }
      );
    },
    onSuccess: (_, variables) => {
      toast.success("Agreement added.");
      utils.getOrgById.setData(org_id, (oldData) => {
        return oldData && oldData.license_application
          ? {
              ...oldData,
              license_application: {
                ...oldData.license_application,
                status: "CustomLicenseAgreementAdded",
                custom_license_agreement: {
                  file_details: variables.custom_agreement.file_details,
                  s3_object_details: null,
                },
              },
            }
          : oldData;
      });
      wait(2000).then(() => utils.getOrgById.refetch(org_id));
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
