import * as z from "zod";
import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { TypographyH3, TypographyList } from "@/components/ui/Typography";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import { getCountryLabelFromCode } from "@/lib/countries";
import useOrgUserRequestToJoin from "@/lib/hooks/mutations/useOrgUserRequestToJoin";
import { zStringRequired } from "@/lib/schemas/helpers";
import { trpc } from "@/lib/trpc";
import { cn } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { CheckIcon, ChevronsUpDownIcon } from "lucide-react";
import { UseFormReturn, useForm } from "react-hook-form";
import { RequestToJoinOrgCommand } from "../../../../crates/iam/bindings/RequestToJoinOrgCommand";

type OrgForJoining = {
  legal_company_name: string;
  doing_business_as?: string | null;
  doing_business_as_intl?: string | null;
  business_country?: string | null;
  value: string;
};

const JoinOrgRequestSchema = z.object({
  orgCompositeValue: zStringRequired,
  position_at_company: zStringRequired,
  business_unit: z.string().optional(),
});

export default function JoinOrgRequestPage() {
  const currentUser = useCurrentUserContext();
  const {
    data: orgJoiningData,
    isLoading,
    isError,
  } = trpc.getOrgsForJoining.useQuery();
  const {
    data: pendingMembershipRequests,
    isLoading: pendingRequestsLoading,
    isError: pendingRequestsError,
  } = trpc.getPendingMembershipRequestsByUserId.useQuery(currentUser.user_id);

  const joinRequestMutation = useOrgUserRequestToJoin();
  const userForm = useForm({
    resolver: zodResolver(JoinOrgRequestSchema),
    defaultValues: {
      orgCompositeValue: "",
      business_unit: "",
      position_at_company: "",
    },
  });
  const handleSubmit = userForm.handleSubmit(
    async ({ business_unit, orgCompositeValue, position_at_company }) => {
      const org_id = orgCompositeValue.split("|||")[0].trim();

      const body: RequestToJoinOrgCommand = {
        org_id,
        business_unit,
        position_at_company,
        user_id: currentUser.id,
      };
      joinRequestMutation.mutate(body);
    }
  );

  return (
    <DashboardOutlet title="Request To Join Organization">
      {pendingRequestsLoading ? (
        <p>Loading...</p>
      ) : pendingRequestsError || !pendingMembershipRequests ? (
        <p>Error loading pending membership requests</p>
      ) : pendingMembershipRequests.length ? (
        <div className="p-4 my-12 bg-accent text-accent-foreground max-w-2xl mx-auto lg:mx-0 space-y-8">
          <p>
            You've requested to join an organization. If you think you've made a
            mistake, just make another request to the correct organization.
          </p>
          <TypographyList>
            {pendingMembershipRequests.map((request) => (
              <li key={request.legal_company_name}>
                {request.legal_company_name}
              </li>
            ))}
          </TypographyList>
        </div>
      ) : null}
      <div className="mx-auto lg:mx-0 max-w-2xl">
        <div className="prose xl:prose-lg prose-slate max-w-none">
          <p>
            If your organization is already registered on the e-licensing
            platform, you can search for them here and request to join.
          </p>
        </div>
        <div>
          <div className="p-4 my-12 bg-accent text-accent-foreground">
            <Form {...userForm}>
              <TypographyH3 as="h2" className="mb-8">
                Request To Join
              </TypographyH3>
              <form className="space-y-6" onSubmit={handleSubmit}>
                {isLoading ? (
                  <p>Loading...</p>
                ) : isError ? (
                  <p>Error loading orgs</p>
                ) : (
                  <OrgSearchSelect
                    orgs={orgJoiningData.map((a) => ({
                      legal_company_name: a.legal_company_name,
                      doing_business_as: a.profile?.doing_business_as,
                      doing_business_as_intl: a.profile?.doing_business_as_intl,
                      business_country: a.profile?.business_country
                        ? getCountryLabelFromCode(
                            a.profile?.business_country as any
                          )
                        : null,
                      value: [
                        a.org_id,
                        a.legal_company_name,
                        a.profile?.doing_business_as,
                        a.profile?.doing_business_as_intl,
                      ].join(" ||| "),
                      //`${a.org_id} | ${a.legal_company_name} | ${a.profile?.doing_business_as} | ${a.profile?.doing_business_as_intl}`,
                    }))}
                    form={userForm}
                  />
                )}
                <FormField
                  name="position_at_company"
                  control={userForm.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Position</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormDescription></FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="business_unit"
                  control={userForm.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Business Unit</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormDescription>Optional.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button type="submit" disabled={joinRequestMutation.isLoading}>
                  Submit
                </Button>
              </form>
            </Form>
          </div>
        </div>
      </div>
    </DashboardOutlet>
  );
}

function OrgSearchSelect({
  orgs,
  form,
}: {
  orgs: OrgForJoining[];
  form: UseFormReturn<any, any, any>;
}) {
  return (
    <FormField
      control={form.control}
      name="orgCompositeValue"
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel>Search Organisations</FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "w-full justify-between",
                    !field.value && "text-muted-foreground"
                  )}
                >
                  {field.value
                    ? orgs.find((org) => org.value === field.value)
                        ?.legal_company_name
                    : "Select Organization"}
                  <ChevronsUpDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-full p-0">
              <Command>
                <CommandInput placeholder="Search organisations..." />
                <CommandEmpty>No organization found.</CommandEmpty>
                <CommandGroup className="max-h-80 overflow-y-auto w-96">
                  {orgs.map((org) => (
                    <CommandItem
                      value={org.value}
                      key={org.value}
                      onSelect={() => {
                        form.setValue("orgCompositeValue", org.value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 h-4 w-4",
                          org.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      <OrgLabel {...org} />
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function OrgLabel({
  legal_company_name,
  doing_business_as,
  doing_business_as_intl,
  business_country,
}: {
  legal_company_name: string;
  doing_business_as?: string | null;
  doing_business_as_intl?: string | null;
  business_country?: string | null;
}) {
  return (
    <div className="space-y-1">
      <p className="text-sm">
        {legal_company_name} ({business_country})
      </p>
      {doing_business_as ? (
        <p className="text-xs text-muted-foreground">{doing_business_as}</p>
      ) : null}
      {doing_business_as_intl ? (
        <p className="text-xs text-muted-foreground">
          {doing_business_as_intl}
        </p>
      ) : null}
    </div>
  );
}
