import * as z from "zod";
import {
  uploadSchema,
  yesNo,
  zStringRequired,
  zYesNoRequired,
} from "./helpers";
import { industrySectors } from "./industry-sectors";
import { countryLabels } from "../countries";

const aa1000apHelperText = `Please note:

For a Type 2 engagement, the assurance provider shall assess the extent of the organization’s adherence to all four AA1000 AccountAbility Principles and provide relevant findings and conclusions, and shall, additionally, assess and evidence the reliability and quality of specified sustainability performance and disclosed information, providing relevant findings and conclusions.

The assurance provider shall not accept a Type 2 engagement unless there are suitable criteria for assessing specified performance and disclosed information.
`;

export const preEngagementSpec: {
  [key: string]: {
    label: string;
    helperText?: string;
    options?: string[];
    getOptions?: (input: keyof typeof industrySectors) => string[];
  };
} = {
  independence: {
    label:
      "Has an assessment been conducted on the adherence to the AA1000AS v3 Code of Practice that confirms no impedance to the production of a robust, independent, objective, and impartial assurance statement?",
    options: yesNo,
  },
  csaps: {
    label:
      "Please select any AccountAbility CSAPs who will be involved in the proposed engagement.",
    helperText: "Multiple individuals can be selected.",
  },
  team: {
    label:
      "Other than the CSAPs mentioned, please describe the full assurance team, with competencies of the professionals who will be carrying out the proposed assurance engagement.",
    helperText:
      "Fill in for all proposed engagement team members (except for CSAPs).",
  },
  engagement_agreement: {
    label:
      "Upload the document containing the terms and conditions of the engagement agreed between the assurance provider and the reporting organization. Maximum file size: 10mb. Supported formats: pdf, docx, png, jpg, wepb, avif.",
  },
  assurance_type: {
    label: "What is the type of engagement?",
    options: ["Type 1", "Type 2"],
  },
  assurance_level: {
    label: "What is the level of assurance?",
    options: ["High", "Moderate", "Combination"],
  },
  combination_details: {
    label: "Please describe details of the Combination",
  },
  aa1000ap: {
    label:
      "Will criteria drawn from the AA1000AP (2018) in order to assess adherence to the AccountAbility Principles be used?",
    options: yesNo,
    helperText: aa1000apHelperText,
  },
  other_used_criteria: {
    label: "Will other criteria be used?",
    helperText: "If yes, please list them.",
  },
  disclosures: {
    label:
      "List all disclosures (e.g. reports, website content, mandated filings, or regulatory disclosures) to be covered by the assurance engagement.",
  },
  describe_quality: {
    label:
      "Describe the relevant internal system of quality management adhered to by the assurance engagement team to discharge its obligations in connection with the provision of the AA1000AS v3 for Sustainability Assurance Services with all due skill, care, and diligence",
  },
  quality_requirements: {
    label:
      "Does the internal system of quality management meet or exceed professional requirements, or requirements in law or regulation, regarding the firm’s responsibility for its system of quality management, including but not limited to good industry practice?",
    options: yesNo,
  },
  org_name: {
    label: "Name of reporting organization",
  },
  country_or_region: {
    label: "Country",
    options: countryLabels,
  },
  industry_sector: {
    label: "Industry Sector",
    options: Object.keys(industrySectors),
  },
  industry_subsector: {
    label: "Industry Subsector",
    // @ts-ignore
    getOptions: (sector: keyof typeof industrySectors) =>
      sector ? industrySectors[sector] || [] : [],
  },
  disclosure_title: {
    label: "Disclosure title",
  },
  disclosure_period_year: {
    label: "Year of disclosure period that is assured",
    options: ["2018", ...getYears()],
  },
  disclosure_publication_year: {
    label: "Disclosure publication year",
    options: ["2018", ...getYears()],
  },
  assurance_report_title: {
    label: "Title of the assurance report",
  },
};

const assuranceLevelSchema = z.discriminatedUnion(
  "assurance_level",
  [
    z.object({
      assurance_level: z.literal("High"),
    }),
    z.object({ assurance_level: z.literal("Moderate") }),
    z.object({
      assurance_level: z.literal("Combination", {}),
      combination_details: zStringRequired,
    }),
  ],
  { errorMap: () => ({ message: "Required" }) }
);

export const preEngagementSchema = z
  .object({
    independence: zYesNoRequired,
    csaps: z.array(z.object({ registration_number: z.string() })).optional(),
    team: z
      .array(
        z.object({
          name: zStringRequired,
          sustainability_years: zStringRequired,
          assurance_years: zStringRequired,
          sustainability_competencies: zStringRequired,
          assurance_methodologies: zStringRequired,
        })
      )
      .optional(),
    engagement_agreement: uploadSchema,
    assurance_type: zStringRequired,
    aa1000ap: zYesNoRequired,
    other_used_criteria: z.string().optional(),
    disclosures: zStringRequired,
    describe_quality: zStringRequired,
    quality_requirements: zYesNoRequired,
    org_name: zStringRequired,
    country_or_region: zStringRequired,
    disclosure_title: zStringRequired,
    disclosure_period_year: zStringRequired,
    disclosure_publication_year: zStringRequired,
    assurance_report_title: zStringRequired,
    industry_sector: zStringRequired,
    industry_subsector: zStringRequired,
  })
  .and(assuranceLevelSchema);

function getYears() {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 2019; i <= currentYear + 3; i++) {
    years.push(i);
  }
  return years.map((year) => year.toString());
}

export type PreEngagementType = z.infer<typeof preEngagementSchema>;
