import { trpc } from "@/lib/trpc";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import { OrgSuAddNoteCommand } from "../../../../../crates/iam/bindings/OrgSuAddNoteCommand";
import { defaultError, wait } from "@/lib/utils";
import { AxiosError } from "axios";
import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";

export default function useOrgSuAddNote() {
  const utils = trpc.useUtils();
  const { axiosInstance: axios } = useAuthTokenContext();
  return useMutation({
    mutationFn: (data: OrgSuAddNoteCommand) => {
      return axios({
        url: `${import.meta.env.VITE_IAM_API_URL}/org/su/add-note`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data,
      });
    },
    onSuccess: (_, variables) => {
      toast.success("Note Added");
      utils.getOrgById.setData(variables.org_id, (oldData) => {
        return oldData
          ? {
              ...oldData,
              notes: [...oldData.notes, variables.note],
            }
          : oldData;
      });
      wait(2500).then(() => utils.getOrgById.refetch(variables.org_id));
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
