import { useQuery } from "@tanstack/react-query";
import axios from "axios";

type StrapiTaxCertificate = {
  country: string;
  disable?: boolean;
  file: {
    data: {
      attributes: { url: string };
    } | null;
  };
  special_instructions?: string;
};

async function csapQueryFunction() {
  const { data } = await axios({
    url: `${
      import.meta.env.VITE_STRAPI_URL
    }/api/tax-certificates?pagination[pageSize]=300&populate[file]=*`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${import.meta.env.VITE_STRAPI_TOKEN}`,
    },
  });
  console.log({ data });

  return data;
}

export default function useTaxCertificates() {
  return useQuery<{ data: { attributes: StrapiTaxCertificate }[] }>(
    ["tax-certificates"],
    csapQueryFunction
  );
}
