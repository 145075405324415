import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { Button } from "@/components/ui/button";
import { DataTable, ViewEditButtonLink } from "@/components/ui/data-table";
import { getCountryLabelFromCode } from "@/lib/countries";
import { RouterOutputs, trpc } from "@/lib/trpc";
import { formatFullDate, getUserMembershipFromUser } from "@/lib/utils";
import { ColumnDef } from "@tanstack/react-table";
import { Link } from "react-router-dom";

const taxCertificateColumns: ColumnDef<
  NonNullable<RouterOutputs["getTaxCertificateRequestsByOrgId"]>[number]
>[] = [
  {
    id: "view/edit",
    cell: ({ row }) => (
      <ViewEditButtonLink
        to={`/dashboard/tax-certificate-requests/${row.original.id}`}
      />
    ),
  },
  {
    header: "Country",
    cell: ({ row }) => getCountryLabelFromCode(row.original.country as any),
    accessorFn: (row) => getCountryLabelFromCode(row.country as any),
  },
  {
    header: "Created At",
    cell: ({ row }) => formatFullDate(new Date(row.original.created_at)),
  },
  {
    header: "Resolved At",
    cell: ({ row }) =>
      row.original.resolved_at
        ? formatFullDate(new Date(row.original.resolved_at))
        : "Pending",
  },
  {
    header: "Response",
    cell: ({ row }) => <p className="truncate">{row.original.responseText}</p>,
  },
  {
    header: "Download",
    cell: ({ row }) =>
      row.original.fileUpload ? (
        <a
          href={row.original.fileUpload.file_details.cloudinary_url}
          target="_blank"
          className="underline"
        >
          {row.original.fileUpload.file_details.original_file_name}
        </a>
      ) : null,
  },
];

export default function YourTaxCertificatesPage() {
  const currentUser = useCurrentUserContext();
  const currentUserMembership = getUserMembershipFromUser(currentUser);

  const { data, isLoading, isError } =
    trpc.getTaxCertificateRequestsByOrgId.useQuery(
      currentUserMembership!.org_id
    );
  return (
    <DashboardOutlet title="Tax Certificates">
      <Button asChild className="mb-4">
        <Link to="/dashboard/request-tax-certificate">
          Request A Tax Certificate
        </Link>
      </Button>
      {isLoading ? (
        <div>Loading...</div>
      ) : isError || !data ? (
        <div>Error loading tax certificates</div>
      ) : (
        <div className="mt-8">
          <DataTable
            columns={taxCertificateColumns}
            data={data}
            showSearch={false}
          />
        </div>
      )}
    </DashboardOutlet>
  );
}
