import { trpc } from "@/lib/trpc";
import { defaultError, wait } from "@/lib/utils";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

/**
 * @description Warns the user at the start of the mutation,
 * and opens then opens the returned checkout session
 * in a new tab.
 * Redirects to /dashboard/reports on success.
 */
export default function useCreateCheckoutSessionForReports() {
  const navigate = useNavigate();
  return trpc.createCheckoutSessionForReports.useMutation({
    onSuccess(data) {
      console.log(data);
      if (data.stripeCheckoutSession.url) {
        window.open(data.stripeCheckoutSession.url);
        navigate("/dashboard/reports");
      } else {
        toast.error("Something went wrong, please try again");
      }
    },
    onError(error) {
      toast.error(error.message || defaultError, {
        duration: 10000,
      });
    },
    onMutate() {
      wait(1000).then(() =>
        toast.loading(
          "Please wait while we create your checkout session. This can take up to 30 seconds."
        )
      );
    },
  });
}
