export const industrySectors = {
  "Consumer Goods": [
    "Apparel & Textiles",
    "Consumer Discretionary Products",
    "Consumer Goods Retail",
  ] as const,
  "Extractives & Minerals Processing": [
    "Coal",
    "Construction Materials",
    "Metals & Mining",
    "Oil & Gas",
  ] as const,
  Financials: [
    "Capital Markets",
    "Corporate & Retail Banking",
    "Insurance",
  ] as const,
  "Food & Beverage": [
    "Food",
    "Beverages",
    "Food & Beverage Retail",
    "Restaurants",
    "Tobacco",
  ] as const,
  "Health Care": [
    "Biotechnology & Pharmaceuticals",
    "Health Care Retail",
    "Health Care Providers",
    "Medical Technology",
  ] as const,
  Infrastructure: [
    "Utilities",
    "Infrastructure",
    "Real Estate",
    "Waste Management",
  ] as const,
  "Renewable Resources & Alternative Energy": [
    "Alternative Energy",
    "Forestry & Paper",
  ] as const,
  "Resource Transformation": ["Industrials", "Chemicals"] as const,
  Services: [
    "Media",
    "Hospitality & Recreation",
    "Consumer Services (i.e. education, professional services)",
  ] as const,
  "Technology & Communications": [
    "Internet & Media Services",
    "Semiconductors",
    "Telecommunications",
    "Technology",
  ] as const,
  Transportation: [
    "Air Transportation",
    "Automobiles",
    "Marine Transportation",
    "Land Transportation",
  ] as const,
} as const;
