import ActionsSection from "@/components/ActionsSection";
import AlertDialogWithActions from "@/components/AlertDialogWithActions";
import ApproveRejectForm from "@/components/ApproveRejectForm";
import NotesFeed from "@/components/NotesFeed";
import PreEngagementForm from "@/components/PreEngagementForm";
import ReportForm from "@/components/ReportForm";
import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { TypographyH3, TypographyH4 } from "@/components/ui/Typography";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { DatePicker } from "@/components/ui/date-picker";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Textarea } from "@/components/ui/textarea";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import useReportAddNote from "@/lib/hooks/mutations/useReportAddNote";
import useReportApprovePostEngagement from "@/lib/hooks/mutations/useReportApprovePostEngagement";
import useReportApprovePreEngagement from "@/lib/hooks/mutations/useReportApprovePreEngagement";
import useReportArchive from "@/lib/hooks/mutations/useReportArchive";
import useReportDownloadMarkings from "@/lib/hooks/mutations/useReportDownloadMarkings";
import useReportEditUrl from "@/lib/hooks/mutations/useReportEditUrl";
import useReportRejectPostEngagement from "@/lib/hooks/mutations/useReportRejectPostEngagement";
import useReportRejectPreEngagement from "@/lib/hooks/mutations/useReportRejectPreEngagement";
import useReportSuDownloadMarkingsUnchecked from "@/lib/hooks/mutations/useReportSuDownloadMarkingsUnchecked";
import useReportSuPublishUnchecked from "@/lib/hooks/mutations/useReportSuPublishUnchecked";
import useReportSuUnpublish from "@/lib/hooks/mutations/useReportSuUnpublish";
import useReportUnarchive from "@/lib/hooks/mutations/useReportUnarchive";
import useReportUpdatePaymentDetails from "@/lib/hooks/mutations/useReportUpdatePaymentDetails";
import {
  getAAPaymentStatusLabel,
  getAAReportStatusLabel,
  getUserReportStatusLabel,
  postEngagementAIAnalysisLabelMap,
} from "@/lib/label-maps";
import { zStringRequired } from "@/lib/schemas/helpers";
import { RouterOutputs, trpc } from "@/lib/trpc";
import { zodResolver } from "@hookform/resolvers/zod";
import clsx from "clsx";
import { BoxesIcon, InfoIcon, LanguagesIcon } from "lucide-react";
import { FormEvent, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

export default function ReportPage() {
  const addNoteMutation = useReportAddNote();
  const { reportId: _reportId } = useParams<{ reportId: string }>();
  if (!_reportId) {
    throw new Error("No report ID provided");
  }
  const reportId = _reportId.toUpperCase();
  const currentUser = useCurrentUserContext();
  const {
    isLoading,
    isError,
    data: engagement,
  } = trpc.getEngagementById.useQuery(reportId);

  // A member is only here if they have at least saved a pre-engagement form
  // AA can never edit pre-engagement forms (but can make notes)
  const preEngagementIsEditable =
    ["ApplicationStarted", "PreEngagementRejected"].includes(
      engagement?.status || ""
    ) && !currentUser.super_user_role;
  // Only show application if user is AA or application hasn't been submitted
  // This also applies to displaying the tabs
  const showPreEngagementForm =
    currentUser.super_user_role || preEngagementIsEditable;
  // AA can always edit the report, members can only edit until it's locked by submission
  const reportIsEditable = isReportEditable(currentUser, engagement);
  const startWithReportTab = !!engagement?.preEngagement.approval_type;
  const showReport = engagement?.status !== "ApplicationStarted";
  const showReportTranslation = showReport && !!currentUser.super_user_role;
  const showAIAnalysis =
    !!engagement?.postEngagement?.ai_analysis && !!currentUser.super_user_role;

  const showEditUrl =
    !currentUser.super_user_role &&
    [
      "ReportSubmitted",
      "Published",
      "PostEngagementApproved",
      "PostEngagementFlaggedForReview",
      "Unpublished",
    ].includes(engagement?.status || "");

  return (
    <DashboardOutlet title={`Report ${reportId}`}>
      {isLoading ? (
        <div>Loading...</div>
      ) : isError || !engagement ? (
        <div>Error loading report.</div>
      ) : (
        <>
          <ReportStatus
            engagement={engagement}
            isSuperUser={!!currentUser.super_user_role}
          />
          {showPreEngagementForm ? (
            <Tabs
              defaultValue={startWithReportTab ? "report" : "pre-engagement"}
            >
              <TabsList className="mb-12">
                <TabsTrigger value="pre-engagement">
                  Pre-engagement Info
                </TabsTrigger>
                <TabsTrigger value="pre-engagement-translation">
                  <LanguagesIcon className="[width:1.125rem] [height:1.125rem]" />
                </TabsTrigger>
                {showReport && <TabsTrigger value="report">Report</TabsTrigger>}
                {showReportTranslation && (
                  <TabsTrigger value="report-translation">
                    <LanguagesIcon className="[width:1.125rem] [height:1.125rem]" />
                  </TabsTrigger>
                )}
                {showAIAnalysis && (
                  <TabsTrigger value="ai-analysis">
                    <svg
                      className="w-0 h-0 absolute"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <linearGradient id="my-cool-gradient" x2="1" y2="1">
                        <stop offset="0%" stop-color="#625dc2" />
                        <stop offset="50%" stop-color="#9d7dd4" />
                        <stop offset="100%" stop-color="#c484d1" />
                      </linearGradient>
                    </svg>
                    <BoxesIcon className="[stroke:url(#my-cool-gradient)]" />
                  </TabsTrigger>
                )}
                {currentUser.super_user_role ? (
                  <TabsTrigger value="notes">Notes</TabsTrigger>
                ) : null}
                <TabsTrigger value="actions">Actions</TabsTrigger>
              </TabsList>
              <TabsContent value="pre-engagement">
                <PreEngagementForm
                  engagement={engagement}
                  isEditable={preEngagementIsEditable}
                  providerId={undefined}
                />
              </TabsContent>
              <TabsContent value="pre-engagement-translation">
                <PreEngagementTranslationTab engagement={engagement} />
              </TabsContent>
              {showReport && (
                <TabsContent value="report">
                  {showEditUrl && (
                    <EditReportUrl
                      reportUrl={engagement?.report?.report_url}
                      report_id={engagement.report_id || ""}
                    />
                  )}
                  <ReportForm
                    engagement={engagement}
                    isEditable={reportIsEditable}
                  />
                </TabsContent>
              )}
              {showReportTranslation && (
                <TabsContent value="report-translation">
                  <ReportTranslationTab engagement={engagement} />
                </TabsContent>
              )}
              {showAIAnalysis && (
                <TabsContent value="ai-analysis">
                  <AIAnalysisTab engagement={engagement} />
                </TabsContent>
              )}
              {currentUser.super_user_role ? (
                <TabsContent value="notes">
                  <NotesFeed
                    notes={engagement.notes || []}
                    addNote={(note) =>
                      addNoteMutation.mutate({
                        report_id: engagement.report_id,
                        note,
                      })
                    }
                  />
                </TabsContent>
              ) : null}
              <TabsContent value="actions">
                {currentUser.super_user_role ? (
                  <SuReportActions engagement={engagement} />
                ) : (
                  <MemberReportActions engagement={engagement} />
                )}
              </TabsContent>
            </Tabs>
          ) : (
            <>
              {showEditUrl && (
                <EditReportUrl
                  reportUrl={engagement?.report?.report_url}
                  report_id={engagement.report_id || ""}
                />
              )}
              <ReportForm
                engagement={engagement}
                isEditable={reportIsEditable}
              />
            </>
          )}
        </>
      )}
    </DashboardOutlet>
  );
}

function AIAnalysisTab({
  engagement,
}: {
  engagement: NonNullable<RouterOutputs["getEngagementById"]>;
}) {
  const materialityRef = useRef<HTMLDivElement>(null);
  const inclusivityRef = useRef<HTMLDivElement>(null);
  const responsivenessRef = useRef<HTMLDivElement>(null);
  const impactRef = useRef<HTMLDivElement>(null);
  const performanceRef = useRef<HTMLDivElement>(null);
  if (!engagement.postEngagement?.ai_analysis) return null;
  const { isLoading, isError, data } =
    trpc.getPostEngagementAnalysisById.useQuery(engagement.report_id);

  return isLoading ? (
    <div>Loading...</div>
  ) : isError || !data ? (
    <div>Error loading AI analysis.</div>
  ) : (
    <div className="space-y-12 max-w-2xl">
      <div className="space-y-6">
        <div className="flex justify-around gap-6">
          <AIScore
            score={data.inclusivityAnalysis.score}
            title="Inclusivity"
            scrollToRef={inclusivityRef}
          />
          <AIScore
            score={data.materialityAnalysis.score}
            title="Materiality"
            scrollToRef={materialityRef}
          />
          <AIScore
            score={data.responsivenessAnalysis.score}
            title="Responsiveness"
            scrollToRef={responsivenessRef}
          />
          <AIScore
            score={data.impactAnalysis.score}
            title="Impact"
            scrollToRef={impactRef}
          />
          {!!data.performanceAnalysis && (
            <AIScore
              score={data.performanceAnalysis.score}
              title="Performance"
              scrollToRef={performanceRef}
            />
          )}
        </div>
        <Separator />
        <AIExplanation
          fieldName="inclusivityAnalysis"
          scrollToRef={inclusivityRef}
          fullAIAnalysis={data}
        />
        <AIExplanation
          fieldName="materialityAnalysis"
          scrollToRef={materialityRef}
          fullAIAnalysis={data}
        />
        <AIExplanation
          fieldName="responsivenessAnalysis"
          scrollToRef={responsivenessRef}
          fullAIAnalysis={data}
        />
        <AIExplanation
          fieldName="impactAnalysis"
          scrollToRef={impactRef}
          fullAIAnalysis={data}
        />
        {!!data.performanceAnalysis && (
          <AIExplanation
            fieldName="performanceAnalysis"
            scrollToRef={performanceRef}
            fullAIAnalysis={data}
          />
        )}
      </div>
    </div>
  );
}

function AIExplanation({
  scrollToRef,
  fieldName,
  fullAIAnalysis,
}: {
  fieldName:
    | "inclusivityAnalysis"
    | "materialityAnalysis"
    | "responsivenessAnalysis"
    | "impactAnalysis"
    | "performanceAnalysis";
  scrollToRef: React.RefObject<HTMLDivElement>;
  fullAIAnalysis: NonNullable<RouterOutputs["getPostEngagementAnalysisById"]>;
}) {
  const field = fullAIAnalysis[fieldName];
  if (!field) return null;
  return (
    <div
      ref={scrollToRef}
      className="space-y-6 bg-accent text-accent-foreground p-4"
    >
      <div className="flex justify-between items-center">
        <TypographyH3>
          {postEngagementAIAnalysisLabelMap[fieldName]}
        </TypographyH3>
        <div className="border border-primary w-10 h-10 rounded-full flex items-center justify-center">
          {field.score}
        </div>
      </div>
      <div>
        <TypographyH4 className="mb-1">Input</TypographyH4>
        <p>{field.promptUserMessage}</p>
      </div>
      <div>
        <TypographyH4 className="mb-1">Explanation</TypographyH4>
        <p>{field.explanation}</p>
      </div>
    </div>
  );
}

function AIScore({
  score,
  title,
  scrollToRef,
}: {
  score: number;
  title: string;
  scrollToRef: React.RefObject<HTMLDivElement>;
}) {
  return (
    <div
      className="inline-flex flex-col items-center justify-center gap-3 cursor-pointer group"
      onClick={() =>
        scrollToRef.current?.scrollIntoView({ behavior: "smooth" })
      }
    >
      <p
        className={clsx(
          "w-16 h-16 border-2 transition-colors rounded-full relative text-xl flex items-center justify-center",
          score < 25
            ? "text-destructive/80 group-hover:text-destructive border-destructive/80 group-hover:border-destructive"
            : "text-primary/80 group-hover:text-primary border-primary/80 group-hover:border-primary"
        )}
      >
        {score}
      </p>
      <p
        className={clsx(
          "font-medium transition-colors text-primary group-hover:text-primary/80"
          // score < 20
          //   ? "text-destructive/80 group-hover:text-destructive"
          //   : "text-primary/80 group-hover:text-primary"
        )}
      >
        {title}
      </p>
    </div>
  );
}

function ReportTranslationTab({
  engagement,
}: {
  engagement: NonNullable<RouterOutputs["getEngagementById"]>;
}) {
  const [translatedReport, setTranslatedReport] = useState(
    engagement.translatedReport?.fields
  );
  // const utils = trpc.useUtils();
  const translateMutation = trpc.translateReportById.useMutation({
    onSuccess(data) {
      console.log({ data });
      setTranslatedReport(data?.translatedReport?.fields);
      // utils.getEngagementById.setData(engagement.report_id, data);
    },
    onMutate() {
      toast.loading("Translating report...");
    },
    onError() {
      toast.error("Error translating report");
    },
  });

  return (
    <div className="space-y-12">
      <Button onClick={() => translateMutation.mutate(engagement.report_id)}>
        {translatedReport ? "Update Translation" : "Translate Report"}
      </Button>
      <div>
        {!!translatedReport && (
          <ReportForm
            engagement={{ ...engagement, report: translatedReport }}
            isEditable={false}
          />
        )}
      </div>
    </div>
  );
}

function PreEngagementTranslationTab({
  engagement,
}: {
  engagement: NonNullable<RouterOutputs["getEngagementById"]>;
}) {
  const [translatedPreEngagement, setTranslatedPreEngagement] = useState(
    engagement.translatedPreEngagement
  );
  const utils = trpc.useUtils();
  const translateMutation = trpc.translatePreEngagementById.useMutation({
    onSuccess(data) {
      setTranslatedPreEngagement(data?.translatedPreEngagement);
      utils.getEngagementById.refetch(engagement.report_id);
    },
    onMutate() {
      toast.loading("Translating pre-engagement...");
    },
    onError() {
      toast.error("Error translating pre-engagement");
    },
  });

  return (
    <div className="space-y-12">
      <Button onClick={() => translateMutation.mutate(engagement.report_id)}>
        {translatedPreEngagement
          ? "Update Translation"
          : "Translate Pre-engagement"}
      </Button>
      <div>
        {!!translatedPreEngagement && (
          <PreEngagementForm
            engagement={{
              ...engagement,
              preEngagement: translatedPreEngagement,
            }}
            isEditable={false}
            providerId={undefined}
          />
        )}
      </div>
    </div>
  );
}

function isReportEditable(
  currentUser: NonNullable<RouterOutputs["getUserById"]>,
  engagement?: RouterOutputs["getEngagementById"]
) {
  return (
    !!currentUser.super_user_role ||
    ![
      "ReportSubmitted",
      "PostEngagementApproved",
      "ReportSubmitted",
      "Archived",
      "Published",
    ].includes(engagement?.status || "")
  );
}

function SuReportActions({
  engagement,
}: {
  engagement: NonNullable<RouterOutputs["getEngagementById"]>;
}) {
  const approvePreEngagementMutation = useReportApprovePreEngagement();
  const rejectPreEngagementMutation = useReportRejectPreEngagement();
  const approvePostEngagementMutation = useReportApprovePostEngagement();
  const rejectPostEngagementMutation = useReportRejectPostEngagement();
  const publishMutation = useReportSuPublishUnchecked();
  const unpublishMutation = useReportSuUnpublish();
  const markingsMutation = useReportSuDownloadMarkingsUnchecked(
    engagement.provider_id
  );
  const archiveMutation = useReportArchive();
  const unarchiveMutation = useReportUnarchive();
  const updatePaymentDetailsMutation = useReportUpdatePaymentDetails();

  const unpublishForm = useForm({
    resolver: zodResolver(z.object({ reason: zStringRequired })),
    defaultValues: { reason: "" },
  });
  const handleUnpublish = unpublishForm.handleSubmit(({ reason }) => {
    unpublishMutation.mutate({ report_id: engagement.report_id, reason });
  });

  const updatePaymentDetailsForm = useForm({
    resolver: zodResolver(
      z.object({
        paid_at: z.coerce.date(),
        note: z.string().optional(),
        payment_ref: z.string().optional(),
      })
    ),
    defaultValues: { paid_at: new Date(), note: "", payment_ref: "" },
  });
  const handleUpdatePaymentDetails = updatePaymentDetailsForm.handleSubmit(
    ({ note, paid_at, payment_ref }) => {
      updatePaymentDetailsMutation.mutate({
        report_id: engagement.report_id,
        payment_details: {
          PaymentReceivedOffline: {
            note: note || null,
            paid_at: new Date(paid_at).toISOString(),
            payment_ref: payment_ref || null,
          },
        },
      });
    }
  );

  const canApprovePreEngagement =
    [
      "PreEngagementFlaggedForReview",
      "PreEngagementRejected",
      "ApplicationSubmitted",
    ].includes(engagement.status || "") ||
    (engagement.status === "ReportStarted" &&
      !engagement.preEngagement.approval_type);
  const canApprovePostEngagement = [
    "ReportSubmitted",
    "PostEngagementRejected",
    "PostEngagementFlaggedForReview",
  ].includes(engagement?.status || "");
  const canDownloadMarkings = ["PostEngagementApproved", "Published"].includes(
    engagement.status || ""
  );
  const canPublish = ["PostEngagementApproved", "Unpublished"].includes(
    engagement.status
  );
  const canUnpublish = engagement.status === "Published";
  const canArchive = engagement.status !== "Archived";
  const canUnarchive = engagement.status === "Archived";
  const canAddPaymentOffline = ![
    "InvoicePaid",
    "ProviderHasUnlimited",
    "PaidViaCheckoutComplete",
    "PaymentReceivedOffline",
  ].includes(engagement.payment_details?.status || "");

  function handleDownloadMarkings() {
    markingsMutation.mutate({ report_id: engagement.report_id });
  }

  return (
    <div className="flex flex-col w-full items-stretch gap-y-6 max-w-2xl">
      <ActionsSection>
        <p>
          Provider:{" "}
          <Link
            to={`/dashboard/orgs/${engagement.provider.org_id}`}
            className="underline"
          >
            {engagement.provider_id}
          </Link>
        </p>
      </ActionsSection>
      {canApprovePreEngagement && (
        <ActionsSection title="Pre-engagement Application">
          <ApproveRejectForm
            rejectionReason={
              engagement.preEngagement.rejection?.rejection_reason
            }
            sendApproval={() =>
              approvePreEngagementMutation.mutate({
                report_id: engagement.report_id,
              })
            }
            sendRejection={(reason: string) =>
              rejectPreEngagementMutation.mutate({
                report_id: engagement.report_id,
                reason,
              })
            }
          />
        </ActionsSection>
      )}
      {canApprovePostEngagement && (
        <ActionsSection title="Pre-issue Check">
          <ApproveRejectForm
            rejectionReason={
              engagement.preEngagement.rejection?.rejection_reason
            }
            sendApproval={() =>
              approvePostEngagementMutation.mutate({
                report_id: engagement.report_id,
              })
            }
            sendRejection={(reason: string) =>
              rejectPostEngagementMutation.mutate({
                report_id: engagement.report_id,
                reason,
              })
            }
          />
        </ActionsSection>
      )}

      {canDownloadMarkings && (
        <ActionsSection title="Markings">
          <p>
            This is a convenience function which allows you to download the
            report markings regardless of status.
          </p>
          <Button onClick={handleDownloadMarkings}>Download Markings</Button>
        </ActionsSection>
      )}
      {canPublish && (
        <ActionsSection title="Publish Override">
          <p>
            This is a convenience function which publishes the report regardless
            of payment status. The payment status will be set to "Payment
            Expected". You will still be able to update the payment status if
            payment is received offline or externally.
          </p>
          <AlertDialogWithActions
            triggerText="Publish Report"
            title="Publish Report"
            description="The report will be published regardless of payment status. Are you sure?"
            action={() =>
              publishMutation.mutate({ report_id: engagement.report_id })
            }
          />
        </ActionsSection>
      )}
      {canAddPaymentOffline && (
        <ActionsSection title="Add External Payment">
          <p>
            This is so a report can be marked as paid if payment has been
            received outside the platform.
          </p>
          <Form {...updatePaymentDetailsForm}>
            <form
              onSubmit={handleUpdatePaymentDetails}
              className="space-y-6 self-stretch"
            >
              <FormField
                name="paid_at"
                control={updatePaymentDetailsForm.control}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Date Paid</FormLabel>
                      <FormControl>
                        <DatePicker
                          date={field.value}
                          setDate={field.onChange}
                          {...field}
                        />
                      </FormControl>
                      <FormDescription>Required.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                name="payment_ref"
                control={updatePaymentDetailsForm.control}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Payment Reference</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormDescription>
                        Optional. To help identify the payment in the future.
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                name="note"
                control={updatePaymentDetailsForm.control}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Note</FormLabel>
                      <FormControl>
                        <Textarea {...field} />
                      </FormControl>
                      <FormDescription>
                        Optional. For your reference only.
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <Button
                type="submit"
                className="mt-6"
                disabled={updatePaymentDetailsMutation.isLoading}
              >
                Update Payment Details
              </Button>
            </form>
          </Form>
        </ActionsSection>
      )}
      {canUnpublish && (
        <ActionsSection title="Unpublish Report">
          <Form {...unpublishForm}>
            <form onSubmit={handleUnpublish} className="space-y-6 self-stretch">
              <FormField
                name="reason"
                control={unpublishForm.control}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Reason for unpublishing</FormLabel>
                      <FormControl>
                        <Textarea {...field} />
                      </FormControl>
                      <FormDescription>Required.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <AlertDialogWithActions
                triggerText="Unpublish Report"
                buttonProps={{
                  variant: "destructive",
                  type: "button",
                  disabled: !unpublishForm.formState.isDirty,
                }}
                title="Unpublish Report"
                description="The report will be unpublished and returned to its previous status. Are you sure?"
                action={handleUnpublish}
              />
            </form>
          </Form>
        </ActionsSection>
      )}
      {canArchive && (
        <ActionsSection title="Archive Report">
          <p>
            This removes the report from the user's reports table. Archived
            reports can be unarchived by a super user.
          </p>
          <AlertDialogWithActions
            triggerText="Archive Report"
            title="Archive Report"
            buttonProps={{ variant: "destructive" }}
            action={() =>
              archiveMutation.mutate({ report_id: engagement.report_id })
            }
          />
        </ActionsSection>
      )}
      {canUnarchive && (
        <ActionsSection title="Unarchive Report">
          <p>This returns the report to the user's reports table.</p>
          <AlertDialogWithActions
            triggerText="Unarchive Report"
            title="Unarchive Report"
            buttonProps={{ variant: "outline" }}
            action={() =>
              unarchiveMutation.mutate({ report_id: engagement.report_id })
            }
          />
        </ActionsSection>
      )}
    </div>
  );
}

function MemberReportActions({
  engagement,
}: {
  engagement: NonNullable<RouterOutputs["getEngagementById"]>;
}) {
  const archiveMutation = useReportArchive();
  const unarchiveMutation = useReportUnarchive();

  const canArchive = engagement.status !== "Archived";
  const canUnarchive = engagement.status === "Archived";

  return (
    <div className="space-y-6 max-w-2xl">
      {canArchive && (
        <ActionsSection>
          <AlertDialogWithActions
            triggerText="Archive Report"
            title="Archive Report"
            buttonProps={{ variant: "destructive" }}
            action={() =>
              archiveMutation.mutate({ report_id: engagement.report_id })
            }
          />
        </ActionsSection>
      )}
      {canUnarchive && (
        <ActionsSection>
          <AlertDialogWithActions
            triggerText="Unarchive Report"
            title="Unarchive Report"
            buttonProps={{ variant: "outline" }}
            action={() =>
              unarchiveMutation.mutate({ report_id: engagement.report_id })
            }
          />
        </ActionsSection>
      )}
    </div>
  );
}

/** 
  @description 
  - Displays report status (and payment status if super user)  
  - Displays pre or post rejection reason  
  - Lets members download markings (but not super users)  
  - If report is payable, link to payment page
*/
function ReportStatus({
  engagement,
  isSuperUser,
}: {
  engagement: NonNullable<RouterOutputs["getEngagementById"]>;
  isSuperUser: boolean;
}) {
  const markingsMutation = useReportDownloadMarkings(engagement.provider_id);
  function handleDownloadMarkings() {
    markingsMutation.mutate({ report_id: engagement.report_id });
  }

  // Can download markings if:
  // - report is published
  // - report has an invoice raised and privilege is PostReportInvoiceRaised
  // - report is approved and privilege is PostReportApproval
  const canDownloadMarkings =
    engagement.status === "Published" ||
    (!!engagement.payment_details?.invoice_id &&
      engagement.provider?.markings_dl_privilege_level ===
        "PostReportInvoiceRaised") ||
    (engagement.status === "PostEngagementApproved" &&
      engagement.provider?.markings_dl_privilege_level ===
        "PostReportApproval");

  const preEngagementRejectionReason =
    // don't display if report has been submitted
    !engagement.postEngagement &&
    engagement.preEngagement.rejection?.rejection_reason;
  const postEngagementRejectionReason =
    engagement.postEngagement?.rejection?.rejection_reason;
  const status = isSuperUser
    ? getAAReportStatusLabel(engagement)
    : getUserReportStatusLabel(engagement);

  const paymentStatus = getAAPaymentStatusLabel(engagement) || "None";
  const reportIsPayable = isReportPayable(engagement);

  return (
    <Alert className="max-w-md mb-12">
      <InfoIcon className="h-4 w-4" />
      <AlertTitle>Status</AlertTitle>
      <AlertDescription className="space-y-3 mt-3">
        <p>
          Report status is: <span className="font-semibold">{status}</span>
        </p>
        {isSuperUser && (
          <p>
            Payment status is:{" "}
            <span className="font-semibold">{paymentStatus}</span>
          </p>
        )}
        {preEngagementRejectionReason && (
          <div className="mt-3 space-y-1">
            <p className="font-semibold">Rejection reason:</p>
            <p className="whitespace-pre-wrap">
              {preEngagementRejectionReason}
            </p>
          </div>
        )}
        {postEngagementRejectionReason && (
          <div className="mt-3 space-y-1">
            <p className="font-semibold">Rejection reason:</p>
            <p className="whitespace-pre-wrap">
              {postEngagementRejectionReason}
            </p>
          </div>
        )}
        {canDownloadMarkings && !isSuperUser && (
          <div className="mt-3 space-y-3">
            <Separator />
            <Button
              size="sm"
              variant="outline"
              onClick={handleDownloadMarkings}
            >
              Download Markings
            </Button>
          </div>
        )}
        {reportIsPayable && (
          <div className="mt-3 space-y-3">
            <Separator />
            <Button size="sm" asChild>
              <Link to={`/dashboard/reports/payable`}>Go To Payments Page</Link>
            </Button>
          </div>
        )}
      </AlertDescription>
    </Alert>
  );
}

function EditReportUrl({
  reportUrl,
  report_id,
}: {
  reportUrl?: string | null;
  report_id: string;
}) {
  const [url, setUrl] = useState<string>(reportUrl || "");
  const editUrlMutation = useReportEditUrl();
  function handleSubmitUrlForm(e: FormEvent) {
    e.preventDefault();
    editUrlMutation.mutate({
      report_id: report_id,
      new_url: url,
    });
  }
  return (
    <div className="rounded-lg bg-accent p-4 mb-12 space-y-6 max-w-2xl">
      <p>
        Reports can't be edited after submission. The only field which can be
        updated is the link to PDF / weblink of assured report.
      </p>
      <form onSubmit={handleSubmitUrlForm}>
        <Label htmlFor="report-url-input" className="mb-2">
          Update Report Url
        </Label>
        <Input
          name="report-url-input"
          value={url}
          onChange={(e) => setUrl(e.currentTarget.value)}
        />
        <Button
          type="submit"
          variant="outline"
          className="mt-6"
          disabled={editUrlMutation.isLoading}
        >
          Update
        </Button>
      </form>
    </div>
  );
}

// report is payable if it's approved and has no payment details
// or if it has payment details that are voided or marked as uncollectable
// or if payment is expected
const isReportPayable = (
  engagement: NonNullable<RouterOutputs["getEngagementById"]>
) => {
  const currentUser = useCurrentUserContext();
  if (currentUser.super_user_role) return false;
  return (
    (!!engagement.postEngagement?.approval_type &&
      !engagement.payment_details) ||
    [
      "InvoiceVoided",
      "InvoiceMarkedAsUncollectable",
      "PaymentExpected",
    ].includes(engagement.payment_details?.status || "")
  );
};
