import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { TypographyList } from "@/components/ui/Typography";
import { Button } from "@/components/ui/button";
import { trpc } from "@/lib/trpc";
import { Link } from "react-router-dom";

export default function BillingAccountsPage() {
  const currentUser = useCurrentUserContext();
  const orgQuery = trpc.getOrgById.useQuery(currentUser.orgs[0].org_id);
  const billingAccounts = orgQuery.data?.billingAccounts;

  return (
    <DashboardOutlet title="Billing Accounts">
      {orgQuery.isLoading ? (
        <p>Loading...</p>
      ) : orgQuery.isError || !orgQuery.data ? (
        <p>Error loading org</p>
      ) : (
        <div className="max-w-2xl space-y-12">
          <div className="bg-accent p-4 rounded-lg prose">
            <p>
              Here you can save your billing account information for future
              convenience. You are able to create and save several accounts if
              invoices need to be sent to different business units.
            </p>
          </div>
          {billingAccounts?.length ? (
            <div className="space-y-12">
              <Button asChild>
                <Link to="/dashboard/billing-accounts/new">
                  New Billing Account
                </Link>
              </Button>
              <TypographyList>
                {billingAccounts.map((account) => (
                  <li key={account.billing_account_id}>
                    <Link
                      className="underline"
                      to={`/dashboard/billing-accounts/${account.billing_account_id}`}
                    >
                      {account.nickname}
                    </Link>
                  </li>
                ))}
              </TypographyList>
            </div>
          ) : (
            <>
              <h3 className="text-2xl font-semibold mb-4">
                Make your first billing account
              </h3>
              <Button asChild>
                <Link to="/dashboard/billing-accounts/new">New Account</Link>
              </Button>
            </>
          )}
        </div>
      )}
    </DashboardOutlet>
  );
}
