import {
  Form,
  FormControl,
  FormDescription,
  // FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import MultiSelect from "@/components/ui/multi-select";
import { Textarea } from "@/components/ui/textarea";
import {
  LicenseApplicationType,
  licenseApplicationFormSpec,
  licenseApplicationSchema,
} from "@/lib/schemas/license-application-schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import CSAPFinder from "./CSAPFinder";
import FormContextualSaveBar from "./FormContextualSaveBar";
import UploadWidget from "./UploadWidget";
import { Button } from "./ui/button";
import { Checkbox } from "./ui/checkbox";

import useLicenseApplicationSave from "@/lib/hooks/mutations/useLicenseApplicationSave";
import useLicenseApplicationStart from "@/lib/hooks/mutations/useLicenseApplicationStart";
import useLicenseApplicationSubmit from "@/lib/hooks/mutations/useLicenseApplicationSubmit";
import { RouterOutputs } from "@/lib/trpc";
import {
  convertBoolToYesNo,
  convertFormValueToFileUploadAction,
  convertYesNoToBool,
  wait,
} from "@/lib/utils";
import type { UnvalidatedLicenseApplicationForm } from "../../../crates/e-licensing/bindings/UnvalidatedLicenseApplicationForm";
import { toast } from "sonner";

const blankFormValues = {
  why_become_provider: "",
  sustainability_esg_years: "",
  audit_assurance_years: "",
  certificates_reports_details: "",
  projects_details: "",
  familiar_ap: "",
  familiar_as: "",
  familiar_as_guidance: "",
  familiar_ghg: "No",
  have_done_training: "",
  trainees: [],
  other_names_of_trainees: "",
  future_training_plans: "",
  interest_live_seminars: false,
  interest_online_learning: false,
  interest_other: "",
  how_work_code_practice: "",
  company_reg_doc: undefined,
  org_application_rejected: "",
  org_warned: "",
};

export default function LicenseApplicationForm({
  isEditable = true,
  org,
}: {
  isEditable?: boolean;
  org: NonNullable<RouterOutputs["getOrgById"]>;
}) {
  const startApplicationMutation = useLicenseApplicationStart({
    showSavedAlert: !!org.license_application,
  });
  const saveApplicationMutation = useLicenseApplicationSave();
  const submitApplicationMutation = useLicenseApplicationSubmit();

  const convertedData = org.license_application?.fields
    ? convertDataToFormValues(org.license_application.fields)
    : {};
  const licenseApplicationForm = useForm({
    resolver: zodResolver(licenseApplicationSchema),
    values: { ...blankFormValues, ...convertedData },
  });

  const haveDoneTrainingField =
    licenseApplicationForm.watch("have_done_training");
  const companyRegDocField = licenseApplicationForm.watch("company_reg_doc");

  function handleSave() {
    const formValues = licenseApplicationForm.getValues();
    const formData = convertFormValuesToData(formValues);
    // if data isn't provided, the application needs to be started
    if (org.license_application) {
      saveApplicationMutation.mutate({
        license_application_id: org.license_application.license_application_id,
        unvalidated_license_application_form: {
          ...formData,
          created_at: org.license_application.fields.created_at,
        },
      });
    } else {
      startApplicationMutation.mutate({
        org_id: org.org_id,
        unvalidated_license_application_form: {
          ...formData,
          created_at: new Date().toISOString(),
        },
      });
    }
  }

  const handleSubmit = licenseApplicationForm.handleSubmit(
    async (data) => {
      const formData = convertFormValuesToData(data);
      if (!org.license_application) {
        startApplicationMutation.mutate({
          org_id: org.org_id,
          unvalidated_license_application_form: {
            ...formData,
            created_at: new Date().toISOString(),
          },
        });
        await wait(1000);
      }
      submitApplicationMutation.mutate({
        license_application_id: org.org_id,
        unvalidated_license_application_form: {
          ...formData,
          created_at: new Date().toISOString(),
        },
      });
    },
    () => toast.error("Form has errors")
  );

  const disableButtons =
    saveApplicationMutation.isLoading ||
    submitApplicationMutation.isLoading ||
    startApplicationMutation.isLoading;

  const isRejected = org.license_application?.status === "ApplicationRejected";

  return (
    <>
      <FormContextualSaveBar
        show={licenseApplicationForm.formState.isDirty && isEditable}
        save={isRejected ? undefined : { label: "Save", onPress: handleSave }}
        submit={
          org.profile ? { label: "Submit", onPress: handleSubmit } : undefined
        }
        discard={{
          onPress: () =>
            licenseApplicationForm.reset({
              ...blankFormValues,
              ...convertedData,
            }),
        }}
      />
      <div className="max-w-2xl">
        <Form {...licenseApplicationForm}>
          <form
            onSubmit={isEditable ? handleSubmit : undefined}
            className="space-y-12"
          >
            <FormFieldWrapper
              name="why_become_provider"
              control={licenseApplicationForm.control}
              render={(field: any) => (
                <Textarea readOnly={!isEditable} {...field} />
              )}
            />

            <div className="p-4 bg-accent rounded-lg space-y-4">
              <FormLabel>
                How many years does your organization have experience in/has
                been doing the following work:
              </FormLabel>
              <FormFieldWrapper
                name="sustainability_esg_years"
                control={licenseApplicationForm.control}
                render={(field: any) => (
                  <Input
                    readOnly={!isEditable}
                    type="number"
                    min={0}
                    {...field}
                  />
                )}
              />
              <FormFieldWrapper
                name="audit_assurance_years"
                control={licenseApplicationForm.control}
                render={(field: any) => (
                  <Input
                    readOnly={!isEditable}
                    type="number"
                    min={0}
                    {...field}
                  />
                )}
              />
            </div>
            <FormFieldWrapper
              name="certificates_reports_details"
              control={licenseApplicationForm.control}
              render={(field: any) => (
                <Textarea readOnly={!isEditable} {...field} />
              )}
            />
            <FormFieldWrapper
              name="projects_details"
              control={licenseApplicationForm.control}
              render={(field: any) => (
                <Textarea readOnly={!isEditable} {...field} />
              )}
            />
            <div className="p-4 bg-accent rounded-lg space-y-4">
              <FormLabel>
                Is your organization familiar with the following documents?
              </FormLabel>
              <FormFieldWrapper
                name="familiar_ap"
                control={licenseApplicationForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={["Yes", "No"]}
                  />
                )}
              />
              <FormFieldWrapper
                name="familiar_as"
                control={licenseApplicationForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={["Yes", "No"]}
                  />
                )}
              />
              <FormFieldWrapper
                name="familiar_as_guidance"
                control={licenseApplicationForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={["Yes", "No"]}
                  />
                )}
              />
              {/* <FormFieldWrapper
                name="familiar_ghg"
                control={licenseApplicationForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={["Yes", "No"]}
                  />
                )}
              /> */}
            </div>
            <FormFieldWrapper
              name="have_done_training"
              control={licenseApplicationForm.control}
              render={(field: any) => (
                <MultiSelect
                  disabled={!isEditable}
                  field={field}
                  options={["Yes", "No"]}
                />
              )}
            />
            {haveDoneTrainingField === "Yes" ? (
              <>
                <CSAPFinder
                  form={licenseApplicationForm}
                  label={licenseApplicationFormSpec.trainees.label}
                  name="trainees"
                  withSPs
                />
                <FormFieldWrapper
                  name="other_names_of_trainees"
                  control={licenseApplicationForm.control}
                  render={(field: any) => (
                    <Textarea readOnly={!isEditable} {...field} />
                  )}
                />
              </>
            ) : null}
            {haveDoneTrainingField === "No" ? (
              <FormFieldWrapper
                name="future_training_plans"
                control={licenseApplicationForm.control}
                render={(field: any) => (
                  <Textarea readOnly={!isEditable} {...field} />
                )}
              />
            ) : null}
            <div className="p-4 bg-accent rounded-lg space-y-4">
              <FormLabel>
                Please click here if you are interested in attending the AA1000
                training and would like our Training Providers to contact you.
                Please select the option(s) you are interested in:
              </FormLabel>
              <FormField
                name="interest_live_seminars"
                control={licenseApplicationForm.control}
                render={({ field }) => {
                  return (
                    <FormItem className="flex flex-row items-center space-y-0">
                      <FormControl>
                        <Checkbox
                          disabled={!isEditable}
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormLabel className="inline-block ml-2">
                        {
                          licenseApplicationFormSpec.interest_live_seminars
                            .label
                        }
                      </FormLabel>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                name="interest_online_learning"
                control={licenseApplicationForm.control}
                render={({ field }) => {
                  return (
                    <FormItem className="flex flex-row items-center space-y-0">
                      <FormControl>
                        <Checkbox
                          disabled={!isEditable}
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormLabel className="inline-block ml-2">
                        {
                          licenseApplicationFormSpec.interest_online_learning
                            .label
                        }
                      </FormLabel>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormFieldWrapper
                name="interest_other"
                control={licenseApplicationForm.control}
                render={(field: any) => (
                  <Textarea readOnly={!isEditable} {...field} />
                )}
              />
            </div>
            <FormFieldWrapper
              name="how_work_code_practice"
              control={licenseApplicationForm.control}
              render={(field: any) => (
                <Textarea readOnly={!isEditable} {...field} />
              )}
            />
            <FormFieldWrapper
              name="company_reg_doc"
              control={licenseApplicationForm.control}
              render={(field: any) => (
                <UploadWidget
                  onSuccess={field.onChange}
                  value={companyRegDocField}
                  disabled={!isEditable}
                  tag="company_reg_doc"
                />
              )}
            />

            <FormFieldWrapper
              name="org_application_rejected"
              control={licenseApplicationForm.control}
              render={(field: any) => (
                <Textarea readOnly={!isEditable} {...field} />
              )}
            />
            <FormFieldWrapper
              name="org_warned"
              control={licenseApplicationForm.control}
              render={(field: any) => (
                <Textarea readOnly={!isEditable} {...field} />
              )}
            />
            {isEditable ? (
              <div className="flex gap-4">
                {!isRejected && (
                  <Button
                    type="button"
                    onClick={handleSave}
                    variant="outline"
                    disabled={disableButtons}
                  >
                    Save
                  </Button>
                )}
                {!!org.profile && (
                  <Button type="submit" disabled={disableButtons}>
                    Submit
                  </Button>
                )}
              </div>
            ) : null}
          </form>
        </Form>
      </div>
    </>
  );
}

function FormFieldWrapper({
  control,
  name,
  render,
}: {
  control: any;
  name: Extract<keyof typeof licenseApplicationFormSpec, string>;
  render: any;
}) {
  return (
    <FormField
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormItem>
            <FormLabel
              dangerouslySetInnerHTML={{
                __html: licenseApplicationFormSpec[name].label,
              }}
            />
            <FormControl>{render(field)}</FormControl>
            <FormDescription
              dangerouslySetInnerHTML={{
                __html: licenseApplicationFormSpec[name]?.helperText || "",
              }}
            />
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
}

function convertDataToFormValues(
  fields: NonNullable<
    NonNullable<RouterOutputs["getOrgById"]>["license_application"]
  >["fields"]
): Partial<LicenseApplicationType> {
  return {
    why_become_provider: fields.why_become_provider || "",
    sustainability_esg_years: fields.sustainability_esg_years || "",
    audit_assurance_years: fields.audit_assurance_years || "",
    certificates_reports_details: fields.certificates_reports_details || "",
    projects_details: fields.projects_details || "",
    familiar_ap: convertBoolToYesNo(fields.familiar_ap),
    familiar_as: convertBoolToYesNo(fields.familiar_as),
    familiar_as_guidance: convertBoolToYesNo(fields.familiar_as_guidance),
    familiar_ghg: convertBoolToYesNo(fields.familiar_ghg),
    have_done_training: convertBoolToYesNo(fields.have_done_training),
    trainees: fields.trainees.length ? fields.trainees : [],
    other_names_of_trainees: fields.other_names_of_trainees || "",
    future_training_plans: fields.future_training_plans || "",
    interest_live_seminars: fields.interest_live_seminars || false,
    interest_online_learning: fields.interest_online_learning || false,
    interest_other: fields.interest_other || "",
    how_work_code_practice: fields.how_work_code_practice || "",
    company_reg_doc: fields.company_reg_doc?.file_details || undefined,
    org_application_rejected: fields.org_application_rejected || "",
    org_warned: fields.org_warned || "",
  };
}

function convertFormValuesToData(
  formValues: LicenseApplicationType,
  created_at?: string
): UnvalidatedLicenseApplicationForm {
  return {
    created_at: created_at || new Date().toISOString(),
    have_done_training:
      formValues.have_done_training === "Yes"
        ? {
            HaveDoneTraining: {
              trainees: formValues.trainees,
              other_names_of_trainees:
                formValues.other_names_of_trainees || null,
            },
          }
        : formValues.have_done_training === "No"
        ? {
            HaveNotDoneTraining: {
              future_training_plans: formValues.future_training_plans,
            },
          }
        : null,
    why_become_provider: formValues.why_become_provider || null,
    sustainability_esg_years: formValues.sustainability_esg_years || null,
    audit_assurance_years: formValues.audit_assurance_years || null,
    certificates_reports_details:
      formValues.certificates_reports_details || null,
    projects_details: formValues.projects_details || null,
    familiar_ap: convertYesNoToBool(formValues.familiar_ap),
    familiar_as: convertYesNoToBool(formValues.familiar_as),
    familiar_as_guidance: convertYesNoToBool(formValues.familiar_as_guidance),
    familiar_ghg: convertYesNoToBool(formValues.familiar_ghg),
    interest_live_seminars: formValues.interest_live_seminars || false,
    interest_online_learning: formValues.interest_online_learning || false,
    interest_other: formValues.interest_other || null,
    how_work_code_practice: formValues.how_work_code_practice || null,
    company_reg_doc: convertFormValueToFileUploadAction(
      formValues.company_reg_doc
    ),
    org_application_rejected: formValues.org_application_rejected || null,
    org_warned: formValues.org_warned || null,
  };
}
