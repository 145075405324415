import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuth0 } from "@auth0/auth0-react";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import { HelpCircleIcon, UserIcon } from "lucide-react";

export default function UserAccountMenu() {
  const { logout } = useAuth0();
  const currentUser = useCurrentUserContext();
  const name = `${currentUser.profile.first_name} ${currentUser.profile.last_name}`;
  return (
    <div className="flex gap-3 lg:gap-6">
      <DropdownMenu>
        <DropdownMenuTrigger className="flex items-center">
          <HelpCircleIcon className="w-8 h-8 text-primary/80 stroke-1" />
          <span className="sr-only">Help</span>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="mr-1">
          <DropdownMenuLabel>Help</DropdownMenuLabel>
          <DropdownMenuItem asChild>
            <Link to="/help-and-info" className="cursor-pointer">
              Help & Info
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Link to="/faqs" className="cursor-pointer">
              FAQs
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Link to="/dashboard/feedback" className="cursor-pointer">
              Feedback
            </Link>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <DropdownMenu>
        <DropdownMenuTrigger className="flex items-center">
          <span className="flex items-center justify-center w-8 h-8 mr-2 text-white text-sm rounded-full bg-primary">
            <UserIcon className="w-8 h-8 stroke-1 p-1" />
          </span>
          <span className="sr-only">Account</span>
          <span aria-hidden="true" className="hidden lg:block">
            {name}
          </span>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="mr-1">
          <DropdownMenuLabel>Account</DropdownMenuLabel>
          <DropdownMenuItem asChild>
            <Link to="/dashboard/profile" className="cursor-pointer">
              Your Profile
            </Link>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
            className="bg-destructive/15 cursor-pointer"
          >
            Log Out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

// function getInitials(name: string): string {
//   const words = name.split(" ");
//   const firstInitial = words[0][0].toUpperCase();
//   const lastInitial = words[words.length - 1][0].toUpperCase();
//   return firstInitial + lastInitial;
// }
