import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { DataTable, ViewEditButtonLink } from "@/components/ui/data-table";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import { licenseApplicationLabelMap } from "@/lib/label-maps";
import { RouterOutputs, trpc } from "@/lib/trpc";
import { formatFullDate } from "@/lib/utils";
import { ColumnDef } from "@tanstack/react-table";

export interface ProviderApprovalRequestRow {
  id: string;
  created_at: string;
  first_name: string;
  last_name: string;
  org_name: string;
  email: string;
}

const providerApprovalRequestColumns: ColumnDef<
  NonNullable<RouterOutputs["getOrgsWithOpenLicenseApplications"]>[number]
>[] = [
  {
    id: "view/edit",
    cell: ({ row }) => (
      <ViewEditButtonLink to={`/dashboard/orgs/${row.original.org_id}`} />
    ),
  },
  { accessorKey: "legal_company_name", header: "Company Name" },
  {
    accessorFn: (row) =>
      licenseApplicationLabelMap[row.license_application!.status],
    header: "Status",
  },
  {
    id: "date",
    header: "Created At",
    cell: ({ row }) =>
      formatFullDate(
        new Date(row.original.license_application!.fields.created_at)
      ),
  },
];

export default function ProviderApprovalRequestsPage() {
  const currentUser = useCurrentUserContext();
  if (!currentUser.super_user_role) {
    throw new Response("You are not authorized to view this page.", {
      status: 401,
    });
  }
  const openApplicationsQuery =
    trpc.getOrgsWithOpenLicenseApplications.useQuery();
  return (
    <DashboardOutlet title="Provider Approval Requests">
      {openApplicationsQuery.isLoading ? (
        <div>Loading...</div>
      ) : openApplicationsQuery.isError || !openApplicationsQuery.data ? (
        <div>Error loading orgs.</div>
      ) : (
        <DataTable
          data={openApplicationsQuery.data}
          columns={providerApprovalRequestColumns}
        />
      )}
    </DashboardOutlet>
  );
}
