import { trpc } from "@/lib/trpc";
import { defaultError, wait } from "@/lib/utils";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";
import { toast } from "sonner";
import { ReportSubmitApplicationCommand } from "../../../../../crates/e-licensing/bindings/ReportSubmitApplicationCommand";
import { useNavigate } from "react-router-dom";

export default function useReportSubmitApplication() {
  const utils = trpc.useUtils();
  const navigate = useNavigate();
  const { axiosInstance: axios } = useAuthTokenContext();
  return useMutation({
    mutationFn: (data: ReportSubmitApplicationCommand) => {
      return axios({
        url: `${import.meta.env.VITE_ELIC_API_URL}/report/submit-application`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data,
      });
    },
    onSuccess: (_, variables) => {
      toast.success("Submitted.");
      utils.getEngagementById.setData(variables.report_id, (prev) =>
        prev
          ? {
              ...prev,
              status: "ApplicationSubmitted",
            }
          : prev
      );
      wait(2000).then(() => {
        navigate(`/dashboard/reports/${variables.report_id}`);
        utils.getEngagementById.invalidate(variables.report_id);
      });
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
