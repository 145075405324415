import React, { createContext, useContext } from "react";
import { RouterOutputs, trpc } from "../trpc";

type ImpersonateContextType = {
  impersonatedUser?: RouterOutputs["getUserById"];
  setImpersonateUserId: (userId: string | null) => void;
};

const ImpersonateContext = createContext<ImpersonateContextType>({
  impersonatedUser: null,
  setImpersonateUserId: () => {},
});

export const useImpersonateContext = () => {
  const impersonateContext = useContext(ImpersonateContext);
  if (!impersonateContext) {
    throw new Error("ImpersonateContext: No value provided");
  }
  return impersonateContext;
};

export const ImpersonateContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [impersonateUserId, setImpersonateUserId] = React.useState<
    string | null
  >(null);
  const impersonateQuery = trpc.getUserById.useQuery(impersonateUserId!, {
    enabled: !!impersonateUserId,
  });

  // React.useEffect(() => {

  // }, [impersonateUserId])

  return (
    <ImpersonateContext.Provider
      value={{ impersonatedUser: impersonateQuery.data, setImpersonateUserId }}
    >
      {children}
    </ImpersonateContext.Provider>
  );
};
