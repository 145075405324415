import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import BillingAccountForm from "@/components/BillingAccountForm";
import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { trpc } from "@/lib/trpc";
import { useParams } from "react-router-dom";

export default function EditBillingAccountPage() {
  const { accountId } = useParams();
  if (!accountId) throw new Error("No account id provided");
  const currentUser = useCurrentUserContext();
  const orgQuery = trpc.getOrgById.useQuery(currentUser.orgs[0].org_id);
  const billingAccount = orgQuery.data?.billingAccounts.find(
    (a) => a.billing_account_id === accountId
  );

  return (
    <DashboardOutlet title="Edit Billing Account">
      {orgQuery.isLoading ? (
        <p>Loading...</p>
      ) : orgQuery.isError || !orgQuery.data ? (
        <p>Error loading billing account</p>
      ) : billingAccount ? (
        <BillingAccountForm data={billingAccount} />
      ) : (
        <p>Billing account not found</p>
      )}
    </DashboardOutlet>
  );
}
