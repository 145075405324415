import { cn } from "@/lib/utils";
import React from "react";

export function TypographyH1({
  children,
  as = "h1",
  className,
}: {
  children: React.ReactNode | React.ReactNode[];
  as?: string;
  className?: string;
}) {
  return React.createElement(
    as,
    {
      className: cn(
        "mb-14 text-4xl font-semibold scroll-m-20 lg:text-5xl text-slate-900",
        className
      ),
    },
    children
  );
}

export function TypographyH2({
  children,
  as = "h2",
  className,
}: {
  children: React.ReactNode | React.ReactNode[];
  as?: string;
  className?: string;
}) {
  return React.createElement(
    as,
    {
      className: cn(
        "scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-0",
        className
      ),
    },
    children
  );
}

export function TypographyH4({
  children,
  as = "h4",
  className,
}: {
  children: React.ReactNode | React.ReactNode[];
  as?: string;
  className?: string;
}) {
  return React.createElement(
    as,
    {
      className: cn(
        "scroll-m-20 text-xl font-semibold tracking-tight",
        className
      ),
    },
    children
  );
}

export function TypographyH3({
  children,
  as = "h3",
  className,
}: {
  children: React.ReactNode | React.ReactNode[];
  as?: string;
  className?: string;
}) {
  return React.createElement(
    as,
    {
      className: cn(
        "scroll-m-20 text-2xl font-semibold tracking-tight",
        className
      ),
    },
    children
  );
}

export function TypographyList({
  children,
  className,
}: {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}) {
  return (
    <ul className={cn("my-6 ml-6 list-disc [&>li]:mt-2", className)}>
      {children}
    </ul>
  );
}
