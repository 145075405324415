import { trpc } from "@/lib/trpc";
import { defaultError, wait } from "@/lib/utils";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";
import { toast } from "sonner";
import { LicenseApplicationAddPaymentCommand } from "../../../../../crates/e-licensing/bindings/LicenseApplicationAddPaymentCommand";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";

export default function useLicenseApplicationAddPayment() {
  const currentUser = useCurrentUserContext();
  const utils = trpc.useUtils();
  const { axiosInstance: axios } = useAuthTokenContext();
  return useMutation({
    mutationFn: (data: LicenseApplicationAddPaymentCommand) => {
      return axios(
        `${import.meta.env.VITE_ELIC_API_URL}/license-application/add-payment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        }
      );
    },
    onSuccess: () => {
      toast.success("Updated.");
      utils.getOrgById.setData(currentUser.orgs[0]?.org_id, (oldData) => {
        return oldData && oldData.license_application
          ? {
              ...oldData,
              license_application: {
                ...oldData.license_application,
                payment_details: {
                  admin_override: true,
                  payment_received: null,
                },
              },
            }
          : oldData;
      });
      wait(2500).then(() =>
        utils.getOrgById.refetch(currentUser.orgs[0]?.org_id)
      );
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
