import PageWithTitle from "@/components/templates/PageWithTitle";
import { Button } from "@/components/ui/button";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Signin() {
  const auth = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate("/dashboard");
    }
  }, [auth]);

  return (
    <PageWithTitle title="Sign In">
      <div className="prose mx-auto">
        <p>
          The AccountAbility E-licensing Platform uses passwordless logins.
          Instead of a traditional password, you will receive a One-Time
          Password (OTP) via email each time you want to log in. Simply enter
          the OTP to access your account. This method enhances the security of
          your account and simplifies the login process.
        </p>
        <p>
          Make sure to check your spam folder if you can't see your login email.
        </p>
        <Button
          variant="default"
          onClick={() =>
            auth.loginWithRedirect({
              authorizationParams: {
                redirect_uri: `${window.location.origin}/dashboard`,
              },
            })
          }
        >
          Log In
        </Button>
      </div>
    </PageWithTitle>
  );
}
