import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import useOrgCreate from "@/lib/hooks/mutations/useOrgCreate";
import { zStringRequired } from "@/lib/schemas/helpers";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { CreateOrgCommand } from "../../../../crates/iam/bindings/CreateOrgCommand";

const createOrgSchema = z.object({
  legal_company_name: zStringRequired,
  position_at_company: zStringRequired,
  business_unit: z.string().optional(),
});

export default function CreateOrgPage() {
  const createOrgMutation = useOrgCreate();
  const currentUser = useCurrentUserContext();
  const createOrgForm = useForm({
    resolver: zodResolver(createOrgSchema),
    defaultValues: {
      legal_company_name: "",
      position_at_company: "",
      business_unit: "",
    },
  });

  const handleSubmit = createOrgForm.handleSubmit(async (data) => {
    const body: CreateOrgCommand = {
      ...data,
      user_id: currentUser.id,
    };
    createOrgMutation.mutate(body);
  });

  return (
    <DashboardOutlet title="Provider Application">
      <div className="max-w-2xl">
        <div className="mb-12 prose xl:prose-lg prose-slate">
          <h2 className="text-xl font-bold">Create an Organization</h2>
          <p>
            Before you can start the application to become an AccountAbility
            Licensed Assurance Provider and invite other individuals in your
            organisation as users, we first need a bit of information about you
            and your organization.
          </p>
        </div>
        <Form {...createOrgForm}>
          <form onSubmit={handleSubmit} className="space-y-6">
            <FormField
              name="legal_company_name"
              control={createOrgForm.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Legal Company Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormDescription></FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="position_at_company"
              control={createOrgForm.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Your position at the company</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormDescription></FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="business_unit"
              control={createOrgForm.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Business unit you're part of</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormDescription>Optional.</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" disabled={createOrgMutation.isLoading}>
              Next
            </Button>
          </form>
        </Form>
      </div>
    </DashboardOutlet>
  );
}
