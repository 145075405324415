import BillingAccountSelector from "@/components/BillingAccountSelector";
import DashboardOutlet from "@/components/templates/DashboardOutlet";
import PageLoader from "@/components/ui/PageLoader";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import useCreateCheckoutSessionForReports from "@/lib/hooks/mutations/useCreateCheckoutSessionForReports";
import useRaiseInvoiceForReports from "@/lib/hooks/mutations/useRaiseInvoiceForReports";
import { RouterOutputs, trpc } from "@/lib/trpc";
import clsx from "clsx";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function PayForReportsPage() {
  const location = useLocation();

  const currentUser = useCurrentUserContext();
  const { data: org } = trpc.getOrgById.useQuery(currentUser.orgs[0].org_id);
  const { data: invoiceCosts } = trpc.getInvoiceCosts.useQuery(
    {
      billingAccountId: org?.billingAccounts[0].billing_account_id || "",
      reportIds: location.state?.reportIds,
    },
    { enabled: !!org?.billingAccounts[0].billing_account_id }
  );

  const [selectedBillingAccount, setSelectedBillingAccount] = useState<
    RouterOutputs["getBillingAccountsByOrgId"][number] | null
  >(null);
  const [note, setNote] = useState<string>("");

  const raiseInvoiceMutation = useRaiseInvoiceForReports();
  const createCheckoutMutation = useCreateCheckoutSessionForReports();

  function handleInvoice() {
    if (!selectedBillingAccount || !location.state?.reportIds?.length) return;
    raiseInvoiceMutation.mutate({
      billingAccountId: selectedBillingAccount.billing_account_id,
      reportIds: location.state.reportIds,
      providerNote: note || null,
    });
  }

  function handleCheckout() {
    if (!selectedBillingAccount || !location.state?.reportIds?.length) return;

    createCheckoutMutation.mutate({
      reportIds: location.state.reportIds,
      providerNote: note || null,
      billingAccountId: selectedBillingAccount.billing_account_id,
      cancelUrl: window.location.href,
      successUrl: `${window.location.origin}/dashboard/reports`,
    });
  }
  const disableButtons =
    !selectedBillingAccount ||
    raiseInvoiceMutation.isLoading ||
    createCheckoutMutation.isLoading;

  return (
    <DashboardOutlet title="Pay for Reports">
      {!location.state?.reportIds?.length ? (
        <p>
          No reports have been selected. You must select reports from{" "}
          <Link to="/dashboard/reports/payable" className="underline">
            this page
          </Link>{" "}
          in order to pay for them here.
        </p>
      ) : (
        <div className="max-w-2xl space-y-12">
          <div className="bg-accent p-4 prose prose-slate xl:prose-lg rounded-lg">
            <p>
              You can now pay for your selected reports. Tiered discounts will
              be applied based on how many reports you've invoiced in the
              current subscription period. Please select a billing account to
              proceed.
            </p>
            <p>
              Your billing account details will be included on the invoice. If
              you add a note, this will also be included on the invoice.
            </p>
            {invoiceCosts?.length ? (
              <>
                <h5>Included Report IDs</h5>
                <ul>
                  {invoiceCosts.map((reportPrice) => (
                    <li key={reportPrice.reportId}>
                      {`${reportPrice.reportId}: £${
                        reportPrice.price.amount_cents / 100
                      }`}
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <div className="py-10 relative">
                <PageLoader />
              </div>
            )}
          </div>
          <BillingAccountSelector
            onSelectBillingAccount={setSelectedBillingAccount}
            selectedBillingAccount={selectedBillingAccount}
          />
          <div>
            <Label className="mb-2">Add Note (optional)</Label>
            <Textarea
              rows={2}
              onChange={(e) => setNote(e.currentTarget.value)}
            />
            <p
              className={clsx(
                "text-sm text-muted-foreground",
                note.length > 255 && "text-red-600"
              )}
            >
              255 character maximum
            </p>
          </div>
          <div className="flex gap-x-6">
            <Button
              variant="outline"
              disabled={disableButtons}
              onClick={handleInvoice}
            >
              Request Invoice
            </Button>
            <Button disabled={disableButtons} onClick={handleCheckout}>
              Pay Online Now
            </Button>
          </div>
        </div>
      )}
    </DashboardOutlet>
  );
}
