import TaxCertificateRequestsForm from "@/components/TaxCertificateRequestForm";
import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { useSearchParams } from "react-router-dom";

export default function RequestTaxCertificatePage() {
  const [urlSearchParams] = useSearchParams();
  const relatedInvoiceId = urlSearchParams.get("relatedInvoiceId");
  return (
    <DashboardOutlet title="Request Tax Certificate">
      <TaxCertificateRequestsForm relatedInvoiceId={relatedInvoiceId} />
    </DashboardOutlet>
  );
}
