import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div
      id="error-page"
      className="flex flex-col items-center justify-center w-full h-screen gap-4"
    >
      <h1 className="text-2xl">Error</h1>
      <p className="text-xl">
        {error?.statusText ||
          error?.message ||
          "Sorry, an unexpected error has occurred."}
      </p>
    </div>
  );
}
