import UserAccountMenu from "../UserAccountMenu";
import { TypographyH1 } from "../ui/Typography";

export default function DashboardOutlet({
  children,
  title,
}: {
  children: React.ReactNode | React.ReactNode[];
  title: string;
}) {
  return (
    <>
      <main className="lg:pl-72">
        <div className="max-w-screen-xl px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
          <TypographyH1>{title}</TypographyH1>
          {children}
          <div className="absolute top-5 right-5">
            <UserAccountMenu />
          </div>
        </div>
      </main>
    </>
  );
}
