import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import { OrgRemoveMembershipRequestCommand } from "../../../../../crates/iam/bindings/OrgRemoveMembershipRequestCommand";
import { AxiosError } from "axios";
import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";
import { defaultError, wait } from "@/lib/utils";
import { trpc } from "@/lib/trpc";

export default function useOrgRemoveMembershipRequest() {
  const { axiosInstance: axios } = useAuthTokenContext();
  const utils = trpc.useUtils();
  return useMutation({
    mutationFn: (data: OrgRemoveMembershipRequestCommand) => {
      return axios({
        url: `${
          import.meta.env.VITE_IAM_API_URL
        }/org/remove-membership-request`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data,
      });
    },
    onSuccess: (_, variables) => {
      toast.success("Request removed.");
      wait(2000).then(() => utils.getOrgById.refetch(variables.org_id));
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
