export default function PageWithTitle({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) {
  return (
    <div className="px-4 pt-8 mx-auto max-w-screen-xl">
      <h1 className="mb-16 text-4xl font-extrabold tracking-tight text-center scroll-m-20 lg:text-5xl text-slate-900">
        {title}
      </h1>
      {children}
    </div>
  );
}
