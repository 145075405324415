import PageLoader from "@/components/ui/PageLoader";
import { useAuth0 } from "@auth0/auth0-react";
import React, { createContext, useContext } from "react";
import { RouterOutputs, trpc } from "../trpc";
import { useImpersonateContext } from "./ImpersonateContext";

const CurrentUserContext = createContext<RouterOutputs["getUserById"] | null>(
  null
);

export const useCurrentUserContext = () => {
  const currentUser = useContext(CurrentUserContext);
  if (!currentUser) {
    throw new Error("CurrentUserContext: No value provided");
  }
  return currentUser;
};

/**
 *
 * @returns Either the impersonated user if set, or the actual user
 */
export const CurrentUserContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const auth = useAuth0();
  const { impersonatedUser } = useImpersonateContext();
  const currentUserQuery = trpc.getUserById.useQuery(auth.user?.sub!, {
    enabled: !!auth.user?.sub,
  });
  if (currentUserQuery.isLoading) {
    return (
      <div>
        <PageLoader />
      </div>
    );
  }
  if (currentUserQuery.isError || !currentUserQuery.data) {
    throw new Error(currentUserQuery.error?.message);
  }

  return (
    <CurrentUserContext.Provider
      value={impersonatedUser || currentUserQuery.data}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};
