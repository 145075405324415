import { trpc } from "@/lib/trpc";
import { defaultError, wait } from "@/lib/utils";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";
import { toast } from "sonner";
import { ReportDownloadMarkingsCommand } from "../../../../../crates/e-licensing/bindings/ReportDownloadMarkingsCommand";
import getReportMarkings from "@/lib/markings/getReportMarkings";

export default function useReportDownloadMarkings(providerId: string) {
  const utils = trpc.useUtils();
  const { axiosInstance: axios } = useAuthTokenContext();
  return useMutation({
    mutationFn: (data: ReportDownloadMarkingsCommand) => {
      return axios({
        url: `${import.meta.env.VITE_ELIC_API_URL}/report/download-markings`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data,
      });
    },
    onMutate: () => {
      toast.info("Downloading...");
    },
    onSuccess: (_, variables) => {
      utils.getEngagementById.setData(variables.report_id, (prev) =>
        prev
          ? {
              ...prev,
              markings_first_downloaded_at: new Date().toISOString(),
            }
          : prev
      );
      getReportMarkings(providerId, variables.report_id);
      wait(2000).then(() =>
        utils.getEngagementById.invalidate(variables.report_id)
      );
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
