import { trpc } from "@/lib/trpc";
import { defaultError, wait } from "@/lib/utils";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";
import { toast } from "sonner";
import { ReportSuEditCommand } from "../../../../../crates/e-licensing/bindings/ReportSuEditCommand";

export default function useReportSuEdit() {
  const utils = trpc.useUtils();
  const { axiosInstance: axios } = useAuthTokenContext();
  return useMutation({
    mutationFn: (data: ReportSuEditCommand) => {
      return axios({
        url: `${import.meta.env.VITE_ELIC_API_URL}/report/su-edit`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data,
      });
    },
    onSuccess: (_, variables) => {
      toast.success("Updated.");
      //todo
      // utils.getEngagementById.setData(variables.report_id, (prev) =>
      //   prev
      //     ? {
      //         ...prev,
      //         report: prev.report
      //           ? {
      //               ...prev.report,
      //               report_url: variables.new_url || "",
      //             }
      //           : null,
      //       }
      //     : prev
      // );
      wait(2000).then(() =>
        utils.getEngagementById.refetch(variables.report_id)
      );
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
