import BillingAccountForm from "@/components/BillingAccountForm";
import DashboardOutlet from "@/components/templates/DashboardOutlet";

export default function NewBillingAccountPage() {
  return (
    <DashboardOutlet title="New Billing Account">
      <BillingAccountForm isNew />
    </DashboardOutlet>
  );
}
