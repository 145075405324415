import { trpc } from "@/lib/trpc";
import { defaultError, wait } from "@/lib/utils";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";
import { toast } from "sonner";
import { SubmitLicenseApplicationCommand } from "../../../../../crates/e-licensing/bindings/SubmitLicenseApplicationCommand";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";

export default function useLicenseApplicationSubmit() {
  const utils = trpc.useUtils();
  const currentUser = useCurrentUserContext();
  const { axiosInstance: axios } = useAuthTokenContext();
  return useMutation({
    mutationFn: (data: SubmitLicenseApplicationCommand) => {
      return axios(
        `${import.meta.env.VITE_ELIC_API_URL}/license-application/submit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        }
      );
    },
    onSuccess: () => {
      toast.success("Application submitted.");
      wait(2000).then(() =>
        utils.getOrgById.refetch(currentUser.orgs[0]?.org_id)
      );
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
