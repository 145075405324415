import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { zStringRequired } from "@/lib/schemas/helpers";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Textarea } from "./ui/textarea";

export default function NotesFeed({
  notes,
  addNote,
}: {
  notes: string[];
  addNote: (note: string) => void;
}) {
  const form = useForm({
    resolver: zodResolver(z.object({ note: zStringRequired })),
    defaultValues: { note: "" },
  });
  const reversedNotes = [...notes].reverse();

  const handleSubmit = form.handleSubmit(async ({ note }) => {
    addNote(note);
    form.reset();
  });
  return (
    <div className="space-y-12 max-w-2xl">
      <div className="bg-accent p-4 rounded-lg">
        <Form {...form}>
          <form className="space-y-2" onSubmit={handleSubmit}>
            <FormField
              name="note"
              control={form.control}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Add A Note</FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <Button type="submit">Submit</Button>
          </form>
        </Form>
      </div>
      <div className="space-y-4">
        {reversedNotes.map((note) => (
          <p key={note} className="border-b p-2 whitespace-pre-wrap">
            {note}
          </p>
        ))}
      </div>
    </div>
  );
}
// <Alert key={note}>
//   <PenSquare className="h-4 w-4" />
//   {/* <AlertTitle>{actorName}</AlertTitle> */}
//   <AlertDescription>
//     <p className="whitespace-pre my-1">{note}</p>
//     {/* <p className="text-xs font-normal text-primary/80">
//       {new Date(timestamp).toLocaleString()}
//     </p> */}
//   </AlertDescription>
// </Alert>
