import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import { OrgInviteNewMemberCommand } from "../../../../../crates/iam/bindings/OrgInviteNewMemberCommand";
import { OrgGetInvitationTokenCommand } from "../../../../../crates/iam/bindings/OrgGetInvitationTokenCommand";
import { AxiosError, AxiosResponse } from "axios";
import { defaultError } from "@/lib/utils";
import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";

export default function useOrgInviteNewMember(
  setInviteLink: (link: string) => void
) {
  const { axiosInstance: axios } = useAuthTokenContext();
  return useMutation({
    mutationFn: async (data: OrgInviteNewMemberCommand) => {
      const invitationRes = await axios({
        url: `${import.meta.env.VITE_IAM_API_URL}/org/invite-new-member`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data,
      });
      const tokenCommand: OrgGetInvitationTokenCommand = {
        org_id: data.org_id,
        email: data.new_member_email,
        invitation_id: invitationRes.data,
      };
      return axios({
        url: `${import.meta.env.VITE_IAM_API_URL}/org/get-invitation-token`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: tokenCommand,
      });
    },
    onSuccess: (response: AxiosResponse) => {
      toast.success("Invitation link generated.");
      setInviteLink(`${window.location.origin}/join/${response.data}`);
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
