import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";
import { defaultError } from "@/lib/utils";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "sonner";
import { OrgGetInvitationTokenCommand } from "../../../../../crates/iam/bindings/OrgGetInvitationTokenCommand";
import useCopyText from "../useCopyText";

export default function useOrgGetInvitationToken() {
  const { axiosInstance: axios } = useAuthTokenContext();
  const { copyText } = useCopyText();
  return useMutation({
    mutationFn: async (data: OrgGetInvitationTokenCommand) => {
      return axios({
        url: `${import.meta.env.VITE_IAM_API_URL}/org/get-invitation-token`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data,
      });
    },
    onSuccess: (response: AxiosResponse) => {
      copyText(`${window.location.origin}/join/${response.data}`);
      toast.success("Invitation link copied.");
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
