export default async function getReportMarkings(
  providerId: string,
  reportId: string
) {
  const res = await fetch(
    `${import.meta.env.VITE_TRPC_URL}/getReportMarkings?batch=1`,
    {
      method: "POST",
      body: JSON.stringify({
        0: { providerId, reportId },
      }),
    }
  );
  const json = await res.json();

  const url = json[0].result.data;
  const link = document.createElement("a");
  link.href = url;
  link.download = `AA1000V3_Report_${providerId}_${reportId}.png`;
  link.click();
}
