import { trpc } from "@/lib/trpc";
import { defaultError, wait } from "@/lib/utils";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";
import { toast } from "sonner";
import { SaveLicenseApplicationCommand } from "../../../../../crates/e-licensing/bindings/SaveLicenseApplicationCommand";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";

export default function useLicenseApplicationSave() {
  const utils = trpc.useUtils();
  const currentUser = useCurrentUserContext();
  const { axiosInstance: axios } = useAuthTokenContext();
  return useMutation({
    mutationFn: (data: SaveLicenseApplicationCommand) => {
      return axios(
        `${import.meta.env.VITE_ELIC_API_URL}/license-application/save`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        }
      );
    },
    onSuccess: () => {
      toast.success("Application saved.", { duration: 4000 });
      wait(2500).then(() =>
        utils.getOrgById.refetch(currentUser.orgs[0]?.org_id)
      );
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
