import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import MultiSelect from "@/components/ui/multi-select";
import { countryLabels, getCountryCodeFromLabel } from "@/lib/countries";
import {
  aaReportStatusLapelMap,
  getAAPaymentStatusLabel,
  getAAReportStatusLabel,
} from "@/lib/label-maps";
import { RouterOutputs, trpc } from "@/lib/trpc";
import { zodResolver } from "@hookform/resolvers/zod";
import { ColumnDef } from "@tanstack/react-table";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { DataTable, ViewEditButtonLink } from "./ui/data-table";

export default function AAReports() {
  const [searchQuery, setSearchQuery] = useState<any>(defaultValues);
  const queryObject = convertSearchQuery(searchQuery);

  const query = trpc.searchReports.useQuery(queryObject, { cacheTime: 1 });

  return (
    <div className="space-y-12">
      <ReportSearchForm setSearchQuery={setSearchQuery} />
      {query.isLoading ? (
        <p>Loading...</p>
      ) : query.error ? (
        <p>Error loading reports.</p>
      ) : (
        <DataTable columns={AAColumns} data={query.data} showSearch={false} />
      )}
    </div>
  );
}

const searchSchema = z.object({
  provider_name: z.string().optional(),
  org_name: z.string().optional(),
  title: z.string().optional(),
  disclosure_period_year: z.string().optional(),
  disclosure_publication_year: z.string().optional(),
  country: z.string().optional(),
  status: z.string().optional(),
});

const defaultValues = {
  provider_name: "",
  org_name: "",
  title: "",
  disclosure_period_year: "",
  disclosure_publication_year: "",
  country: "All",
  status: "All",
};

function ReportSearchForm({
  setSearchQuery,
}: {
  setSearchQuery: (query: any) => void;
}) {
  const form = useForm({
    resolver: zodResolver(searchSchema),
    defaultValues,
  });
  const handleSubmit = form.handleSubmit((data) => {
    setSearchQuery(data);
  });
  return (
    <Accordion
      type="single"
      defaultValue="item-1"
      collapsible
      className="w-full"
    >
      <AccordionItem value="item-1">
        <AccordionTrigger className="pl-2">Search Form</AccordionTrigger>
        <AccordionContent>
          <Form {...form}>
            <form onSubmit={handleSubmit} className="pl-2 pt-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-12 gap-x-12">
                <FormField
                  name="status"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Status</FormLabel>
                        <FormControl>
                          <MultiSelect
                            field={field}
                            options={[
                              "All",
                              ...Object.values(aaReportStatusLapelMap),
                            ]}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  name="provider_name"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Provider</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormDescription>
                          Searches across Provider ID (exact match), Legal
                          Company Name, and Doing Business As / Doing Business
                          As International.
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  name="org_name"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Assured Org Name</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  name="title"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Title</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormDescription>
                          Searches across Report Title and Disclosure Title.
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  name="country"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Country</FormLabel>
                        <FormControl>
                          <MultiSelect
                            field={field}
                            options={["All", ...countryLabels]}
                          />
                        </FormControl>
                        <FormDescription>
                          Searches Country field on the report.
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  name="disclosure_period_year"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Disclosure Period Year</FormLabel>
                        <FormControl>
                          <Input {...field} type="number" min={0} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  name="disclosure_publication_year"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Disclosure Publication Year</FormLabel>
                        <FormControl>
                          <Input {...field} type="number" min={0} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </div>
              <div className="flex gap-x-3 mt-12">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => form.reset(defaultValues)}
                >
                  Reset
                </Button>
                <Button type="submit">Search</Button>
              </div>
            </form>
          </Form>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

const AAColumns: ColumnDef<RouterOutputs["searchReports"][number]>[] = [
  {
    id: "view/edit",
    cell: ({ row }) => (
      <ViewEditButtonLink to={`/dashboard/reports/${row.original.report_id}`} />
    ),
  },
  { header: "ID", accessorKey: "report_id" },
  {
    accessorFn: (row) =>
      `${row.provider.org.legal_company_name} ${row.provider.org.profile
        ?.doing_business_as} ${
        row.provider.org.profile?.doing_business_as_intl || ""
      }`,
    header: "Provider",
    cell: ({ row }) => {
      return (
        <div className="space-y-1">
          <p className="text-sm">
            {row.original?.provider.org.legal_company_name}
          </p>
          <p className="text-xs text-muted-foreground">
            {row.original.provider.provider_id}
          </p>
        </div>
      );
    },
  },
  { header: "Assured Org Name", accessorKey: "report.org_name" },
  {
    header: "Report / Disclosure Title",
    cell: ({ row }) => (
      <div className="text-sm">
        <p>{row.original.report?.assurance_report_title}</p>
        <p>{row.original.report?.disclosure_title}</p>
      </div>
    ),
  },
  {
    header: "Disclosure Period Year",
    accessorKey: "report.disclosure_period_year",
  },
  {
    header: "Disclosure Publication Year",
    accessorKey: "report.disclosure_publication_year",
  },
  { header: "Status", cell: ({ row }) => getAAReportStatusLabel(row.original) },
  {
    header: "Payment Status",
    cell: ({ row }) => getAAPaymentStatusLabel(row.original),
  },
];

function convertSearchQuery(searchQuery: any) {
  const query = {
    status:
      searchQuery.status !== "All"
        ? Object.entries(aaReportStatusLapelMap).find(
            ([_key, value]) => value === searchQuery.status
          )?.[0]
        : undefined,
    country:
      searchQuery.country !== "All"
        ? getCountryCodeFromLabel(searchQuery.country) || undefined
        : undefined,
    provider_name: searchQuery.provider_name || undefined,
    org_name: searchQuery.org_name || undefined,
    title: searchQuery.title || undefined,
    disclosure_period_year: searchQuery.disclosure_period_year || undefined,
    disclosure_publication_year:
      searchQuery.disclosure_publication_year || undefined,
  };

  return query;
}
