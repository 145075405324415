import PageWithTitle from "@/components/templates/PageWithTitle";
import PageLoader from "@/components/ui/PageLoader";
import { demoteHeadings } from "@/lib/utils";
import { useQuery } from "@tanstack/react-query";

export default function HelpAndInfo() {
  const { isLoading, isError, data } = useQuery({
    queryKey: ["help-and-info"],
    queryFn: helpAndInfoQueryFunction,
  });
  return (
    <PageWithTitle title="Help & Info">
      {isLoading ? (
        <PageLoader />
      ) : isError ? (
        <p>Error loading page. Please refresh.</p>
      ) : (
        <div className="mx-auto prose xl:prose-lg prose-slate">
          <div
            dangerouslySetInnerHTML={{
              __html: demoteHeadings(data.data.attributes.content),
            }}
          />
          <div>
            <h2>Links</h2>
            <ul>
              {data.data.attributes.links.map((link) => (
                <li key={link.id}>
                  <a href={link.url} target="_blank">
                    {link.text}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h2>Downloads</h2>
            <ul>
              {data.data.attributes.downloads
                .sort((a, b) => a.id > b.id)
                .map((download) => (
                  <li key={download.id}>
                    <a href={download.file.data.attributes.url} target="_blank">
                      {download.name}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
    </PageWithTitle>
  );
}

function helpAndInfoQueryFunction() {
  return fetch(
    `${
      import.meta.env.VITE_STRAPI_URL
    }/api/help-and-info?populate[downloads][populate][0]=file&populate[links]=*`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${import.meta.env.VITE_STRAPI_TOKEN}`,
      },
    }
  ).then((res) => res.json());
}
