import { Button, ButtonProps } from "@/components/ui/button";
import { Clipboard, ClipboardCheck } from "lucide-react";
import useCopyText from "@/lib/hooks/useCopyText";

export default function CopyTextButton({
  label,
  text,
  variant,
}: {
  label: string;
  text: string;
  variant?: ButtonProps["variant"];
}) {
  const { copyText, copied } = useCopyText();

  return (
    <Button onClick={() => copyText(text)} variant={variant}>
      {label}
      {copied ? (
        <ClipboardCheck className="w-4 h-4 ml-2" />
      ) : (
        <Clipboard className="w-4 h-4 ml-2" />
      )}
    </Button>
  );
}
