import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import useCreateCheckoutSessionForProviderLicense from "@/lib/hooks/mutations/useCreateCheckoutSessionForProviderLicense";
import useRaiseInvoiceForProviderLicense from "@/lib/hooks/mutations/useRaiseInvoiceForProviderLicense";
import { RouterOutputs, trpc } from "@/lib/trpc";
import { useState } from "react";
import BillingAccountSelector from "./BillingAccountSelector";
import { TypographyH3 } from "./ui/Typography";
import { Button } from "./ui/button";
import { Label } from "./ui/label";
import { Textarea } from "./ui/textarea";

export default function PayForLicense() {
  const currentUser = useCurrentUserContext();
  const { data: licenseApplication } =
    trpc.getLicenseApplicationByOrgId.useQuery(currentUser.orgs[0].org_id);
  const { data: billingAccounts } = trpc.getBillingAccountsByOrgId.useQuery(
    currentUser.orgs[0].org_id
  );
  const [selectedBillingAccount, setSelectedBillingAccount] = useState<
    RouterOutputs["getBillingAccountsByOrgId"][number] | null
  >(null);
  const [note, setNote] = useState<string>("");
  const invoiceMutation = useRaiseInvoiceForProviderLicense();
  const checkoutMutation = useCreateCheckoutSessionForProviderLicense();

  function handleInvoice() {
    if (!licenseApplication) return;
    invoiceMutation.mutate({
      billingAccountId: selectedBillingAccount!.id,
      providerNote: note || null,
      providerLicenseApplicationId: licenseApplication.license_application_id,
    });
  }
  function handleCheckout() {
    if (!licenseApplication) return;
    checkoutMutation.mutate({
      billingAccountId: selectedBillingAccount!.id,
      providerNote: note || null,
      providerLicenseApplicationId: licenseApplication.license_application_id,
      cancelUrl: window.location.href,
      successUrl: `${window.location.origin}/dashboard`,
    });
  }

  const disableButtons =
    !selectedBillingAccount ||
    invoiceMutation.isLoading ||
    checkoutMutation.isLoading;

  // todo check this
  // checks for open invoice across all billing accounts
  if (
    billingAccounts?.find((a) =>
      a.invoices.find(
        (b) =>
          b.stripe_invoice?.metadata?.aa_provider_license_application_id &&
          b.status === "Open"
      )
    )
  ) {
    return <p>Awaiting invoice payment</p>;
  }

  return (
    <div className="max-w-2xl space-y-12">
      <TypographyH3>Pay For Provider License</TypographyH3>
      <div className="bg-accent p-4 prose prose-slate xl:prose-lg rounded-lg">
        <p>
          You can now pay for your provider license. Please select a billing
          account to proceed.
        </p>
        <p>
          Your billing account details will be included on the invoice. If you
          add a note, this will also be included on the invoice
        </p>
      </div>
      <BillingAccountSelector
        onSelectBillingAccount={setSelectedBillingAccount}
        selectedBillingAccount={selectedBillingAccount}
      />
      <div>
        <Label className="mb-2">Add Note (optional)</Label>
        <Textarea onChange={(e) => setNote(e.currentTarget.value)} />
      </div>
      <div className="flex gap-x-6">
        <Button
          variant="outline"
          disabled={disableButtons}
          onClick={handleInvoice}
        >
          Request Invoice
        </Button>
        <Button disabled={disableButtons} onClick={handleCheckout}>
          Pay Online Now
        </Button>
      </div>
    </div>
  );
}
