import * as z from "zod";
import { zStringRequired } from "./helpers";

export const memberSchema = z.object({
  id: z.string(),
  email: z.string(),
  role: z.enum(["Basic", "Admin"]),
  status: z.enum(["Active", "Inactive"]),
  business_unit: z.string().optional(),
  first_name: z.string(),
  last_name: z.string(),
  position_at_company: z.string(),
  phone: z.string(),
});

export type Member = z.infer<typeof memberSchema>;

export const invitedMemberSchema = z.object({
  id: z.string(),
  email: z.string(),
  role: z.enum(["Basic", "Admin"]),
  invitation_id: z.string(),
});

export type InvitedMember = z.infer<typeof invitedMemberSchema>;

export const memberRequestSchema = z.object({
  email: z.string().email(),
  business_unit: z.string().optional(),
  first_name: zStringRequired,
  last_name: zStringRequired,
  position_at_company: zStringRequired,
  phone: z.string().optional(),
});

export type MemberRequestFormType = z.infer<typeof memberRequestSchema>;

export const userSchema = z.object({
  id: z.string(),
  email: z.string(),
  role: z.enum(["Basic", "Admin"]).optional(),
  status: z.enum(["Active", "Inactive"]).optional(),
  business_unit: z.string().optional(),
  first_name: z.string(),
  last_name: z.string(),
  position_at_company: z.string().optional(),
  phone: z.string(),
  org_name: z.string().optional(),
  org_id: z.string().optional(),
});

export type User = z.infer<typeof userSchema>;
