import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { DataTable, ViewEditButtonLink } from "@/components/ui/data-table";
import { getCountryLabelFromCode } from "@/lib/countries";
import { getAAReportStatusLabel } from "@/lib/label-maps";
import { RouterOutputs, trpc } from "@/lib/trpc";
import { formatFullDate } from "@/lib/utils";
import { ColumnDef } from "@tanstack/react-table";
import { Link } from "react-router-dom";

const columns: ColumnDef<RouterOutputs["getPendingPostEngagements"][number]>[] =
  [
    {
      id: "view/edit",
      cell: ({ row }) => (
        <ViewEditButtonLink
          to={`/dashboard/reports/${row.original.report_id}`}
        />
      ),
    },
    {
      id: "provider",
      header: "Provider",
      cell: ({ row }) => (
        <Link to={`/dashboard/orgs/${row.original.provider.org.org_id}`}>
          <ul className="text-xs list-none">
            <li>{row.original.provider.org.legal_company_name}</li>
            <li>
              {row.original.provider.org.profile?.doing_business_as}

              {row.original.provider.org.profile?.doing_business_as_intl ? (
                <span>
                  {" "}
                  / {row.original.provider.org.profile?.doing_business_as_intl}
                </span>
              ) : null}
            </li>
            <li>
              {getCountryLabelFromCode(
                row.original.provider.org.profile?.business_country as any
              )}
            </li>
          </ul>
        </Link>
      ),
    },
    {
      id: "provider-privileges",
      header: "Markings / Pre-check Privileges",
      cell: ({ row }) => (
        <ul className="text-xs list-none space-y-1">
          <li>{row.original.provider.markings_dl_privilege_level}</li>
          <li>{row.original.provider.pre_engagement_check_level}</li>
        </ul>
      ),
    },
    {
      accessorKey: "report.org_name",
      header: "Org Name",
    },
    {
      accessorKey: "report.disclosure_title",
      header: "Disclosure Title",
    },
    {
      accessorKey: "report.assurance_report_title",
      header: "Report Title",
    },
    {
      accessorFn: (row) =>
        getCountryLabelFromCode(
          row.preEngagement.fields.country_or_region as any
        ),
      header: "Country",
      cell: ({ row }) =>
        getCountryLabelFromCode(
          row.original.preEngagement.fields.country_or_region as any
        ),
    },
    {
      id: "created",
      header: "Created",
      cell: ({ row }) =>
        formatFullDate(new Date(row.original.preEngagement.fields.created_at)),
    },
    {
      id: "status",
      header: "Status",
      cell: ({ row }) => getAAReportStatusLabel(row.original),
    },
  ];

export default function ReportChecksPage() {
  const postEngagementQuery = trpc.getPendingPostEngagements.useQuery();
  return (
    <DashboardOutlet title="Report Checks">
      {postEngagementQuery.isLoading ? (
        <div>Loading...</div>
      ) : postEngagementQuery.isError || !postEngagementQuery.data ? (
        <div>Error loading reports.</div>
      ) : (
        <DataTable columns={columns} data={postEngagementQuery.data} />
      )}
    </DashboardOutlet>
  );
}
