import { trpc } from "@/lib/trpc";
import { defaultError, wait } from "@/lib/utils";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";
import { toast } from "sonner";
import { ReportStartNewCommand } from "../../../../../crates/e-licensing/bindings/ReportStartNewCommand";

export default function useReportStartNew() {
  const utils = trpc.useUtils();
  const { axiosInstance: axios } = useAuthTokenContext();
  return useMutation({
    mutationFn: (data: ReportStartNewCommand) => {
      return axios({
        url: `${import.meta.env.VITE_ELIC_API_URL}/report/start-new`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data,
      });
    },
    onSuccess: (_, variables) => {
      wait(2000).then(() =>
        utils.getEngagementById.refetch(variables.report_id)
      );
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
