import { cn } from "@/lib/utils";
import { Listbox } from "@headlessui/react";
import { Check, ChevronDown } from "lucide-react";

export default function MultiSelect({
  multiple,
  field,
  options,
  ...props
}: {
  multiple?: boolean;
  field: any;
  options: string[];
  [key: string]: any;
}) {
  const valueToDisplay = multiple
    ? field.value.length
      ? field.value.join(", ")
      : "Select"
    : field.value || "Select";
  return (
    <Listbox multiple={multiple} {...field} {...props} as="div">
      <div className="relative">
        <Listbox.Button className="flex [min-height:2.5rem] w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-80">
          <span className="block text-left">{valueToDisplay}</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronDown className="w-5 h-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {options.map((option) => (
            <Listbox.Option
              key={option}
              value={option}
              className={({ active }) =>
                cn(
                  active ? "bg-primary/80 text-white" : "text-primary",
                  "relative cursor-default select-none py-2 pl-8 pr-4"
                )
              }
            >
              {({ selected, active }) => (
                <>
                  <span
                    className={cn(
                      selected ? "font-semibold" : "font-normal",
                      "block truncate"
                    )}
                  >
                    {option}
                  </span>

                  {selected ? (
                    <span
                      className={cn(
                        active ? "text-white" : "text-primary",
                        "absolute inset-y-0 left-0 flex items-center pl-1.5"
                      )}
                    >
                      <Check className="w-5 h-5" aria-hidden="true" />
                    </span>
                  ) : null}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  );
}
