import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import MultiSelect from "@/components/ui/multi-select";
import { Textarea } from "@/components/ui/textarea";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import { requiredErrorMap, zStringRequired } from "@/lib/schemas/helpers";
import { trpc } from "@/lib/trpc";
import { defaultError } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

export default function FeedbackPage() {
  const currentUser = useCurrentUserContext();
  const feedbackForm = useForm({
    resolver: zodResolver(
      z.object({
        type: z.union([
          z.literal("Feedback", requiredErrorMap),
          z.literal("Feature Request", requiredErrorMap),
          z.literal("Other", requiredErrorMap),
        ]),
        message: zStringRequired,
      })
    ),
    defaultValues: { type: "", message: "" },
  });
  const feedbackMutation = trpc.submitFeedback.useMutation({
    onSuccess: () => {
      toast.success("Feedback submitted, thank you.");
      feedbackForm.reset();
    },
    onError: (error) => {
      console.log(error);
      toast.error(defaultError);
    },
  });
  const handleSubmitFeedbackForm = feedbackForm.handleSubmit((data) => {
    feedbackMutation.mutate({
      ...data,
      first_name: currentUser.profile.first_name,
      last_name: currentUser.profile.last_name,
      email: currentUser.email,
      user_id: currentUser.user_id,
      timestamp: new Date().toISOString(),
    });
  });

  return (
    <DashboardOutlet title="Feedback & Feature Requests">
      <div className="max-w-2xl space-y-12">
        <div className="prose prose-slate">
          <p>
            We are always looking for ways to improve your experience. If you
            have any feedback or suggestions, please let us know by filling out
            the form below.
          </p>
          <p className="font-medium">
            If you have a problem to report or are in need of assistance, please
            contact{" "}
            <a
              href={`mailto:${import.meta.env.VITE_AA_SENDING_EMAIL}`}
              target="_blank"
            >
              {import.meta.env.VITE_AA_SENDING_EMAIL}
            </a>{" "}
            directly.
          </p>
        </div>
        <Form {...feedbackForm}>
          <form onSubmit={handleSubmitFeedbackForm} className="space-y-6">
            <FormField
              name="type"
              control={feedbackForm.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Type</FormLabel>
                  <FormControl>
                    <MultiSelect
                      field={field}
                      options={["Feedback", "Feature Request", "Other"]}
                      placeholder="Select"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="message"
              control={feedbackForm.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Message</FormLabel>
                  <FormControl>
                    <Textarea {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" disabled={feedbackMutation.isLoading}>
              Submit
            </Button>
          </form>
        </Form>
      </div>
    </DashboardOutlet>
  );
}
