import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import PreEngagementForm from "@/components/PreEngagementForm";
import DashboardOutlet from "@/components/templates/DashboardOutlet";

export default function NewReport() {
  const currentUser = useCurrentUserContext();
  const providerId = currentUser.orgs?.[0]?.provider_details?.provider_id;
  if (!providerId)
    throw new Error("You must be a licensed provider to create reports.");
  return (
    <DashboardOutlet title="New Report">
      <PreEngagementForm
        isEditable
        providerId={providerId}
        engagement={undefined}
      />
    </DashboardOutlet>
  );
}
