import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Check, XIcon } from "lucide-react";
import { Link } from "react-router-dom";
import LicenseApplicationForm from "@/components/LicenseApplicationForm";
import { Checkbox } from "@/components/ui/checkbox";
import PayForLicense from "@/components/PayForLicense";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import { trpc } from "@/lib/trpc";
import useLicenseApplicationAcceptStdAgreement from "@/lib/hooks/mutations/useLicenseApplicationAcceptStdAgreement";
import { toast } from "sonner";
import { TypographyH3 } from "@/components/ui/Typography";

export default function ProviderApplicationPage() {
  const currentUser = useCurrentUserContext();
  const acceptStdAgreementMutation = useLicenseApplicationAcceptStdAgreement();
  const mongoAcceptStdAgreementMutation =
    trpc.acceptLicensingAgreement.useMutation();
  const [declarationChecked, setDeclarationChecked] = useState(false);
  if (!currentUser.orgs[0]?.org_id) return null;

  const orgQuery = trpc.getOrgById.useQuery(currentUser.orgs[0].org_id);

  const application = orgQuery.data?.license_application;

  function handleAcceptAgreement() {
    if (!declarationChecked) {
      toast.error(
        "You must agree to the terms of the license agreement before you can proceed."
      );
      return;
    }
    acceptStdAgreementMutation.mutate({
      license_application_id:
        orgQuery.data!.license_application!.license_application_id,
    });
    mongoAcceptStdAgreementMutation.mutate({
      address: orgQuery.data!.profile!.business_address,
      org_id: orgQuery.data!.org_id,
      user_id: currentUser.user_id!,
      timestamp: new Date().toDateString(),
      doingBusinessAs: orgQuery.data!.profile!.doing_business_as,
    });
  }

  if (application?.status === "Complete") return null;

  const displayForm = ![
    "ApplicationSubmitted",
    "ApplicationRejected",
    "ApplicationApproved",
    "StandardLicenseAgreementAccepted",
    "CustomLicenseAgreementAdded",
  ].includes(application?.status || "");
  const showInstructions =
    !application || application.status === "ApplicationStarted";

  return (
    <DashboardOutlet title="Apply">
      {orgQuery.isLoading ? (
        <div>Loading...</div>
      ) : orgQuery.isError || !orgQuery.data ? (
        <div>Error loading org.</div>
      ) : (
        <div className="max-w-2xl">
          {showInstructions && (
            <div className="max-w-2xl space-y-6">
              <h2 className="text-xl font-bold">Apply To Be A Provider</h2>
              <Alert
                variant={orgQuery.data.profile ? "default" : "destructive"}
              >
                {orgQuery.data.profile ? (
                  <Check className="h-4 w-4" />
                ) : (
                  <XIcon className="h-4 w-4" />
                )}
                <AlertTitle>Note</AlertTitle>
                <AlertDescription>
                  <p className="mb-3">
                    You must have completed your company profile before you can
                    submit your initial application.
                  </p>
                  <Link
                    to="/dashboard/company-profile"
                    className="text-muted-foreground underline-offset-2 hover:underline"
                  >
                    Go to company profile
                  </Link>
                </AlertDescription>
              </Alert>
              <div className="prose xl:prose-lg prose-slate">
                <p>
                  You are about to initiate the process to sign up for an AA1000
                  Assurance Standard (AA1000AS v3) license from AccountAbility.
                </p>
                <p>
                  If you are able to meet all requirements of the AA1000AS v3
                  and license agreement, you can apply for an AA1000AS v3
                  license, which will be approved subject to review of your
                  application by AccountAbility. You maybe contacted if any
                  further information is required.
                </p>
              </div>
            </div>
          )}
          {displayForm && (
            <div className="not-prose mt-12">
              <LicenseApplicationForm org={orgQuery.data} />
            </div>
          )}

          {application?.status === "ApplicationSubmitted" && (
            <p>
              Thank you for submitting your application to become an
              AccountAbility licensed assurance provider. You will hear from us
              by email as soon as we have reviewed the application (usually
              within 2 weeks).
            </p>
          )}

          {application?.status === "ApplicationRejected" && (
            <div className="border border-destructive p-4 rounded-lg">
              <TypographyH3>Application Rejected</TypographyH3>
              <p className="mt-4">{application.rejection?.rejection_reason}</p>
            </div>
          )}

          {application?.status === "ApplicationApproved" && (
            <div className="prose xl:prose-lg prose-slate">
              <p>
                Thank you for your application to become an AccountAbility
                licensed assurance provider. Your application has been approved.
                You can now complete the process by accepting the{" "}
                <a
                  href="https://aa-files.nyc3.cdn.digitaloceanspaces.com/elicensing/accountability-aa1000as-v3-licensing-agreement_final_v2.pdf"
                  target="_blank"
                >
                  License Agreement
                </a>{" "}
                online and proceeding to payment.
              </p>
              <p>
                By clicking the button below, your company name and address will
                be added to the PDF along with a timestamp indicating the date
                of acceptance. You will then be asked to pay for your
                AccountAbility Assurance Provider License before you are able to
                start logging assurances. Your license is active from the date
                when AccountAbility receives payment.
              </p>
              <div className="mb-4 flex gap-x-4 items-center p-4 bg-accent rounded-lg font-semibold">
                <Checkbox
                  name="declaration-checkbox"
                  id="declaration-checkbox"
                  checked={declarationChecked}
                  onCheckedChange={(state) => setDeclarationChecked(!!state)}
                />
                <label htmlFor="declaration-checkbox">
                  I have read and agree to the terms of the license agreement on
                  behalf of my organization, and have authority to do so.
                </label>
              </div>
              <Button
                disabled={
                  !declarationChecked || acceptStdAgreementMutation.isLoading
                }
                onClick={handleAcceptAgreement}
              >
                Accept Licensing Agreement
              </Button>
            </div>
          )}

          {[
            "CustomLicenseAgreementAdded",
            "StandardLicenseAgreementAccepted",
          ].includes(application?.status || "") && <PayForLicense />}
        </div>
      )}
    </DashboardOutlet>
  );
}
