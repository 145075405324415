import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import UserProfileForm from "@/components/UserProfileForm";
import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import MultiSelect from "@/components/ui/multi-select";
import { Separator } from "@/components/ui/separator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import useOrgChangeMemberRole from "@/lib/hooks/mutations/useOrgChangeMemberRole";
import useOrgRemoveUserMembership from "@/lib/hooks/mutations/useOrgRemoveUserMembership";
import useUserUpdateEmail from "@/lib/hooks/mutations/useUserUpdateEmail";
import { getUserMembershipFromUser } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { UserUpdateEmailCommand } from "../../../crates/iam/bindings/UserUpdateEmailCommand";
import AlertDialogWithActions from "@/components/AlertDialogWithActions";
import { useImpersonateContext } from "@/lib/context/ImpersonateContext";
import ImpersonationControls from "@/components/ImpersonationControls";

export default function UserPage() {
  const currentUser = useCurrentUserContext();
  const { impersonatedUser } = useImpersonateContext();
  const hasMembership = currentUser.orgs?.length > 0;
  return (
    <DashboardOutlet title="Profile">
      <Tabs defaultValue="profile" className="max-w-2xl">
        <TabsList className="mb-12">
          <TabsTrigger value="profile">Your Profile</TabsTrigger>
          <TabsTrigger value="email">Change Email</TabsTrigger>
          {hasMembership && (
            <TabsTrigger value="org">Organisational Details</TabsTrigger>
          )}
        </TabsList>
        <TabsContent tabIndex={-1} value="profile">
          <UserProfileForm data={impersonatedUser || currentUser} />
        </TabsContent>
        <TabsContent tabIndex={-1} value="email">
          <ChangeEmailForm />
        </TabsContent>
        {hasMembership && (
          <TabsContent tabIndex={-1} value="org">
            <ChangeMemberOrgDetails />
          </TabsContent>
        )}
      </Tabs>
      {(!!impersonatedUser || currentUser.super_user_role === "SuperAdmin") && (
        <ImpersonationControls />
      )}
    </DashboardOutlet>
  );
}

function ChangeEmailForm() {
  const currentUser = useCurrentUserContext();
  const changeEmailMutation = useUserUpdateEmail();

  const emailForm = useForm({
    resolver: zodResolver(z.object({ email: z.string().email() })),
    values: { email: currentUser.email },
  });

  const handleChangeEmail = emailForm.handleSubmit(async function ({ email }) {
    const body: UserUpdateEmailCommand = {
      new_email: email,
      user_id: currentUser.id,
    };
    changeEmailMutation.mutate(body);
  });

  return (
    <Form {...emailForm}>
      <form onSubmit={handleChangeEmail} className="space-y-6">
        <FormField
          name="email"
          control={emailForm.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <AlertDialogWithActions
          title="Update Email"
          triggerText="Update Email"
          description="Are you sure you want to update your email? You must have access to your new email address or you risk losing access to your account."
          buttonProps={{
            type: "button",
            disabled: !emailForm.formState.isDirty,
          }}
          action={handleChangeEmail}
        />
      </form>
    </Form>
  );
}

function ChangeMemberOrgDetails() {
  const currentUser = useCurrentUserContext();
  const currentUserMembership = getUserMembershipFromUser(currentUser);
  if (!currentUserMembership) {
    throw new Error(
      "You must be a member of an organisation to access this area"
    );
  }
  const changeRoleMutation = useOrgChangeMemberRole();
  const removeMembershipMutation = useOrgRemoveUserMembership();

  const roleForm = useForm({
    resolver: zodResolver(z.object({ role: z.enum(["Basic", "Admin"]) })),
    values: { role: currentUserMembership?.role || "" },
  });
  const handleChangeRole = roleForm.handleSubmit(async function ({ role }) {
    changeRoleMutation.mutate({
      membership_id: currentUserMembership.id,
      new_member_role: role,
      org_id: currentUserMembership.org_id,
    });
  });
  function handleRemoveMembership() {
    removeMembershipMutation.mutate({
      membership_id: currentUserMembership!.id,
      org_id: currentUserMembership!.org_id,
    });
  }

  return (
    <div className="space-y-6">
      <p className="font-semibold">
        Member of: {currentUser.orgs[0].legal_company_name}
      </p>
      <Separator />
      <Form {...roleForm}>
        <form onSubmit={handleChangeRole}>
          <FormField
            name="role"
            control={roleForm.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Role</FormLabel>
                <FormControl>
                  <MultiSelect
                    disabled={currentUserMembership?.role !== "Admin"}
                    field={field}
                    options={["Basic", "Admin"]}
                  />
                </FormControl>
                <FormDescription>
                  {currentUserMembership?.role !== "Admin"
                    ? "To change this, request admin status from one of your organization's admins"
                    : null}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            type="submit"
            disabled={
              !roleForm.formState.isDirty || changeRoleMutation.isLoading
            }
            className="mt-2"
          >
            Update Role
          </Button>
        </form>
      </Form>
      <Separator />
      <p className="font-semibold">Danger Zone</p>
      <Button
        variant="destructive"
        onClick={handleRemoveMembership}
        disabled={removeMembershipMutation.isLoading}
      >
        Remove Membership
      </Button>
    </div>
  );
}
