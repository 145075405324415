import { Auth0Provider } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { Toaster } from "sonner";
import ErrorPage from "./components/ErrorPage";
import "./globals.css";
import DashboardRoot from "./routes/dashboard";
import DashboardHome from "./routes/dashboard.index";
import FeeStructure from "./routes/fee-structure";
import HelpAndInfo from "./routes/help-and-info";
import Homepage from "./routes/homepage";
import JoinByInvitation from "./routes/join.$invitationToken";
import OrgsPage from "./routes/orgs";
import OrgPage from "./routes/orgs.$orgId";
import PreEngagementChecksPage from "./routes/pre-engagement-checks";
import UserProfilePage from "./routes/profile";
import ProviderApprovalRequestsPage from "./routes/provider-approval-requests";
import ProviderAssetsPage from "./routes/provider-assets";
import ProviderApplicationPage from "./routes/providers/provider-application";
import BillingAccountsPage from "./routes/providers/billing-accounts";
import CompanyProfilePage from "./routes/providers/company-profile";
import CreateOrgPage from "./routes/providers/create-org";
import UserInvitationsPage from "./routes/providers/invitations";
import JoinOrgRequestPage from "./routes/providers/join-org-request";
import PaymentsPage from "./routes/providers/invoices";
import ReportsPage from "./routes/providers/reports";
import NewReport from "./routes/providers/reports.new";
import RequestTaxCertificatePage from "./routes/providers/request-tax-certificate";
import YourTaxCertificatesPage from "./routes/providers/your-tax-certificates";
import InviteUser from "./routes/providers/users.invite";
import ReportPage from "./routes/reports.$reportId";
import Root from "./routes/root";
import Signin from "./routes/sign-in";
import Signup from "./routes/sign-up";
import TaxCertificateRequestsPage from "./routes/tax-certificate-requests";
import Users from "./routes/users";
import UserPage from "./routes/users.$userId";
import { trpc, trpcClient } from "./lib/trpc";
import ReportChecksPage from "./routes/report-checks";
import Changelog from "./routes/changelog";
import TaxCertificatesPage from "./routes/tax-certificates";
import NewBillingAccountPage from "./routes/providers/billing-accounts.new";
import EditBillingAccountPage from "./routes/providers/billing-accounts.$accountId";
import TaxCertificateRequestPage from "./routes/tax-certificate-requests.$requestId";
import InvoicePage from "./routes/providers/invoices.$invoiceId";
import PayableReportsPage from "./routes/providers/reports.payable";
import PayForReportsPage from "./routes/providers/reports.pay";
import DashboardError from "./components/DashboardError";
import FAQs from "./routes/faqs";
import FeedbackPage from "./routes/feedback";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://2b36999b5266257951103767bea51a23@o4506424969330688.ingest.sentry.io/4506617481789440",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  tracesSampleRate: 1.0,
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

declare global {
  interface ImportMetaEnv {
    VITE_ELIC_API_URL: string;
    VITE_IAM_API_URL: string;
    VITE_PAYMENTS_API_URL: string;
    VITE_TRPC_URL: string;
    VITE_STRAPI_TOKEN: string;
    VITE_STRAPI_URL: string;
    VITE_AUTH0_DOMAIN: string;
    VITE_AUTH0_CLIENT_ID: string;
    VITE_AUTH0_AUDIENCE: string;
    VITE_AA_SENDING_EMAIL: string;
  }
}

const queryClient = new QueryClient();

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Homepage /> },
      { path: "/sign-up", element: <Signup /> },
      { path: "/sign-in", element: <Signin /> },
      { path: "/join/:invitationToken", element: <JoinByInvitation /> },
      { path: "/help-and-info", element: <HelpAndInfo /> },
      { path: "/fee-structure", element: <FeeStructure /> },
      { path: "/changelog", element: <Changelog /> },
      { path: "/faqs", element: <FAQs /> },
    ],
  },
  {
    path: "/dashboard",
    element: <DashboardRoot />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <DashboardHome /> },
      {
        path: "users/invite",
        element: <InviteUser />,
      },
      { path: "reports", element: <ReportsPage /> },
      { path: "reports/new", element: <NewReport /> },
      { path: "reports/:reportId", element: <ReportPage /> },
      { path: "reports/payable", element: <PayableReportsPage /> },
      { path: "reports/pay", element: <PayForReportsPage /> },
      { path: "users", element: <Users /> },
      { path: "users/:userId", element: <UserPage /> },
      { path: "profile", element: <UserProfilePage /> },
      { path: "create-org", element: <CreateOrgPage /> },
      { path: "company-profile", element: <CompanyProfilePage /> },
      { path: "provider-application", element: <ProviderApplicationPage /> },
      {
        path: "provider-approval-requests",
        element: <ProviderApprovalRequestsPage />,
      },
      { path: "orgs", element: <OrgsPage /> },
      { path: "orgs/:orgId", element: <OrgPage /> },
      { path: "billing-accounts", element: <BillingAccountsPage /> },
      { path: "billing-accounts/new", element: <NewBillingAccountPage /> },
      {
        path: "billing-accounts/:accountId",
        element: <EditBillingAccountPage />,
      },
      { path: "provider-assets", element: <ProviderAssetsPage /> },
      { path: "Invoices", element: <PaymentsPage /> },
      {
        path: "tax-certificate-requests",
        element: <TaxCertificateRequestsPage />,
      },
      { path: "invitations", element: <UserInvitationsPage /> },
      { path: "join-org-request", element: <JoinOrgRequestPage /> },
      { path: "pre-engagement-checks", element: <PreEngagementChecksPage /> },
      { path: "report-checks", element: <ReportChecksPage /> },
      {
        path: "request-tax-certificate",
        element: <RequestTaxCertificatePage />,
      },
      { path: "your-tax-certificates", element: <YourTaxCertificatesPage /> },
      { path: "tax-certificates", element: <TaxCertificatesPage /> },
      {
        path: "tax-certificate-requests/:requestId",
        element: <TaxCertificateRequestPage />,
      },
      { path: "invoices/:invoiceId", element: <InvoicePage /> },
      { path: "feedback", element: <FeedbackPage /> },
    ].map((route) => ({ ...route, errorElement: <DashboardError /> })),
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <Auth0Provider
          domain={import.meta.env.VITE_AUTH0_DOMAIN}
          clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
          cacheLocation="localstorage"
          useRefreshTokens
          authorizationParams={{
            redirect_uri: `${window.location.origin}/dashboard`,
            connection: "email",
            audience: import.meta.env.VITE_AUTH0_AUDIENCE,
          }}
        >
          <RouterProvider router={router} />
          <Toaster
            position="top-center"
            toastOptions={{
              classNames: {
                error: "[&_svg]:!text-red-500",
                success: "[&_svg]:!text-green-500",
                info: "[&_svg]:!text-blue-500",
              },
            }}
          />
        </Auth0Provider>
      </QueryClientProvider>
    </trpc.Provider>
  </React.StrictMode>
);
