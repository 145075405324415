import { trpc } from "@/lib/trpc";
import { wait } from "@/lib/utils";
import { toast } from "sonner";

/**
 * Warns the user at the start of the mutation,
 * and opens then opens the returned checkout session
 * in a new tab
 */
export default function useCreateCheckoutSessionForProviderLicense() {
  return trpc.createCheckoutSessionForProviderLicense.useMutation({
    onSuccess(data) {
      console.log(data);
      if (data.stripeCheckoutSession.url) {
        window.open(data.stripeCheckoutSession.url);
      } else {
        toast.error("Something went wrong, please try again");
      }
    },
    onError(error) {
      console.log(error);
      toast.error("Something went wrong, please try again");
    },
    onMutate() {
      wait(500).then(() =>
        toast.info(
          "Please wait while we create your checkout session. This can take up to 30 seconds.",
          {
            duration: 10000,
          }
        )
      );
    },
  });
}
