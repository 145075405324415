import { Badge } from "@/components/ui/badge";
import { Button, ButtonProps } from "@/components/ui/button";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import { trpc } from "@/lib/trpc";
import {
  BadgePlusIcon,
  Banknote,
  BookOpenCheckIcon,
  BookOpenIcon,
  Building,
  Download,
  ExternalLink,
  FileBadge2Icon,
  FileCheck,
  FileQuestion,
  FileStack,
  FileText,
  Info,
  LucideIcon,
  PresentationIcon,
  UserCheck,
  Users,
  WalletCards,
} from "lucide-react";
import { Link } from "react-router-dom";
import { UserStatus, getUserStatus } from "./getUserStatus";

interface SidebarLink {
  to?: string;
  label: string;
  admin?: boolean;
  variant?: ButtonProps["variant"];
  icon?: LucideIcon;
  display?: UserStatus[];
}

const providerLinks: SidebarLink[] = [
  { label: "E-Licensing", display: ["isProvider", "isOrgMember"] },
  {
    to: "/reports",
    label: "Reports",
    icon: FileStack,
    display: ["isProvider"],
  },
  {
    to: "/provider-assets",
    label: "Provider Assets",
    icon: Download,
    display: ["isProvider"],
  },
  {
    to: "/provider-application",
    label: "Provider Application",
    icon: FileQuestion,
    display: ["isOrgMember"],
  },
  { label: "Billing", display: ["isProvider", "isOrgMember"] },
  {
    to: "/invoices",
    label: "Invoices",
    admin: true,
    icon: Banknote,
    display: ["isProvider", "isOrgMember"],
  },
  {
    to: "/billing-accounts",
    label: "Billing Accounts",
    admin: true,
    icon: WalletCards,
    display: ["isProvider", "isOrgMember"],
  },
  {
    to: "/your-tax-certificates",
    label: "Tax Certificates",
    icon: FileBadge2Icon,
    display: ["isProvider", "isOrgMember"],
  },
  { label: "Your Organization" },
  {
    to: "/users",
    label: "Users",
    icon: Users,
    display: ["isOrgMember", "isProvider"],
  },
  {
    to: "/company-profile",
    label: "Company Profile",
    icon: Info,
    display: ["isOrgMember", "isProvider"],
  },
  {
    to: "/create-org",
    label: "Create Organization",
    icon: Building,
    display: ["isNew"],
  },
  {
    to: "/join-org-request",
    label: "Join Organization",
    icon: Users,
    display: ["isNew"],
  },
  {
    to: "/invitations",
    label: "Invitations",
    icon: BadgePlusIcon,
    display: ["isNew"],
  },
];

const aaLinks: SidebarLink[] = [
  { to: "/users", label: "Users", icon: Users },
  { to: "/orgs", label: "Organizations", icon: Building },
  { to: "/reports", label: "Reports", icon: FileStack },
  {
    to: "/provider-approval-requests",
    label: "Provider Requests",
    icon: UserCheck,
  },
  {
    to: "/tax-certificate-requests",
    label: "Tax Certificate Requests",
    icon: FileText,
  },
  {
    to: "/pre-engagement-checks",
    label: "Engagement Checks",
    icon: FileCheck,
  },
  {
    to: "/report-checks",
    label: "Report Checks",
    icon: BookOpenCheckIcon,
  },
];

function SidebarItem({
  label,
  to,
  variant,
  icon: IconComponent,
  pendingCount,
}: SidebarLink & { pendingCount?: number }) {
  return (
    <li>
      {to && IconComponent ? (
        <Button
          variant={variant || "ghost"}
          className="justify-start w-full relative px-2"
          asChild
          key={to}
        >
          <Link to={`/dashboard${to}`}>
            <IconComponent className="w-4 h-4 mr-1.5 inline" />
            <span>{label}</span>
            {pendingCount ? (
              <Badge
                variant="secondary"
                className="ml-auto text-secondary-foreground"
              >
                {pendingCount}
              </Badge>
            ) : null}
          </Link>
        </Button>
      ) : (
        <h2 className="px-2 mt-8 mb-2 text-lg font-semibold tracking-tight">
          {label}
        </h2>
      )}
    </li>
  );
}

export function Sidebar() {
  const currentUser = useCurrentUserContext();
  const openApplicationsQuery = currentUser.super_user_role
    ? trpc.getOrgsWithOpenLicenseApplications.useQuery()
    : { data: null };
  const pendingEngagementsQuery = currentUser.super_user_role
    ? trpc.getPendingPreEngagements.useQuery()
    : { data: null };
  const postEngagementQuery = currentUser.super_user_role
    ? trpc.getPendingPostEngagements.useQuery()
    : { data: null };
  const taxCertificateRequestsQuery = currentUser.super_user_role
    ? trpc.getTaxCertificateRequests.useQuery()
    : { data: null };

  const pendingAAItems: { [to: string]: number } = {
    "/provider-approval-requests": openApplicationsQuery.data?.length || 0,
    "/tax-certificate-requests": taxCertificateRequestsQuery.data?.length || 0,
    "/pre-engagement-checks": pendingEngagementsQuery.data?.length || 0,
    "/report-checks": postEngagementQuery.data?.length || 0,
  };

  const userStatus = getUserStatus(currentUser);
  return (
    <nav className="flex flex-col flex-1">
      <ul role="list" className="flex flex-col flex-1">
        {userStatus === "isSU"
          ? aaLinks.map((link) => (
              <SidebarItem
                key={link.label}
                {...link}
                pendingCount={pendingAAItems[link.to || ""]}
              />
            ))
          : providerLinks.map((link) =>
              link.display && !link.display.includes(userStatus) ? null : (
                <SidebarItem
                  key={link.label}
                  {...link}
                  pendingCount={pendingAAItems[link.to || ""]}
                />
              )
            )}
        {userStatus !== "isSU" ? (
          <>
            <li>
              <h2 className="px-2 mt-8 mb-2 text-lg font-semibold tracking-tight">
                Training
              </h2>
            </li>
            <li>
              <Button
                variant="ghost"
                className="justify-start w-full relative px-2"
                asChild
              >
                <a
                  href="https://www.accountability.org/standards/aa1000-training/"
                  target="_blank"
                >
                  <BookOpenIcon className="w-4 h-4 mr-1.5 inline" />
                  <span>AA1000 Training</span>
                  <ExternalLink className="w-4 h-4 ml-auto mr-1.5 inline" />
                </a>
              </Button>
            </li>
            <li>
              <Button
                variant="ghost"
                className="justify-start w-full relative px-2"
                asChild
              >
                <a
                  href="https://www.accountability.org/standards/licensed-training-providers/"
                  target="_blank"
                >
                  <PresentationIcon className="w-4 h-4 mr-1.5 inline" />
                  <span>Training Providers</span>
                  <ExternalLink className="w-4 h-4 ml-auto mr-1.5 inline" />
                </a>
              </Button>
            </li>
          </>
        ) : null}
      </ul>
    </nav>
  );
}
