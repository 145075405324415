import { trpc } from "@/lib/trpc";
import { defaultError, wait } from "@/lib/utils";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import { UserUpdateProfileCommand } from "../../../../../crates/iam/bindings/UserUpdateProfileCommand";
import { AxiosError } from "axios";
import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";

export default function useUserUpdateProfile() {
  const utils = trpc.useUtils();
  const { axiosInstance: axios } = useAuthTokenContext();
  return useMutation({
    mutationFn: (data: UserUpdateProfileCommand) => {
      return axios(`${import.meta.env.VITE_IAM_API_URL}/user/update-profile`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data,
      });
    },
    onSuccess: (_, variables) => {
      toast.success("Profile updated");
      utils.getUserById.setData(variables.user_id, (oldData) => {
        return oldData
          ? {
              ...oldData,
              profile: {
                first_name: variables.first_name,
                last_name: variables.last_name,
                phone: variables.phone || "",
              },
            }
          : oldData;
      });
      wait(2000).then(() => utils.getUserById.refetch(variables.user_id));
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
