import { trpc } from "@/lib/trpc";
import { defaultError, wait } from "@/lib/utils";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";
import { toast } from "sonner";
import { ApproveApplicationCommand } from "../../../../../crates/e-licensing/bindings/ApproveApplicationCommand";
import { useNavigate } from "react-router-dom";

export default function useLicenseApplicationApprove(orgId: string) {
  const utils = trpc.useUtils();
  const navigate = useNavigate();
  const { axiosInstance: axios } = useAuthTokenContext();
  return useMutation({
    mutationFn: (data: ApproveApplicationCommand) => {
      return axios(
        `${import.meta.env.VITE_ELIC_API_URL}/license-application/approve`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        }
      );
    },
    onSuccess: () => {
      toast.success("Application approved.");
      wait(2000).then(() => {
        navigate(`/dashboard/orgs/${orgId}`);
        utils.getOrgById.refetch(orgId);
      });
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
