import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import { OrgRemoveUserMembershipCommand } from "../../../../../crates/iam/bindings/OrgRemoveUserMembershipCommand";
import { AxiosError } from "axios";
import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";
import { defaultError, wait } from "@/lib/utils";
import { trpc } from "@/lib/trpc";

export default function useOrgRemoveUserMembership() {
  const { axiosInstance: axios } = useAuthTokenContext();
  const utils = trpc.useUtils();
  return useMutation({
    mutationFn: (data: OrgRemoveUserMembershipCommand) => {
      return axios(
        `${import.meta.env.VITE_IAM_API_URL}/org/remove-user-membership`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        }
      );
    },
    onSuccess: (_, variables) => {
      toast.success("Membership removed.");
      wait(2000).then(() => utils.getOrgById.refetch(variables.org_id));
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
