import { useEffect, useState } from "react";
import { Button } from "./ui/button";
import { toast } from "sonner";

const UPLOAD_PRESET = "wwsthvlq";
const CLOUD_NAME = "rubie";

declare global {
  interface Window {
    cloudinary: any;
  }
}

// This rigmarole is a bit crazy but the Cloudinary widget
// gets stuck when it's closed and reopened, so we have to
// destroy it and recreate it every time we want to open it.

export default function UploadWidget({
  onSuccess,
  value,
  disabled,
  tag,
}: {
  onSuccess: (fileDetails?: {
    cloudinary_url: string;
    original_file_name: string;
  }) => void;
  value?: {
    cloudinary_url: string;
    original_file_name: string;
  };
  disabled?: boolean;
  tag: string;
}) {
  const [widget, setWidget] = useState<any>({
    open: undefined,
    destroy: () => {},
  });
  const [iteration, setIteration] = useState({ n: 0, cmd: "create" });

  const config = {
    cloudName: CLOUD_NAME,
    uploadPreset: UPLOAD_PRESET,
    sources: ["local", "url"],
    multiple: false,
    tags: [tag],
  };

  useEffect(() => {
    widget.destroy();
    const createFunc =
      iteration.cmd === "create"
        ? window.cloudinary.createUploadWidget
        : window.cloudinary.openUploadWidget;
    const myWidget = createFunc(config, (error: any, result: any) => {
      console.log({ error, result });
      if (!error && result.event === "queues-end") {
        try {
          onSuccess({
            cloudinary_url: result.data.info.files[0].uploadInfo.secure_url,
            original_file_name: result.data.info.files[0].name,
          });
        } catch (error) {
          console.error(error);
          toast.error(
            "Error uploading file, please check your firewall isn't blocking *.cloudinary.com",
            { duration: 10000 }
          );
        }
      }
    });
    setWidget(myWidget);
    return () => myWidget.destroy();
  }, [iteration]);

  return value ? (
    <div className="mt-12 space-y-6">
      <a
        className="font-medium underline"
        href={value.cloudinary_url}
        target="_blank"
      >
        {decodeStr(value.original_file_name)}
      </a>
      <div className="flex gap-6 mt-4">
        <Button
          type="button"
          onClick={() => {
            setIteration(({ n }) => ({ cmd: "open", n: n + 1 }));
          }}
          variant="outline"
          disabled={disabled}
        >
          Change File
        </Button>
        <Button
          type="button"
          variant="destructive"
          onClick={() => {
            widget.destroy();
            setIteration(({ n }) => ({ cmd: "create", n: n + 1 }));
            onSuccess(undefined);
          }}
          disabled={disabled}
        >
          Remove File
        </Button>
      </div>
    </div>
  ) : (
    <Button
      type="button"
      onClick={widget.open}
      variant="outline"
      disabled={disabled}
    >
      Upload
    </Button>
  );
}

function decodeStr(str: string) {
  const decoded = decodeURIComponent(str);
  if (decoded.length > 50) {
    return decoded.slice(0, 50) + "...";
  }
  return decoded;
}
