import AAReports from "@/components/AAReports";
import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { TypographyH3 } from "@/components/ui/Typography";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { DataTable, ViewEditButtonLink } from "@/components/ui/data-table";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import { getUserReportStatusLabel } from "@/lib/label-maps";
import { RouterOutputs, trpc } from "@/lib/trpc";
import { formatFullDate } from "@/lib/utils";
import { ColumnDef } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import flowChart from "@/assets/elicensing_flow_chart_v1.png";

export default function ReportsPage() {
  const currentUser = useCurrentUserContext();
  return (
    <DashboardOutlet title="Reports">
      {currentUser.super_user_role ? <AAReports /> : <ProviderReports />}
    </DashboardOutlet>
  );
}

type EngagementDetails = NonNullable<
  RouterOutputs["getReportsByProviderId"]
>[number];

const reportsColumns: ColumnDef<EngagementDetails>[] = [
  {
    id: "view/edit",
    cell: ({ row }) => (
      <ViewEditButtonLink to={`/dashboard/reports/${row.original.report_id}`} />
    ),
  },
  {
    header: "Org Name",
    accessorFn: (row) =>
      row.report?.org_name || row.preEngagement.fields.org_name,
  },
  {
    header: "Disclosure Title",
    accessorFn: (row) =>
      row.report?.disclosure_title || row.preEngagement.fields.disclosure_title,
  },
  {
    header: "Report Title",
    accessorFn: (row) =>
      row.report?.assurance_report_title ||
      row.preEngagement.fields.assurance_report_title,
  },
  {
    id: "date",
    header: "Date",
    cell: ({ row }) =>
      formatFullDate(new Date(row.original.preEngagement.fields.created_at)),
  },
  {
    id: "created",
    header: "Created",
    cell: ({ row }) =>
      formatFullDate(new Date(row.original.preEngagement.fields.created_at)),
  },
  {
    accessorKey: "report_id",
    header: "Report ID",
  },
  {
    id: "status",
    header: "Status",
    cell: ({ row }) => getUserReportStatusLabel(row.original),
  },
];

function ProviderReports() {
  const currentUser = useCurrentUserContext();
  const providerId = currentUser.orgs[0].provider_details?.provider_id;
  if (!providerId) {
    throw new Error("You must be a licensed provider to view this page.");
  }
  const engagementsQuery = trpc.getReportsByProviderId.useQuery(providerId);
  return (
    <>
      <div className="flex gap-x-6">
        <Button asChild>
          <Link to="/dashboard/reports/new">New Report</Link>
        </Button>
        <Button asChild variant="outline">
          <Link to="/dashboard/reports/payable">Pay For Reports</Link>
        </Button>
      </div>
      <div className="my-12">
        <Accordion
          type="single"
          // defaultValue="item-1"
          collapsible
          defaultChecked={false}
          className="w-full"
        >
          <AccordionItem value="item-1">
            <AccordionTrigger className="pl-2">
              View flow-chart of process
            </AccordionTrigger>
            <AccordionContent>
              <img
                src={flowChart}
                className="w-full"
                alt="Flow chart of process"
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
      {engagementsQuery.isLoading ? (
        <div className="mt-12">Loading...</div>
      ) : engagementsQuery.isError || !engagementsQuery.data ? (
        <div className="mt-12">Error loading reports</div>
      ) : (
        <div className="mt-12">
          <DataTable
            columns={reportsColumns}
            data={engagementsQuery.data.filter((a) => a.status !== "Archived")}
          />
        </div>
      )}
    </>
  );
}
