import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { OrgAcceptInvitationExistingUserCommand } from "../../../../../crates/iam/bindings/OrgAcceptInvitationExistingUserCommand";
import { AxiosError } from "axios";
import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";
import { defaultError, wait } from "@/lib/utils";
import { trpc } from "@/lib/trpc";

export default function useOrgAcceptMembershipInvitationExistingUser() {
  const navigate = useNavigate();
  const utils = trpc.useUtils();
  const { axiosInstance: axios } = useAuthTokenContext();
  return useMutation({
    mutationFn: (data: OrgAcceptInvitationExistingUserCommand) => {
      return axios({
        url: `${
          import.meta.env.VITE_IAM_API_URL
        }/org/accept-membership-invitation-existing-user`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data,
      });
    },
    onSuccess: (_, variables) => {
      toast.success(
        "Invitation accepted, you are now a member of this organization."
      );
      wait(2000).then(() => {
        utils.getUserById.refetch(variables.user_id);
        navigate("/dashboard");
      });
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
