import OrgInvoicesTable from "@/components/OrgInvoicesTable";
import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import { getUserMembershipFromUser } from "@/lib/utils";

export default function PaymentsPage() {
  const currentUser = useCurrentUserContext();
  const currentUserMembership = getUserMembershipFromUser(currentUser);
  if (!currentUserMembership) {
    throw new Error("Unauthorized");
  }

  return (
    <DashboardOutlet title="Invoices">
      <OrgInvoicesTable orgId={currentUserMembership.org_id} />
    </DashboardOutlet>
  );
}
