import { useImpersonateContext } from "@/lib/context/ImpersonateContext";

export default function ImpersonationControls() {
  const { impersonatedUser, setImpersonateUserId } = useImpersonateContext();
  return (
    <div className="p-4 bg-accent rounded-lg prose mt-12">
      {impersonatedUser ? (
        <div>
          <p>Currently impersonating: {impersonatedUser?.email}</p>
          <button onClick={() => setImpersonateUserId(null)}>Stop</button>
        </div>
      ) : (
        <div>
          <p>Impersonate user by ID:</p>
          <form
            className="space-x-3"
            onSubmit={(e) => {
              e.preventDefault();
              const elements = new FormData(e.currentTarget);
              const userId = elements.get("userId");
              if (userId) {
                setImpersonateUserId(userId.toString());
              }
            }}
          >
            <input
              name="userId"
              type="text"
              // onChange={(e) => setImpersonateUserId(e.target.value)}
            />
            <button type="submit">Impersonate</button>
          </form>
        </div>
      )}
    </div>
  );
}
