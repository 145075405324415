import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosInstance } from "axios";
import React, { createContext, useContext, useEffect } from "react";
import { setTRPCAccessToken } from "../trpc";
import PageLoader from "@/components/ui/PageLoader";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

const AuthTokenContext = createContext<{
  axiosInstance: AxiosInstance;
} | null>(null);

export const useAuthTokenContext = () => {
  const authToken = useContext(AuthTokenContext);
  if (!authToken) {
    throw new Error("AuthTokenContext: No value provided");
  }
  return authToken;
};

export const AuthTokenContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const auth = useAuth0();

  // first get token upon login
  // then get token on every request
  useEffect(() => {
    if (auth.isLoading) return;
    if (auth.isAuthenticated) {
      auth
        .getAccessTokenSilently({
          authorizationParams: {
            audience: import.meta.env.VITE_AUTH0_AUDIENCE,
          },
        })
        .then((_token) => {
          setTRPCAccessToken(_token);
        })
        .catch(() => {
          toast.error("You need to log in.");
          navigate("/sign-in");
        });
      return;
    } else {
      toast.error("You need to log in.");
      navigate("/sign-in");
    }
  }, [auth]);

  if (!auth.isAuthenticated) {
    return <PageLoader />;
  }

  const axiosInstance = axios.create();
  axiosInstance.interceptors.request.use(async (config) => {
    try {
      const accessToken = await auth.getAccessTokenSilently({
        authorizationParams: {
          audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        },
      });
      setTRPCAccessToken(accessToken);
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    } catch (error) {
      toast.error("You need to log in.");
      navigate("/sign-in");
      return config;
    }
  });

  return (
    <AuthTokenContext.Provider value={{ axiosInstance }}>
      {children}
    </AuthTokenContext.Provider>
  );
};
