import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import { OrgUpdateCompanyNameCommand } from "../../../../../crates/iam/bindings/OrgUpdateCompanyNameCommand";
import { OrgUpdateCompanyProfileCommand } from "../../../../../crates/iam/bindings/OrgUpdateCompanyProfileCommand";
import { trpc } from "@/lib/trpc";
import { defaultError, wait } from "@/lib/utils";
import { AxiosError } from "axios";
import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";
import { useNavigate } from "react-router-dom";

export default function useUpdateCompanyNameAndProfile(
  redirectToApplicationOnSave?: boolean
) {
  const utils = trpc.useUtils();
  const navigate = useNavigate();
  const { axiosInstance: axios } = useAuthTokenContext();
  return useMutation({
    mutationFn: ({
      name,
      profile,
    }: {
      name: OrgUpdateCompanyNameCommand;
      profile: OrgUpdateCompanyProfileCommand;
    }) => {
      const updateName = axios({
        url: `${import.meta.env.VITE_IAM_API_URL}/org/update-company-name`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: name,
      });
      const updateProfile = axios({
        url: `${import.meta.env.VITE_IAM_API_URL}/org/update-company-profile`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: profile,
      });
      return Promise.all([updateName, updateProfile]);
    },
    onSuccess: (_, variables) => {
      toast.success("Profile updated.");
      wait(2000).then(() => {
        utils.getOrgById.refetch(variables.name.org_id);
        if (redirectToApplicationOnSave) {
          navigate(`/dashboard/provider-application`);
        }
      });
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
