import PageWithTitle from "@/components/templates/PageWithTitle";
import PageLoader from "@/components/ui/PageLoader";
import { TypographyH3 } from "@/components/ui/Typography";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import useOrgAcceptMembershipInvitationNewUser from "@/lib/hooks/mutations/useOrgAcceptMembershipInvitationNewUser";
import { memberRequestSchema } from "@/lib/schemas/member-schema";
import { trpc } from "@/lib/trpc";
import { useAuth0 } from "@auth0/auth0-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, redirect, useParams } from "react-router-dom";
import { OrgAcceptInvitationNewUserCommand } from "../../../crates/iam/bindings/OrgAcceptInvitationNewUserCommand";
import { Buffer as BufferPolyfill } from "buffer";

// Existing user is auto-logged in to dashboard
// Or the invitation details tell them to log in
// Otherwise they can accept the invitation here

const defaultValues = {
  first_name: "",
  last_name: "",
  business_unit: "",
  phone: "",
  position_at_company: "",
};

export default function JoinByInvitation() {
  const { invitationToken } = useParams();
  if (!invitationToken) throw new Error("No invitation ID");
  const joinMutation = useOrgAcceptMembershipInvitationNewUser();
  const auth = useAuth0();
  const tokenBuffer = BufferPolyfill.from(invitationToken, "base64");
  const decodedToken = JSON.parse(tokenBuffer.toString());

  const {
    data: isExistingUser,
    isLoading,
    isError,
  } = trpc.checkUserByEmail.useQuery(decodedToken.email);

  const userForm = useForm({
    resolver: zodResolver(memberRequestSchema),
    values: { ...defaultValues, email: decodedToken.email },
  });

  const handleSubmit = userForm.handleSubmit(async (data) => {
    const body: OrgAcceptInvitationNewUserCommand = {
      first_name: data.first_name,
      last_name: data.last_name,
      position_at_company: data.position_at_company,
      business_unit: data.business_unit || null,
      phone: data.phone || null,
      invitation_token: invitationToken,
      signed_up_at: new Date().toISOString(),
    };
    joinMutation.mutate(body);
  });

  useEffect(() => {
    if (auth.isAuthenticated) {
      redirect("/dashboard/invitations");
    }
  }, [auth]);

  return (
    <PageWithTitle title="Invitation">
      {isLoading ? (
        <PageLoader />
      ) : isError ? (
        <p>
          Something went wrong, please refresh the page or contact the person
          who sent you this invitation.
        </p>
      ) : (
        <>
          <div className="mx-auto prose xl:prose-lg prose-slate max-w-2xl">
            <p>
              You've been invited to join an organization to register AA1000AS
              v3 sustainability assurances via the online platform.
            </p>
          </div>
          <div className="max-w-2xl mx-auto">
            <div className="p-4 my-12 bg-accent text-accent-foreground">
              {isExistingUser ? (
                <p>
                  The email address associated with this invitation (
                  {decodedToken.email}) already has an account on this platform.
                  Please{" "}
                  <Link className="underline" to="/sign-in">
                    log in
                  </Link>{" "}
                  and you can accept the invitation from your dashboard. If you
                  think there's been a mistake, please contact the person who
                  sent you this invitation.
                </p>
              ) : (
                <Form {...userForm}>
                  <TypographyH3 as="h2" className="mb-2">
                    Sign up
                  </TypographyH3>
                  <p className="mb-8">
                    Fill in the form below to create your account and accept the
                    invitation.
                  </p>
                  <form className="space-y-6" onSubmit={handleSubmit}>
                    <FormField
                      name="email"
                      control={userForm.control}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Email</FormLabel>
                          <FormControl>
                            <Input readOnly {...field} />
                          </FormControl>
                          <FormDescription>
                            Invited email address cannot be changed. If you
                            think there's been a mistake, please contact the
                            person who sent you this invitation. (But you can
                            change your email address after accepting the
                            invitation.)
                          </FormDescription>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      name="first_name"
                      control={userForm.control}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>First Name</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormDescription></FormDescription>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      name="last_name"
                      control={userForm.control}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Last Name</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormDescription></FormDescription>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      name="position_at_company"
                      control={userForm.control}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Position at company</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormDescription></FormDescription>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      name="business_unit"
                      control={userForm.control}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Business Unit</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormDescription>Optional</FormDescription>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      name="phone"
                      control={userForm.control}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Phone</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormDescription>Optional</FormDescription>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <Button type="submit" disabled={joinMutation.isLoading}>
                      Submit
                    </Button>
                  </form>
                </Form>
              )}
            </div>
          </div>
        </>
      )}
    </PageWithTitle>
  );
}
