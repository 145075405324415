import { trpc } from "@/lib/trpc";
import { defaultError, wait } from "@/lib/utils";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";
import { toast } from "sonner";
import { ReportRejectPostEngagementCommand } from "../../../../../crates/e-licensing/bindings/ReportRejectPostEngagementCommand";

export default function useReportRejectPostEngagement() {
  const utils = trpc.useUtils();
  const { axiosInstance: axios } = useAuthTokenContext();
  return useMutation({
    mutationFn: (data: ReportRejectPostEngagementCommand) => {
      return axios({
        url: `${
          import.meta.env.VITE_ELIC_API_URL
        }/report/reject-post-engagement`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data,
      });
    },
    onSuccess: (_, variables) => {
      toast.success("Rejected.");
      utils.getEngagementById.setData(variables.report_id, (prev) =>
        prev
          ? {
              ...prev,
              status: "PostEngagementRejected",
              postEngagement: prev.postEngagement
                ? {
                    ...prev.postEngagement,
                    rejection: {
                      rejection_reason: variables.reason,
                    },
                  }
                : null,
            }
          : prev
      );
      wait(2000).then(() =>
        utils.getEngagementById.invalidate(variables.report_id)
      );
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
