import * as z from "zod";
import { countryLabels } from "../countries";
import { requiredErrorMap, yesNo, zStringRequired } from "./helpers";
import { industrySectors } from "./industry-sectors";
const orgTypes = [
  "Specialist Assurance Provider",
  "Independent Contractor",
  "Non-profit",
  "Consulting Company",
  "Other",
] as const;
const annualRevenueBands = [
  "Under £5m",
  "£5m - £100m",
  "£101m - £1bn",
  "More than £1bn",
];

const employeeNumberBands = [
  "Under 50",
  "51 - 500",
  "501 - 1000",
  "More than 1000",
];

const industrySectorNames = Object.keys(industrySectors);

export const companyProfileSpec: {
  [key: string]: {
    label: string;
    helperText?: string;
    options?: string[];
  };
} = {
  legal_company_name: {
    label: "Legal Company Name",
    helperText: "This will not be displayed on the weblisting.",
  },
  doing_business_as: {
    label: "Doing Business As (English)",
    helperText:
      "Please enter your company name exactly as you'd like it to appear in the weblisting. All Providers must have a name in English to enable indexing and searching.",
  },
  doing_business_as_intl: {
    label: "Doing Business As (International)",
    helperText:
      "If your company name uses non-English characters, please enter it here. This will be displayed on the weblisting as well.",
  },
  registration_number: {
    label: "Business Registration Number",
    helperText: "If available",
  },
  tax_number: {
    label: "Tax/VAT Number",
    helperText: "If available",
  },
  company_website: {
    label: "Company Website",
    helperText:
      "Please enter the full website address (URL) including http:// or https://",
  },
  business_address: {
    label: "Business Address",
  },
  business_country: {
    label: "Business Country",
    options: countryLabels,
  },
  business_phone_number: {
    label: "Business Phone Number",
    helperText: "Please include country code. For example, +44 203 123 4567",
  },
  active_in: {
    label: "Countries Active In",
    helperText:
      "To be displayed in weblisting. Multiple Countries can be selected.",
    options: ["Global", ...countryLabels],
  },
  supplier_registration_process: {
    label: "Select if your company has a supplier registration process",
    options: yesNo,
  },
  supplier_contact_name: {
    label: "Supplier Registration Contact Name",
  },
  supplier_contact_email: {
    label: "Supplier Registration Contact Email",
  },
  supplier_contact_phone: {
    label: "Supplier Registration Contact Phone Number",
  },
  org_type: {
    label: "Organization Type",
    options: [...orgTypes],
  },
  annual_revenue_gbp: {
    label: "Annual Revenue (GBP)",
    options: annualRevenueBands,
  },
  total_employees: {
    label: "Total Number of Employees",
    options: employeeNumberBands,
  },
  industry_sector: {
    label: "Specialised Industry Sectors",
    helperText:
      "To be displayed in weblisting. Multiple industry sectors can be selected.",
    options: industrySectorNames,
  },
};

const supplierSchema = z.discriminatedUnion(
  "supplier_registration_process",
  [
    z.object({
      supplier_registration_process: z.literal("Yes"),
      supplier_contact_name: zStringRequired,
      supplier_contact_email: z.string().email(),
      supplier_contact_phone: zStringRequired,
    }),
    z.object({
      supplier_registration_process: z.literal("No"),
    }),
  ],
  requiredErrorMap
);

export const companyProfileSchema = z
  .object({
    legal_company_name: zStringRequired,
    doing_business_as: z.string().refine(dbaRegex, {
      message:
        "If your company name uses non-English characters, please enter it in the 'Doing Business As (International)' field.",
    }),
    doing_business_as_intl: z.string().optional(),
    registration_number: z.string().optional(),
    tax_number: z.string().optional(),
    company_website: z.string().url(),
    business_address: zStringRequired,
    business_country: zStringRequired,
    business_phone_number: zStringRequired,
    active_in: z.array(zStringRequired),
    org_type: zStringRequired,
    annual_revenue_gbp: zStringRequired,
    total_employees: zStringRequired,
    industry_sector: z.array(zStringRequired),
  })
  .and(supplierSchema);

export type CompanyProfileType = z.infer<typeof companyProfileSchema>;

function dbaRegex(key: string) {
  if (!key) return false;

  const regex = /^[a-zA-Z0-9!@#$%^&*()_+=\-[\]{}:|,.<>/?' ]*$/;
  return regex.test(key);
}
