import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { Button } from "@/components/ui/button";
import { DataTable } from "@/components/ui/data-table";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import { RouterOutputs, trpc } from "@/lib/trpc";
import { formatFullDate } from "@/lib/utils";
import { ColumnDef, Table as TableType } from "@tanstack/react-table";
import { Link } from "react-router-dom";

type PayableReport = NonNullable<
  RouterOutputs["getPayableReportsByProviderId"]
>[number];

const columns: ColumnDef<PayableReport>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <div className="px-1">
        <input
          type="checkbox"
          {...{
            checked: table.getIsAllRowsSelected(),
            // indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      </div>
    ),
    cell: ({ row }) => (
      <div className="px-1">
        <input
          type="checkbox"
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            // indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
  },
  { header: "ID", accessorKey: "report_id" },
  { header: "Org Name", accessorKey: "report.org_name" },
  { header: "Disclosure Title", accessorKey: "report.disclosure_title" },
  { header: "Report Title", accessorKey: "report.assurance_report_title" },
  {
    header: "Date",
    cell: ({ row }) => formatFullDate(new Date(row.original.report!.date!)),
  },
];

export default function PayableReportsPage() {
  const currentUser = useCurrentUserContext();
  const providerId = currentUser.orgs?.[0]?.provider_details?.provider_id;
  if (!providerId) {
    throw new Error("Unauthorized");
  }
  const { data, isLoading, isError } =
    trpc.getPayableReportsByProviderId.useQuery(providerId);

  return (
    <DashboardOutlet title="Pay For Reports">
      {isLoading ? (
        <div>Loading...</div>
      ) : isError ? (
        <div>Error</div>
      ) : (
        <div>
          <p className="mb-3">You can pay for up to 30 reports at a time.</p>
          <DataTable
            columns={columns}
            data={data.sort((a, b) => a.report_id.localeCompare(b.report_id))}
            showSearch={false}
            showSelectionCount={true}
            renderFooter={(table: TableType<PayableReport>) => (
              <TableFooter table={table} />
            )}
          />
        </div>
      )}
    </DashboardOutlet>
  );
}

function TableFooter({ table }: { table: TableType<PayableReport> }) {
  const numSelected = table.getSelectedRowModel().flatRows.length;
  const disableButton = numSelected === 0 || numSelected > 30;
  return (
    <div className="mt-12">
      {numSelected > 30 && (
        <p className="mb-3 text-destructive">More than 30 reports selected</p>
      )}
      <Button asChild disabled={disableButton}>
        <Link
          to={disableButton ? "" : "/dashboard/reports/pay"}
          state={{
            reportIds: table
              .getSelectedRowModel()
              .flatRows.map((row) => row.original.report_id),
          }}
        >
          Continue to Payment
        </Link>
      </Button>
    </div>
  );
}
