import UploadWidget from "@/components/UploadWidget";
import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { TypographyList } from "@/components/ui/Typography";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import { getCountryLabelFromCode } from "@/lib/countries";
import { uploadSchema } from "@/lib/schemas/helpers";
import { trpc } from "@/lib/trpc";
import { formatFullDate } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

export default function TaxCertificateRequestPage() {
  const navigate = useNavigate();
  const currentUser = useCurrentUserContext();
  const { requestId } = useParams<{ requestId: string }>();
  if (!requestId) throw new Error("No requestId");
  const { data, isLoading, isError } =
    trpc.getTaxCertificateRequestById.useQuery(requestId);

  const resolveRequestMutation = trpc.resolveTaxCertificateRequest.useMutation({
    onSuccess() {
      toast.success("Response submitted.");
      navigate("/dashboard");
    },
    onError() {
      toast.error("Error submitting response.");
    },
  });

  const taxCertificateResponseForm = useForm({
    resolver: zodResolver(
      z.object({
        responseText: z.string().optional(),
        fileUpload: uploadSchema.optional(),
      })
    ),
    defaultValues: { responseText: "", fileUpload: undefined },
  });
  const handleSubmit = taxCertificateResponseForm.handleSubmit(
    async ({ fileUpload, responseText }) => {
      if (!fileUpload && !responseText) return;
      resolveRequestMutation.mutate({
        id: requestId,
        file_details: fileUpload,
        responseText,
      });
    }
  );

  return (
    <DashboardOutlet title="Tax Certificate Request">
      {isLoading ? (
        <div>Loading...</div>
      ) : isError || !data ? (
        <div>Error loading tax certificate request</div>
      ) : (
        <div className="max-w-2xl">
          <TypographyList>
            <li>Created At: {formatFullDate(new Date(data.created_at))}</li>
            <li>
              Org:{" "}
              <Link
                className="underline"
                to={`/dashboard/orgs/${data.org.org_id}`}
              >
                {data.org.legal_company_name}
              </Link>
            </li>
            <li>Country: {getCountryLabelFromCode(data.country as any)}</li>
            <li>
              <p>Request Text:</p>
              <p className="whitespace-pre-wrap">{data.requestText}</p>
            </li>
            {data.responseText ? (
              <li>
                <p>Response Text:</p>
                <p className="whitespace-pre-wrap">{data.responseText}</p>
              </li>
            ) : null}
            {data.fileUpload ? (
              <li>
                <a
                  className="underline"
                  target="_blank"
                  href={data.fileUpload.file_details.cloudinary_url}
                >
                  {data.fileUpload.file_details.original_file_name}
                </a>{" "}
              </li>
            ) : null}
            {data.relatedInvoiceId ? (
              <li>
                <Link
                  className="underline"
                  to={`/dashboard/invoices/${data.relatedInvoiceId}`}
                >
                  Related Invoice
                </Link>
              </li>
            ) : null}
            {data.resolved_at ? (
              <li>Resolved At: {formatFullDate(new Date(data.resolved_at))}</li>
            ) : null}
          </TypographyList>
          {currentUser.super_user_role && !data.resolved_at ? (
            <div className="bg-accent p-4 rounded-lg">
              <Form {...taxCertificateResponseForm}>
                <form onSubmit={handleSubmit} className="space-y-12">
                  <FormField
                    name="responseText"
                    control={taxCertificateResponseForm.control}
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>Response text</FormLabel>
                          <FormControl>
                            <Textarea {...field} />
                          </FormControl>
                          <FormDescription>
                            Optional written response
                          </FormDescription>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                  <FormField
                    name="fileUpload"
                    control={taxCertificateResponseForm.control}
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>File Upload</FormLabel>
                          <FormControl>
                            <UploadWidget
                              onSuccess={field.onChange}
                              tag="tax-certificate-request"
                              value={field.value}
                            />
                          </FormControl>
                          <FormDescription>
                            Optional file upload. Maximum file size: 10mb.
                            Supported formats: pdf, docx, png, jpg, wepb, avif.
                          </FormDescription>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                  <Button
                    type="submit"
                    disabled={
                      !taxCertificateResponseForm.formState.isDirty ||
                      resolveRequestMutation.isLoading
                    }
                  >
                    Submit
                  </Button>
                </form>
              </Form>
            </div>
          ) : null}
        </div>
      )}
    </DashboardOutlet>
  );
}
