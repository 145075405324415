import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import MultiSelect from "@/components/ui/multi-select";
import { Textarea } from "@/components/ui/textarea";
import {
  PreEngagementType,
  preEngagementSchema,
  preEngagementSpec,
} from "@/lib/schemas/pre-engagement-schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRef } from "react";
import { UseFormReturn, useFieldArray, useForm } from "react-hook-form";
import CSAPFinder from "./CSAPFinder";
import FormContextualSaveBar from "./FormContextualSaveBar";
import { TypographyH2, TypographyH4 } from "./ui/Typography";
import { Button } from "./ui/button";
import { Separator } from "./ui/separator";
import UploadWidget from "./UploadWidget";
import { RouterOutputs, trpc } from "@/lib/trpc";
import {
  convertBoolToYesNo,
  convertFormValueToFileUploadAction,
  convertYesNoToBool,
  getReportId,
} from "@/lib/utils";
import { ReportSubmitApplicationCommand } from "../../../crates/e-licensing/bindings/ReportSubmitApplicationCommand";
import useReportSaveApplication from "@/lib/hooks/mutations/useReportSaveApplication";
import useReportSubmitApplication from "@/lib/hooks/mutations/useReportSubmitApplication";
import useReportStartNew from "@/lib/hooks/mutations/useReportStartNew";
import {
  getCountryCodeFromLabel,
  getCountryLabelFromCode,
} from "@/lib/countries";
import { toast } from "sonner";

const blankFormValues = {
  independence: "",
  csaps: [],
  team: [],
  engagement_agreement: undefined,
  assurance_type: "",
  assurance_level: "",
  aa1000ap: "",
  other_used_criteria: "",
  disclosures: "",
  describe_quality: "",
  quality_requirements: "",
  org_name: "",
  country_or_region: "",
  disclosure_title: "",
  disclosure_period_year: "",
  disclosure_publication_year: "",
  industry_sector: "",
  industry_subsector: "",
  assurance_report_title: "",
  combination_details: "",
};

type PreEngagementFormProps = { isEditable: boolean } & (
  | {
      engagement: NonNullable<RouterOutputs["getEngagementById"]>;
      providerId: undefined;
    }
  | { providerId: string; engagement: undefined }
);

export default function PreEngagementForm({
  engagement,
  isEditable,
  providerId,
}: PreEngagementFormProps) {
  const existingData = engagement?.preEngagement.fields
    ? convertPreEngagementDataToFormValues(engagement.preEngagement.fields)
    : {};
  const preEngagementForm = useForm({
    resolver: zodResolver(preEngagementSchema),
    values: { ...blankFormValues, ...existingData },
  });
  const reportIdMutation = trpc.reserveReportId.useMutation();
  const savePreEngagementMutation = useReportSaveApplication();
  const submitPreEngagementMutation = useReportSubmitApplication();
  const startEngagementMutation = useReportStartNew();

  const checksRef = useRef<HTMLDivElement>(null);
  const reportsRef = useRef<HTMLDivElement>(null);

  const engagementAgreementField = preEngagementForm.watch(
    "engagement_agreement"
  );
  const industrySectorField = preEngagementForm.watch("industry_sector");
  const levelField = preEngagementForm.watch("assurance_level");

  async function handleSaveForm() {
    const reportId =
      engagement?.report_id || (await getReportId(reportIdMutation));
    const values = preEngagementForm.getValues();
    const command = convertPreEngagementFormValuesToCommand(
      values,
      engagement?.preEngagement.fields?.created_at
    );
    if (!engagement) {
      await startEngagementMutation.mutateAsync({
        provider_id: providerId,
        application_form: command,
        report_id: reportId,
      });
    }
    savePreEngagementMutation.mutate({
      report_id: reportId,
      application_form: command,
    });
  }

  const handleSubmit = preEngagementForm.handleSubmit(
    async (values) => {
      const reportId =
        engagement?.report_id || (await getReportId(reportIdMutation));
      const command = convertPreEngagementFormValuesToCommand(
        values,
        engagement?.preEngagement.fields?.created_at
      );
      if (!engagement) {
        await startEngagementMutation.mutateAsync({
          provider_id: providerId,
          application_form: command,
          report_id: reportId,
        });
      }
      submitPreEngagementMutation.mutate({
        report_id: reportId,
        application_form: command,
      });
    },
    () => toast.error("Form has errors")
  );

  const disableButtons =
    startEngagementMutation.isLoading ||
    savePreEngagementMutation.isLoading ||
    submitPreEngagementMutation.isSuccess ||
    submitPreEngagementMutation.isLoading;

  const isRejected = engagement?.status === "PreEngagementRejected";

  return (
    <>
      <FormContextualSaveBar
        show={preEngagementForm.formState.isDirty}
        save={
          isRejected ? undefined : { label: "Save", onPress: handleSaveForm }
        }
        discard={{
          onPress: () =>
            preEngagementForm.reset({
              ...blankFormValues,
              ...existingData,
            }),
        }}
        submit={{ label: "Submit", onPress: handleSubmit }}
      />
      <div className="max-w-screen-lg flex flex-col-reverse xl:flex-row gap-12">
        <Form {...preEngagementForm}>
          <form onSubmit={handleSubmit}>
            <div className="space-y-12 scroll-m-14" ref={checksRef}>
              <TypographyH2>Pre-engagement Information</TypographyH2>
              <div className="space-y-12">
                <TypographyH4>INDEPENDENCE AND IMPARTIALITY</TypographyH4>
                <FormFieldWrapper
                  control={preEngagementForm.control}
                  name="independence"
                  render={(field: any) => (
                    <MultiSelect
                      disabled={!isEditable}
                      field={field}
                      options={preEngagementSpec.independence.options!}
                    />
                  )}
                />
              </div>
              <Separator />
              <div className="space-y-12 bg-accent p-4">
                <TypographyH4>COMPETENCE</TypographyH4>
                <CSAPFinder
                  form={preEngagementForm}
                  label={preEngagementSpec.csaps.label}
                  name="csaps"
                />
                <TeamsArrayField
                  form={preEngagementForm}
                  isEditable={isEditable}
                />
              </div>
              <Separator />
              <div className="space-y-12">
                <TypographyH4>AGREEMENT</TypographyH4>
                <FormFieldWrapper
                  control={preEngagementForm.control}
                  name="engagement_agreement"
                  render={(field: any) => (
                    <UploadWidget
                      disabled={!isEditable}
                      onSuccess={field.onChange}
                      value={engagementAgreementField}
                      tag="engagement_agreement"
                    />
                  )}
                />
              </div>
              <Separator />
              <FormFieldWrapper
                control={preEngagementForm.control}
                name="assurance_type"
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={preEngagementSpec.assurance_type.options!}
                  />
                )}
              />
              <FormFieldWrapper
                control={preEngagementForm.control}
                name="assurance_level"
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={preEngagementSpec.assurance_level.options!}
                  />
                )}
              />
              {levelField === "Combination" ? (
                <FormFieldWrapper
                  control={preEngagementForm.control}
                  name="combination_details"
                  render={(field: any) => (
                    <Textarea readOnly={!isEditable} {...field} />
                  )}
                />
              ) : null}
              <Separator />
              <div className="space-y-12">
                <TypographyH4>SUBJECT MATTER</TypographyH4>
                <FormFieldWrapper
                  control={preEngagementForm.control}
                  name="aa1000ap"
                  render={(field: any) => (
                    <MultiSelect
                      disabled={!isEditable}
                      field={field}
                      options={preEngagementSpec.aa1000ap.options!}
                    />
                  )}
                />
                <FormFieldWrapper
                  control={preEngagementForm.control}
                  name="other_used_criteria"
                  render={(field: any) => (
                    <Textarea readOnly={!isEditable} {...field} />
                  )}
                />
                <FormFieldWrapper
                  control={preEngagementForm.control}
                  name="disclosures"
                  render={(field: any) => (
                    <Textarea readOnly={!isEditable} {...field} />
                  )}
                />
              </div>
              <Separator />
              <div className="space-y-12">
                <TypographyH4>QUALITY CONTROL SYSTEMS</TypographyH4>
                <FormFieldWrapper
                  control={preEngagementForm.control}
                  name="describe_quality"
                  render={(field: any) => (
                    <Textarea readOnly={!isEditable} {...field} />
                  )}
                />
                <FormFieldWrapper
                  control={preEngagementForm.control}
                  name="quality_requirements"
                  render={(field: any) => (
                    <MultiSelect
                      disabled={!isEditable}
                      field={field}
                      options={preEngagementSpec.quality_requirements.options!}
                    />
                  )}
                />
              </div>
            </div>
            <Separator className="mt-12" />
            <div ref={reportsRef} className="space-y-12 mt-20 scroll-m-14">
              <TypographyH2>Report Information</TypographyH2>
              <p>
                Please note: you will be able to edit this information when
                filling out the main assurance form.
              </p>
              <FormFieldWrapper
                name="org_name"
                control={preEngagementForm.control}
                render={(field: any) => (
                  <Input readOnly={!isEditable} {...field} />
                )}
              />
              <FormFieldWrapper
                name="country_or_region"
                control={preEngagementForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={preEngagementSpec.country_or_region.options!}
                  />
                )}
              />
              <FormFieldWrapper
                name="industry_sector"
                control={preEngagementForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={preEngagementSpec.industry_sector.options!}
                  />
                )}
              />
              {/* Subsector is disabled until a sector selection is made, which also updates the set of options */}
              <FormFieldWrapper
                name="industry_subsector"
                control={preEngagementForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable || !industrySectorField}
                    field={field}
                    options={preEngagementSpec.industry_subsector.getOptions!(
                      industrySectorField
                    )}
                  />
                )}
              />
              <FormFieldWrapper
                name="disclosure_title"
                control={preEngagementForm.control}
                render={(field: any) => (
                  <Input readOnly={!isEditable} {...field} />
                )}
              />
              <FormFieldWrapper
                name="disclosure_period_year"
                control={preEngagementForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={preEngagementSpec.disclosure_period_year.options!}
                  />
                )}
              />
              <FormFieldWrapper
                name="disclosure_publication_year"
                control={preEngagementForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={
                      preEngagementSpec.disclosure_publication_year.options!
                    }
                  />
                )}
              />
              <FormFieldWrapper
                name="assurance_report_title"
                control={preEngagementForm.control}
                render={(field: any) => (
                  <Input readOnly={!isEditable} {...field} />
                )}
              />
            </div>
            {isEditable && (
              <div className="flex gap-4 mt-12">
                {!isRejected && (
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={handleSaveForm}
                    disabled={disableButtons}
                  >
                    Save
                  </Button>
                )}
                <Button type="submit" disabled={disableButtons}>
                  Submit
                </Button>
              </div>
            )}
          </form>
        </Form>
        <div className="shrink-0 relative w-64">
          <div className="sticky left-0 top-14 flex flex-col gap-y-6">
            <h5 className="text-lg">Jump To Section</h5>
            <ul className="space-y-2 list-disc">
              <li
                className="cursor-pointer"
                onClick={() =>
                  checksRef.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Pre-Engagement Info
              </li>
              <li
                className="cursor-pointer"
                onClick={() =>
                  reportsRef.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Report Info
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

function TeamsArrayField({
  form: { control },
  isEditable,
}: {
  form: UseFormReturn<any, any, any>;
  isEditable: boolean;
}) {
  const { append, remove, fields } = useFieldArray({
    control: control,
    name: "team",
  });

  return (
    <div>
      <p className="text-sm font-medium block leading-relaxed mb-6">
        {preEngagementSpec.team.label}
      </p>
      <div className="space-y-6">
        {fields.map((field, index) => (
          <div
            className="flex flex-col gap-4 rounded-lg border p-4"
            key={field.id}
          >
            <FormField
              name={`team.${index}.name`}
              control={control}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input readOnly={!isEditable} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              name={`team.${index}.sustainability_years`}
              control={control}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Years of experience in Sustainability</FormLabel>
                    <FormControl>
                      <Input
                        readOnly={!isEditable}
                        type="number"
                        {...field}
                        min={0}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              name={`team.${index}.sustainability_competencies`}
              control={control}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>
                      Competencies related to sustainability
                    </FormLabel>
                    <FormControl>
                      <Textarea readOnly={!isEditable} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              name={`team.${index}.assurance_years`}
              control={control}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>
                      Years of experience in Assurance / Audit
                    </FormLabel>
                    <FormControl>
                      <Input
                        readOnly={!isEditable}
                        type="number"
                        {...field}
                        min={0}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              name={`team.${index}.assurance_methodologies`}
              control={control}
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>
                      List audit / assurance methodologies that team member is
                      experienced in
                    </FormLabel>
                    <FormControl>
                      <Textarea readOnly={!isEditable} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <Button variant="secondary" onClick={() => remove(index)}>
              Remove
            </Button>
          </div>
        ))}
        <Button
          onClick={() =>
            append({ name: "", sustainability_years: "", assurance_years: "" })
          }
          type="button"
          disabled={!isEditable}
        >
          Add Team Member
        </Button>
      </div>
    </div>
  );
}

function FormFieldWrapper({
  control,
  name,
  render,
}: {
  control: any;
  name: Extract<keyof typeof preEngagementSpec, string>;
  render: any;
}) {
  return (
    <FormField
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormItem>
            <FormLabel
              dangerouslySetInnerHTML={{
                __html: preEngagementSpec[name].label,
              }}
            />
            <FormControl>{render(field)}</FormControl>
            {preEngagementSpec[name].helperText ? (
              <FormDescription
                dangerouslySetInnerHTML={{
                  __html: preEngagementSpec[name].helperText!,
                }}
                className="whitespace-pre-wrap"
              />
            ) : null}
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
}

function convertPreEngagementDataToFormValues(
  fields: NonNullable<
    RouterOutputs["getEngagementById"]
  >["preEngagement"]["fields"]
): Partial<PreEngagementType> {
  return {
    independence: convertBoolToYesNo(fields.independence),
    csaps: fields.csaps || [],
    team: fields.team || [],
    engagement_agreement:
      fields.engagement_agreement?.file_details || undefined,
    assurance_type: fields.assurance_type || "",
    assurance_level: fields.assurance_level || "",
    aa1000ap: convertBoolToYesNo(fields.aa1000ap),
    other_used_criteria: fields.other_used_criteria || "",
    disclosures: fields.disclosures || "",
    describe_quality: fields.describe_quality || "",
    quality_requirements: convertBoolToYesNo(fields.quality_requirements),
    org_name: fields.org_name || "",
    country_or_region: fields.country_or_region
      ? getCountryLabelFromCode(fields.country_or_region)
      : "",
    disclosure_title: fields.disclosure_title || "",
    disclosure_period_year: fields.disclosure_period_year || "",
    disclosure_publication_year: fields.disclosure_publication_year || "",
    industry_sector: fields.industry_sector || "",
    industry_subsector: fields.industry_subsector || "",
    assurance_report_title: fields.assurance_report_title || "",
    combination_details: fields.combination_details || "",
  };
}

function convertPreEngagementFormValuesToCommand(
  values: Partial<PreEngagementType>,
  created_at?: string
): ReportSubmitApplicationCommand["application_form"] {
  return {
    created_at: created_at || new Date().toISOString(),
    engagement_agreement: convertFormValueToFileUploadAction(
      values.engagement_agreement
    ),
    team: values.team || [],
    assurance_type:
      values.assurance_type === "Type 1"
        ? "Type1"
        : values.assurance_type === "Type 2"
        ? "Type2"
        : null,
    aa1000ap: convertYesNoToBool(values.aa1000ap),
    other_used_criteria: values.other_used_criteria || null,
    disclosures: values.disclosures || null,
    describe_quality: values.describe_quality || null,
    quality_requirements: convertYesNoToBool(values.quality_requirements),
    org_name: values.org_name || null,
    country_or_region: values.country_or_region
      ? getCountryCodeFromLabel(values.country_or_region)
      : null,
    disclosure_title: values.disclosure_title || null,
    disclosure_period_year: values.disclosure_period_year || null,
    disclosure_publication_year: values.disclosure_publication_year || null,
    assurance_report_title: values.assurance_report_title || null,
    industry_sector_and_subsector: values.industry_sector
      ? {
          sector: values.industry_sector,
          subsector: values.industry_subsector || null,
        }
      : null,
    assurance_level:
      values.assurance_level === "High" || values.assurance_level === "Moderate"
        ? values.assurance_level
        : values.assurance_level === "Combination"
        ? {
            Combination: {
              combination_details: values.combination_details || "",
            },
          }
        : null,
    independence: convertYesNoToBool(values.independence),
    csaps: values.csaps || [],
  };
}
