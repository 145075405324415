import { cn } from "@/lib/utils";
import { useQuery } from "@tanstack/react-query";
import { CheckIcon, ChevronsUpDownIcon } from "lucide-react";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { Button } from "./ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./ui/command";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";

type MongoCSAP = {
  data_id: string;
  first_name: string;
  last_name: string;
  email: string;
  level: "ACSAP" | "PCSAP" | "LCSAP" | "SP";
  country: string;
};

export default function CSAPFinder({
  form: { control, getValues },
  label,
  name,
  withSPs,
}: {
  form: UseFormReturn<any, any, any>;
  label: string;
  name: string;
  withSPs?: boolean;
}) {
  const { append, remove, fields } = useFieldArray({
    control: control,
    name,
  });

  const csapQuery = useQuery<{ data: { attributes: MongoCSAP }[] }>({
    queryKey: ["csaps"],
    queryFn: csapQueryFunction,
  });
  const _data = csapQuery.data?.data?.map((a) => ({
    ...a.attributes,
    registration_number: a.attributes.data_id,
    value: `${a.attributes.first_name} ||| ${a.attributes.last_name} ||| ${a.attributes.email}`,
  }));
  const data = withSPs ? _data : _data?.filter((a) => a.level !== "SP");

  return (
    <FormField
      control={control}
      name={name}
      render={() => (
        <FormItem className="flex flex-col">
          <FormLabel>{label}</FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "w-full justify-between [min-height:2.5rem] h-auto",
                    !fields.length && "text-muted-foreground"
                  )}
                >
                  {
                    // Wait till the data arrives
                    !data
                      ? null
                      : fields.length
                        ? fields
                            .map((field) => {
                              const entry = data?.find(
                                (b) =>
                                  b.registration_number ===
                                  field.registration_number
                              );
                              return `${entry?.first_name} ${entry?.last_name}`.trim();
                            })
                            .join(", ")
                        : "Search"
                  }
                  <ChevronsUpDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-full p-0">
              <Command>
                <CommandInput placeholder="Search CSAPs..." />
                <CommandEmpty>No results.</CommandEmpty>
                <CommandGroup className="max-h-80 overflow-y-auto w-96">
                  {data?.map((csap) => (
                    <CommandItem
                      value={csap.value}
                      key={csap.value}
                      onSelect={() => {
                        const currentValues = getValues(name);
                        const existingIndex = currentValues.findIndex(
                          (a: { registration_number: string }) =>
                            a.registration_number === csap.registration_number
                        );
                        if (existingIndex > -1) {
                          remove(existingIndex);
                        } else {
                          append({
                            registration_number: csap.registration_number,
                          });
                        }
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 h-4 w-4 shrink-0 self-center",
                          fields.find(
                            (a) =>
                              a.registration_number === csap.registration_number
                          )
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      <CSAPFieldLabel {...csap} />
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormDescription>Search by name or email</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

const csapLevelNames = {
  ACSAP: "Associate CSAP",
  PCSAP: "Practising CSAP",
  LCSAP: "Lead CSAP",
  SP: "Sustainability Practitioner",
};

function CSAPFieldLabel({
  first_name,
  last_name,
  email,
  level,
  country,
}: MongoCSAP) {
  return (
    <div className="space-y-1">
      <p className="text-sm">
        {`${first_name} ${last_name}`} ({email})
      </p>
      <p className="text-xs text-muted-foreground">{csapLevelNames[level]}</p>
      <p className="text-xs text-muted-foreground">{country}</p>
    </div>
  );
}

function csapQueryFunction() {
  return fetch(
    `${import.meta.env.VITE_STRAPI_URL}/api/csaps?pagination[pageSize]=2000`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${import.meta.env.VITE_STRAPI_TOKEN}`,
      },
    }
  ).then((res) => res.json());
}
