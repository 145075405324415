import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import { ChangeMemberRoleCommand } from "../../../../../crates/iam/bindings/ChangeMemberRoleCommand";
import { AxiosError } from "axios";
import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";
import { trpc } from "@/lib/trpc";
import { defaultError, wait } from "@/lib/utils";

export default function useOrgChangeMemberRole() {
  const utils = trpc.useUtils();
  const { axiosInstance: axios } = useAuthTokenContext();
  return useMutation({
    mutationFn: (data: ChangeMemberRoleCommand) => {
      return axios({
        url: `${import.meta.env.VITE_IAM_API_URL}/org/change-member-role`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data,
      });
    },
    onSuccess: (_, vars) => {
      toast.success("Role updated.");
      wait(1500).then(() => utils.getUserById.refetch(vars.membership_id));
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
