import { trpc } from "@/lib/trpc";
import { defaultError, wait } from "@/lib/utils";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";
import { toast } from "sonner";
import { ProviderLicenseReinstateCommand } from "../../../../../crates/e-licensing/bindings/ProviderLicenseReinstateCommand";

export default function useProviderLicenseReinstate(orgId: string) {
  const utils = trpc.useUtils();
  const { axiosInstance: axios } = useAuthTokenContext();
  return useMutation({
    mutationFn: (data: ProviderLicenseReinstateCommand) => {
      return axios(
        `${import.meta.env.VITE_ELIC_API_URL}/provider/license-reinstate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        }
      );
    },
    onSuccess: () => {
      toast.success("Provider reinstated.");
      utils.getOrgById.setData(orgId, (oldData) => {
        return oldData && oldData.provider_details
          ? {
              ...oldData,
              provider_details: {
                ...oldData.provider_details,
                suspension: null,
              },
            }
          : oldData;
      });
      wait(2000).then(() => utils.getOrgById.refetch(orgId));
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
