import { useEffect, useState } from "react";

export default function useCopyText() {
  const [copied, setCopied] = useState(false);
  function copyText(textToCopy: string) {
    setCopied(true);
    navigator.clipboard.writeText(textToCopy);
  }
  useEffect(() => {
    if (!copied) return;
    const tmo = setTimeout(() => setCopied(false), 3000);
    return () => clearTimeout(tmo);
  }, [copied]);
  return { copyText, copied };
}
