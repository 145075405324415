import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import UserActions from "@/components/UserActions";
import UserProfileForm from "@/components/UserProfileForm";
import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { RouterOutputs, trpc } from "@/lib/trpc";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import useOrgChangeMemberDetails from "@/lib/hooks/mutations/useOrgChangeMemberDetails";
import { getUserMembershipFromUser } from "@/lib/utils";
import FormContextualSaveBar from "@/components/FormContextualSaveBar";

export default function UserPage() {
  const { userId } = useParams<{ userId: string }>();
  if (!userId) throw new Error("No such user");
  const currentUser = useCurrentUserContext();
  const userQuery = trpc.getUserById.useQuery(userId);
  const userIsInOrg = !!userQuery.data?.orgs.length;

  return (
    <DashboardOutlet title="User Information">
      {userQuery.isLoading ? (
        <p>Loading...</p>
      ) : userQuery.isError || !userQuery.data ? (
        <p>Error loading user</p>
      ) : (
        <div className="max-w-2xl space-y-12">
          <Tabs defaultValue="user-profile">
            <TabsList className="mb-12">
              <TabsTrigger value="user-profile">User Profile</TabsTrigger>
              {userIsInOrg && (
                <TabsTrigger value="org-profile">Org Profile</TabsTrigger>
              )}
              <TabsTrigger value="actions">Actions</TabsTrigger>
            </TabsList>
            <TabsContent value="user-profile">
              <UserProfileForm data={userQuery.data} />
            </TabsContent>
            {userIsInOrg && (
              <TabsContent value="org-profile">
                <OrgProfileForm
                  isEditable={currentUser.id === userId}
                  data={userQuery.data}
                />
              </TabsContent>
            )}
            <TabsContent value="actions">
              <UserActions data={userQuery.data} />
            </TabsContent>
          </Tabs>
        </div>
      )}
    </DashboardOutlet>
  );
}

function OrgProfileForm({
  data,
  isEditable,
}: {
  data: NonNullable<RouterOutputs["getUserById"]>;
  isEditable: boolean;
}) {
  const changeDetailsMutation = useOrgChangeMemberDetails();
  const orgMemberDetails = getUserMembershipFromUser(data);
  if (!orgMemberDetails) throw new Error("User is not a member of any org");
  const changeDetailsForm = useForm({
    resolver: zodResolver(
      z.object({
        new_business_unit: z.string().optional(),
        new_position_at_company: z.string(),
      })
    ),
    values: {
      new_business_unit: orgMemberDetails.business_unit || "",
      new_position_at_company: orgMemberDetails.position_at_company || "",
    },
  });
  const handleSubmit = changeDetailsForm.handleSubmit((values) => {
    changeDetailsMutation.mutate({
      ...values,
      org_id: orgMemberDetails.org_id,
      user_id: orgMemberDetails.user_id,
      membership_id: orgMemberDetails.id,
    });
  });

  return (
    <>
      <FormContextualSaveBar
        show={changeDetailsForm.formState.isDirty}
        submit={{ label: "Save", onPress: handleSubmit }}
        discard={{
          onPress: () =>
            changeDetailsForm.reset({
              new_business_unit: orgMemberDetails.business_unit || "",
              new_position_at_company:
                orgMemberDetails.position_at_company || "",
            }),
        }}
      />
      <Form {...changeDetailsForm}>
        <form onSubmit={handleSubmit} className="space-y-12">
          <FormField
            name="new_position_at_company"
            control={changeDetailsForm.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Position at Company</FormLabel>
                <FormControl>
                  <Input readOnly={!isEditable} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="new_business_unit"
            control={changeDetailsForm.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Business Unit</FormLabel>
                <FormControl>
                  <Input readOnly={!isEditable} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {isEditable && (
            <Button type="submit" disabled={changeDetailsMutation.isLoading}>
              Update
            </Button>
          )}
        </form>
      </Form>
    </>
  );
}
