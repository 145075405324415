import PageWithTitle from "@/components/templates/PageWithTitle";
import PageLoader from "@/components/ui/PageLoader";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { demoteHeadings } from "@/lib/utils";
import { useQuery } from "@tanstack/react-query";

export default function FAQs() {
  const { isLoading, isError, data } = useQuery({
    queryKey: ["faqs"],
    queryFn: faqsQueryFunction,
  });

  return (
    <PageWithTitle title="FAQs">
      {isLoading ? (
        <PageLoader />
      ) : isError ? (
        <p>Error loading page. Please refresh.</p>
      ) : (
        <div className="mx-auto prose xl:prose-lg prose-slate">
          {!!data?.data?.attributes?.download?.data?.attributes?.url && (
            <div className="py-6 space-y-3">
              <Button asChild>
                <a
                  href={data.data.attributes.download.data.attributes.url}
                  target="_blank"
                >
                  Download
                </a>
              </Button>
              <Separator />
            </div>
          )}
          <div
            dangerouslySetInnerHTML={{
              __html: demoteHeadings(data.data.attributes.content),
            }}
          />
        </div>
      )}
    </PageWithTitle>
  );
}

function faqsQueryFunction() {
  return fetch(
    `${
      import.meta.env.VITE_STRAPI_URL
    }/api/faqs-e-licensing?populate[download]=*`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${import.meta.env.VITE_STRAPI_TOKEN}`,
      },
    }
  ).then((res) => res.json());
}
