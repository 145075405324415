import ProviderAssets from "@/components/ProviderAssets";
import DashboardOutlet from "@/components/templates/DashboardOutlet";

export default function ProviderAssetsPage() {
  return (
    <DashboardOutlet title="Provider Assets">
      <ProviderAssets />
    </DashboardOutlet>
  );
}
