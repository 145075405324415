import { Link } from "react-router-dom";
import ProviderAssets from "@/components/ProviderAssets";
import { getUserStatus } from "@/components/layout/getUserStatus";
import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import { trpc } from "@/lib/trpc";
import { TypographyH4, TypographyList } from "@/components/ui/Typography";

export default function DashboardHome() {
  const currentUser = useCurrentUserContext();
  const userStatus = getUserStatus(currentUser);
  const { data: stats } = trpc.getSuperUserStats.useQuery(undefined, {
    enabled: !!currentUser.super_user_role,
  });
  return (
    <DashboardOutlet title="Dashboard">
      <>
        <div className="prose prose-slate">
          {userStatus === "isSU" && (
            <div>
              <TypographyH4>You are a Super User.</TypographyH4>
              <TypographyList>
                <li>Total providers: {stats?.providersCount}</li>
                <li>Published reports: {stats?.publishedReportsCount}</li>
                <li>Total organizations: {stats?.orgsCount}</li>
                <li>Total users: {stats?.usersCount}</li>
              </TypographyList>
            </div>
          )}
          {userStatus === "isNew" && (
            <p>
              You must be a member of an organization to use this platform. You
              can either{" "}
              <Link to="/dashboard/create-org">create an organization</Link> or
              check the list of existing organizations for your own company and{" "}
              <Link to="/dashboard/join-org-request">request to join</Link>. You
              can also check the{" "}
              <Link to="/dashboard/invitations">invitations page</Link> to see
              if you've been invited to join an organization.
            </p>
          )}
          {userStatus === "isOrgMember" && (
            <div>
              <p>
                Your organization is{" "}
                <span className="font-semibold">
                  {currentUser.orgs[0].legal_company_name}
                </span>
              </p>
              <p>
                To become an AccountAbility Licensed Assurance Provider, you
                must now fill in your{" "}
                <Link to="/dashboard/company-profile">company profile</Link> and{" "}
                <Link to="/dashboard/provider-application">
                  submit an application
                </Link>
                .
              </p>
            </div>
          )}
        </div>
        {userStatus === "isProvider" && <ProviderAssets />}
      </>
    </DashboardOutlet>
  );
}
