import PageLoader from "@/components/ui/PageLoader";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { demoteHeadings } from "@/lib/utils";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Homepage() {
  const auth = useAuth0();
  const navigate = useNavigate();
  const { isLoading, isError, data } = useQuery({
    queryKey: ["homepage"],
    queryFn: homepageQueryFunction,
  });
  useEffect(() => {
    if (auth.isAuthenticated) navigate("/dashboard");
  }, [auth]);

  return (
    <div className="px-4 pt-24 mx-auto max-w-4xl">
      <h1 className="font-semibold text-3xl md:text-4xl xl:text-5xl mb-8 lg:mb-16 flex flex-col gap-4 !leading-relaxed text-center">
        E-Licensing for the AccountAbility Assurance Standard (AA1000AS v3)
      </h1>
      <div className="flex flex-col md:flex-row justify-center gap-6 my-12 lg:my-24 items-center">
        <div className="flex gap-6">
          <Link to="/help-and-info">Help & Info</Link>
          <Link to="/fee-structure">Fee Structure</Link>
        </div>
        <div className="flex gap-6">
          <Button asChild variant="default">
            <Link to="/sign-up">Sign Up</Link>
          </Button>
          <Button asChild variant="secondary">
            <Link to="/sign-in">Sign In</Link>
          </Button>
        </div>
      </div>
      <Separator />
      {isLoading ? (
        <PageLoader />
      ) : isError ? null : (
        <div className="prose prose-lg prose-slate mx-auto mt-24">
          <div
            dangerouslySetInnerHTML={{
              __html: demoteHeadings(data.data.attributes.content),
            }}
          />
        </div>
      )}
    </div>
  );
}

function homepageQueryFunction() {
  return fetch(`${import.meta.env.VITE_STRAPI_URL}/api/homepage-e-licensing`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${import.meta.env.VITE_STRAPI_TOKEN}`,
    },
  }).then((res) => res.json());
}
