import { createTRPCReact, httpBatchLink } from "@trpc/react-query";

import type { AppRouter } from "../../../trpc/api/trpc";
import { inferRouterOutputs } from "@trpc/server";

export const trpc = createTRPCReact<AppRouter>();

export type RouterOutputs = inferRouterOutputs<AppRouter>;

let accessToken: string | null = null;
export function setTRPCAccessToken(token: string | null) {
  accessToken = token;
}

export const trpcClient = trpc.createClient({
  links: [
    httpBatchLink({
      url: import.meta.env.VITE_TRPC_URL,
      headers() {
        return accessToken
          ? {
              Authorization: `Bearer ${accessToken}`,
            }
          : {};
      },
    }),
  ],
});
