import { trpc } from "@/lib/trpc";
import { defaultError, wait } from "@/lib/utils";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";
import { toast } from "sonner";
import { UpdateBillingAccountCommand } from "../../../../../crates/payments/bindings/UpdateBillingAccountCommand";
import { useNavigate } from "react-router-dom";

export default function useBillingAccountUpdate(org_id: string) {
  const utils = trpc.useUtils();
  const navigate = useNavigate();
  const { axiosInstance: axios } = useAuthTokenContext();
  return useMutation({
    mutationFn: (data: UpdateBillingAccountCommand) => {
      return axios({
        url: `${import.meta.env.VITE_PAYMENTS_API_URL}/billing-account/update`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data,
      });
    },
    onSuccess: () => {
      toast.success("Billing account updated.");
      wait(2000).then(() => {
        utils.getOrgById.refetch(org_id);
        navigate("/dashboard/billing-accounts");
      });
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
