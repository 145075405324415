import * as z from "zod";
import {
  requiredErrorMap,
  uploadSchema,
  zStringRequired,
  zYesNoRequired,
} from "./helpers";
import { industrySectors } from "./industry-sectors";
import { countryLabels } from "../countries";

export const reportFormSpec: {
  [key: string]: {
    label: string;
    helperText?: string;
    options?: string[];
    getOptions?: (input?: any) => string[];
  };
} = {
  org_name: {
    label: "Name of reporting organization",
  },
  country_or_region: {
    label: "Country",
    options: countryLabels,
  },
  industry_sector: {
    label: "Industry Sector",
    options: Object.keys(industrySectors),
  },
  industry_subsector: {
    label: "Industry Subsector",
    getOptions: (sector: keyof typeof industrySectors) =>
      sector ? industrySectors[sector] : [],
  },
  disclosure_title: {
    label: "Disclosure title",
  },
  disclosure_period_year: {
    label: "Year of disclosure period that is assured",
    options: ["2018", ...getYears()],
  },
  disclosure_publication_year: {
    label: "Disclosure publication year",
    options: ["2018", ...getYears()],
  },
  assurance_report_title: {
    label: "Title of the assurance report",
  },
  report_url: {
    label: "Link to PDF / weblink of assured report",
    helperText:
      "This can be filled in after submission if it's not available right now",
  },
  aa_csap_involved: {
    label: "Did AccountAbility CSAPs carry out the engagement?",
    options: ["Yes", "No"],
  },
  csaps: {
    label: "Select the AccountAbility CSAP(s) involved in the engagement",
  },
  intended_users: {
    label: "Intended users of the Assurance Statement",
  },
  reporting_org_responsibilities: {
    label: "Reporting organization responsibilities",
  },
  assurance_provider_responsibilities: {
    label: "Assurance provider responsibilities",
  },
  refers_aa1000v3: {
    label: "Does the report refer to the AA1000AS v3 assurance standard?",
    helperText: "AA1000AS v3 must be referenced in the report",
    options: ["Yes", "No"],
  },
  refers_isae3000: {
    label: "Does the report refer to the ISAE3000 assurance standard?",
    options: ["Yes", "No"],
  },
  refers_other_standards: {
    label:
      "Does the report use any other assurance standards? If so please list them here",
  },
  description_scope: {
    label: "Please provide a description of the report's scope",
  },
  description_subject_matter: {
    label: "Please provide a description of the report's subject matter",
  },
  assurance_type: {
    label: "What type of assurance was provided?",
    options: ["Type 1", "Type 2"],
  },
  assurance_level: {
    label: "What level of assurance was provided?",
    options: ["Moderate", "High", "Combination"],
  },
  used_criteria: {
    label: "Reference to criteria used",
    helperText:
      "Please indicate if any of the following criteria are referenced in the report. Multiple criteria can be selected.",
    options: [
      "AA1000SES",
      "ESRS",
      "GRI",
      "IIRC",
      "ISSB",
      "SASB",
      "TCFD",
      "UNSDG",
    ],
  },
  used_criteria_other: {
    label: "Please enter any other criteria used here",
  },
  disclosures_covered_description_sources: {
    label: "Description and sources of disclosures covered",
  },
  methodology_description: {
    label: "Description of methodology",
  },
  limitations_mitigation_approach: {
    label: "Limitations and approach used to mitigate limitations",
  },
  assurance_provider_independence_competencies: {
    label:
      "Notes on the independence and competencies of the assurance provider",
  },
  assurance_provider_name_mentioned: {
    label: "Is the name of the assurance provider mentioned on the statement?",
    options: ["Yes", "No"],
  },
  place: {
    label: "Place assurance statement has been issued",
    options: countryLabels,
  },
  date: {
    label: "Date Assurance Statement was issued",
  },
  aa1000ap_inclusivity: {
    label: "Inclusivity",
  },
  aa1000ap_materiality: {
    label: "Materiality",
  },
  aa1000ap_responsiveness: {
    label: "Responsiveness",
  },
  aa1000ap_impact: {
    label: "Impact",
  },
  performance_information_reliability_quality: {
    label:
      "Findings and conclusions concerning the reliability and quality of specified performance information",
  },
  address_deficiencies: {
    label:
      "Have recommendations to address deficiencies been included? If so, please list them here",
  },
  separate_management_report: {
    label:
      "Is there a separate Report to Management issued as part of the assurance engagement?",
    options: ["Yes", "No"],
  },
  assurance_statement: {
    label:
      "Please upload your assurance statement document, if available. Maximum file size: 10mb. Supported formats: pdf, docx, png, jpg, wepb, avif.",
  },
  self_confirmation: {
    label:
      "I confirm all information above has been extracted from the content of the final assurance statement to be issued to the client organization.",
    options: ["Yes", "No"],
  },
};

function getYears() {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 2019; i <= currentYear + 3; i++) {
    years.push(i);
  }
  return years.map((year) => year.toString());
}

const sectorsUnion = Object.entries(industrySectors).map(
  ([sector, subsectors]) => {
    const arr = [subsectors[0], ...subsectors.slice(1)] as [
      string,
      ...string[],
    ];
    return z.object({
      industry_sector: z.literal(sector),
      industry_subsector: z.enum(arr),
    });
  }
);

const industrySubsectorSchema = z.discriminatedUnion(
  "industry_sector",
  [sectorsUnion[0], ...sectorsUnion.slice(1)],
  requiredErrorMap
);

const typeTwoSchema = z.discriminatedUnion(
  "assurance_type",
  [
    z.object({
      assurance_type: z.literal("Type 1"),
    }),
    z.object({
      assurance_type: z.literal("Type 2"),
      performance_information_reliability_quality: zStringRequired,
    }),
  ],
  requiredErrorMap
);

export const reportFormSchema = z
  .object({
    org_name: zStringRequired,
    country_or_region: zStringRequired,
    disclosure_title: zStringRequired,
    disclosure_period_year: z.enum(["2018", ...getYears()], requiredErrorMap),
    disclosure_publication_year: z.enum(
      ["2018", ...getYears()],
      requiredErrorMap
    ),
    assurance_report_title: zStringRequired,
    report_url: z.string().url().or(z.literal("")),
    aa_csap_involved: zYesNoRequired,
    csaps: z.array(z.object({ registration_number: z.string() })),
    intended_users: zStringRequired,
    reporting_org_responsibilities: zStringRequired,
    assurance_provider_responsibilities: zStringRequired,
    refers_aa1000v3: z.literal("Yes", {
      errorMap: () => ({
        message: "You must select Yes to be able to submit the report",
      }),
    }),
    refers_isae3000: zYesNoRequired,
    refers_other_standards: z.string().optional(),
    description_scope: zStringRequired,
    description_subject_matter: zStringRequired,
    // assurance_type: z.enum(["Type 1", "Type 2"]),
    assurance_level: z.enum(
      ["Moderate", "High", "Combination"],
      requiredErrorMap
    ),
    used_criteria: z.array(z.string()).optional(),
    used_criteria_other: z.string().optional(),
    disclosures_covered_description_sources: zStringRequired,
    methodology_description: zStringRequired,
    limitations_mitigation_approach: zStringRequired,
    assurance_provider_independence_competencies: zStringRequired,
    assurance_provider_name_mentioned: z.literal("Yes", {
      errorMap: () => ({
        message: "You must select Yes to be able to submit the report",
      }),
    }),
    place: zStringRequired,
    date: z.coerce.date(),
    aa1000ap_inclusivity: zStringRequired,
    aa1000ap_materiality: zStringRequired,
    aa1000ap_responsiveness: zStringRequired,
    aa1000ap_impact: zStringRequired,
    address_deficiencies: z.string().optional(),
    separate_management_report: zYesNoRequired,
    assurance_statement: uploadSchema.optional(),
    self_confirmation: z.literal("Yes", {
      errorMap: () => ({
        message: "You must give confirmation to submit the report",
      }),
    }),
  })
  .and(industrySubsectorSchema)
  .and(typeTwoSchema);

export type ReportFormType = z.infer<typeof reportFormSchema>;
