import CompanyProfileForm from "@/components/CompanyProfileForm";
import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import { trpc } from "@/lib/trpc";
import { getUserMembershipFromUser } from "@/lib/utils";

export default function CompanyProfilePage() {
  const currentUser = useCurrentUserContext();
  const currentUserMembership = getUserMembershipFromUser(currentUser);
  const isEditable =
    currentUserMembership?.role === "Admin" || !!currentUser.super_user_role;
  const { data, isLoading, isError } = trpc.getOrgById.useQuery(
    currentUserMembership?.org_id || ""
  );

  return (
    <DashboardOutlet title="Company Profile">
      {isLoading ? (
        <div>Loading...</div>
      ) : isError || !data ? (
        <div>Error loading profile</div>
      ) : (
        <CompanyProfileForm data={data} isEditable={isEditable} />
      )}
    </DashboardOutlet>
  );
}
