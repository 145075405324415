import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import { trpc } from "@/lib/trpc";
import { defaultError, wait } from "@/lib/utils";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

export default function useRaiseInvoiceForReports() {
  const currentUser = useCurrentUserContext();
  const utils = trpc.useUtils();
  const navigate = useNavigate();
  return trpc.raiseInvoiceForReports.useMutation({
    onSuccess() {
      toast.success("Invoice raised");
      wait(2000).then(() => {
        utils.getReportsByProviderId.refetch(
          currentUser.orgs[0].provider_details?.provider_id!
        );
        navigate("/dashboard/reports");
      });
    },
    onError(error) {
      toast.error(error.message || defaultError, {
        duration: 10000,
      });
    },
    onMutate() {
      toast.loading(
        "Please wait while we raise your invoice. This can take up to 30 seconds."
      );
    },
  });
}
