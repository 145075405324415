import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import useUserUpdateProfile from "@/lib/hooks/mutations/useUserUpdateProfile";
import { zStringRequired } from "@/lib/schemas/helpers";
import { RouterOutputs } from "@/lib/trpc";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import FormContextualSaveBar from "./FormContextualSaveBar";

const blankFormValues = {
  first_name: "",
  last_name: "",
  phone: "",
};

const userProfileSchema = z.object({
  first_name: zStringRequired,
  last_name: zStringRequired,
  phone: z.string().optional(),
});

export default function UserProfileForm({
  data,
}: {
  data: NonNullable<RouterOutputs["getUserById"]>;
}) {
  const updateProfileMutation = useUserUpdateProfile();
  const currentUser = useCurrentUserContext();

  const existingData = data.profile;
  const canUpdateUser =
    currentUser.id === data.id || !!currentUser.super_user_role;

  const userProfileForm = useForm({
    resolver: zodResolver(userProfileSchema),
    values: {
      ...blankFormValues,
      ...existingData,
      phone: existingData.phone || "",
    },
  });

  const handleSubmit = userProfileForm.handleSubmit(async function (values) {
    updateProfileMutation.mutate({
      ...values,
      user_id: data?.user_id || currentUser.user_id,
    });
  });

  return (
    <>
      <FormContextualSaveBar
        show={userProfileForm.formState.isDirty}
        submit={{ label: "Save", onPress: handleSubmit }}
        discard={{
          onPress: () =>
            userProfileForm.reset({
              ...blankFormValues,
              ...existingData,
              phone: existingData.phone || "",
            }),
        }}
      />
      <Form {...userProfileForm}>
        <form className="space-y-6" onSubmit={handleSubmit}>
          <FormField
            name="first_name"
            control={userProfileForm.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>First Name</FormLabel>
                <FormControl>
                  <Input readOnly={!canUpdateUser} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="last_name"
            control={userProfileForm.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Last Name</FormLabel>
                <FormControl>
                  <Input readOnly={!canUpdateUser} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="phone"
            control={userProfileForm.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Phone</FormLabel>
                <FormControl>
                  <Input readOnly={!canUpdateUser} {...field} />
                </FormControl>
                <FormDescription>
                  Please add your full phone number, including country code.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          {canUpdateUser && (
            <Button type="submit" disabled={updateProfileMutation.isLoading}>
              Save
            </Button>
          )}
        </form>
      </Form>
    </>
  );
}
