import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { TypographyList } from "@/components/ui/Typography";
import { Button } from "@/components/ui/button";
import { RouterOutputs, trpc } from "@/lib/trpc";
import { formatFullDate } from "@/lib/utils";
import { ExternalLinkIcon } from "lucide-react";
import { Link, useParams } from "react-router-dom";

export default function InvoicePage() {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  if (!invoiceId) throw new Error("No invoiceId");
  const {
    data: invoice,
    isLoading,
    isError,
  } = trpc.getInvoiceById.useQuery(invoiceId);
  const { data: reports } = trpc.getReportsByInvoiceId.useQuery(invoiceId);

  return (
    <DashboardOutlet title="Invoice">
      {isLoading ? (
        <div>Loading...</div>
      ) : isError || !invoice ? (
        <div>Error loading invoice</div>
      ) : (
        <div className="max-w-2xl">
          <TypographyList>
            <li>Status: {invoice.status}</li>
            <li>Amount: £{Number(invoice.amount_due) / 100}</li>
            <li>
              Created:{" "}
              {formatFullDate(new Date(invoice.stripe_invoice!.created * 1000))}
            </li>
            <li>
              <a
                href={invoice.invoice_pdf_link}
                target="_blank"
                className="flex gap-3 items-center"
              >
                Invoice PDF <ExternalLinkIcon className="w-4 h-4 -mt-1" />
              </a>
            </li>
            <li>Type: {getInvoiceTypeFromInvoice(invoice)}</li>
            {reports?.length ? (
              <li>
                {reports.map((a) => (
                  <span key={a.id}>
                    <Link to={`/dashboard/reports/${a.report_id}`}>
                      {a.report_id}
                    </Link>{" "}
                  </span>
                ))}
              </li>
            ) : null}
          </TypographyList>
          <Button asChild>
            <Link
              to={`/dashboard/request-tax-certificate?relatedInvoiceId=${invoiceId}`}
            >
              Request Tax Certificate
            </Link>
          </Button>
        </div>
      )}
    </DashboardOutlet>
  );
}

function getInvoiceTypeFromInvoice(
  invoice: NonNullable<RouterOutputs["getInvoiceById"]>
) {
  if (invoice.stripe_invoice?.metadata?.aa_provider_license_application_id) {
    return "Provider License";
  }
  //todo renewals
  if (invoice.stripe_invoice?.metadata?.aa_elicensing_report_ids) {
    return "Report(s)";
  }
  return "";
}
