import { Button } from "@/components/ui/button";
import { DatePicker } from "@/components/ui/date-picker";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import MultiSelect from "@/components/ui/multi-select";
import { Textarea } from "@/components/ui/textarea";
import {
  getCountryCodeFromLabel,
  getCountryLabelFromCode,
} from "@/lib/countries";
import useReportSaveForm from "@/lib/hooks/mutations/useReportSaveForm";
import useReportSubmitForm from "@/lib/hooks/mutations/useReportSubmitForm";
import {
  ReportFormType,
  reportFormSchema,
  reportFormSpec,
} from "@/lib/schemas/report-form-schema";
import { RouterOutputs } from "@/lib/trpc";
import {
  convertBoolToYesNo,
  convertFormValueToFileUploadAction,
  convertYesNoToBool,
} from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { ReportSubmitFormCommand } from "../../../crates/e-licensing/bindings/ReportSubmitFormCommand";
import CSAPFinder from "./CSAPFinder";
import FormContextualSaveBar from "./FormContextualSaveBar";
import UploadWidget from "./UploadWidget";
import { TypographyH3 } from "./ui/Typography";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import useReportSuEdit from "@/lib/hooks/mutations/useReportSuEdit";
import { toast } from "sonner";

const reportFormDefaultValues = {
  org_name: "",
  country_or_region: "",
  disclosure_title: "",
  disclosure_period_year: "",
  disclosure_publication_year: "",
  industry_sector: "",
  industry_subsector: "",
  assurance_report_title: "",
  report_url: "",
  aa_csap_involved: "",
  csaps: [],
  intended_users: "",
  reporting_org_responsibilities: "",
  assurance_provider_responsibilities: "",
  refers_aa1000v3: "",
  refers_isae3000: "",
  refers_other_standards: "",
  description_scope: "",
  description_subject_matter: "",
  assurance_type: "",
  assurance_level: "",
  used_criteria: [],
  used_criteria_other: "",
  disclosures_covered_description_sources: "",
  methodology_description: "",
  limitations_mitigation_approach: "",
  assurance_provider_independence_competencies: "",
  assurance_provider_name_mentioned: "",
  place: "",
  date: "",
  aa1000ap_inclusivity: "",
  aa1000ap_materiality: "",
  aa1000ap_responsiveness: "",
  aa1000ap_impact: "",
  performance_information_reliability_quality: "",
  address_deficiencies: "",
  separate_management_report: "",
  assurance_statement: undefined,
  self_confirmation: "",
};

export default function ReportForm({
  engagement,
  isEditable = false,
}: {
  engagement: NonNullable<RouterOutputs["getEngagementById"]>;
  isEditable?: boolean;
}) {
  const currentUser = useCurrentUserContext();
  const existingData = engagement.report
    ? convertReportDataToFormValues(engagement.report)
    : [
        "ApplicationSubmitted",
        "PreEngagementApproved",
        "PreEngagementFlaggedForReview",
      ].includes(engagement.status)
    ? // hack to pipe in the pre-engagement fields before the report has been saved
      convertReportDataToFormValues({
        ...engagement.preEngagement.fields,
        assurance_type: null,
        assurance_level: null,
      } as any)
    : {};

  const reportForm = useForm({
    resolver: zodResolver(reportFormSchema),
    values: { ...reportFormDefaultValues, ...existingData },
  });
  const saveReportFormMutation = useReportSaveForm();
  const submitReportFormMutation = useReportSubmitForm();
  const suEditMutation = useReportSuEdit();

  const industrySectorField = reportForm.watch("industry_sector");
  const assuranceTypeField = reportForm.watch("assurance_type");
  const csapInvolvedField = reportForm.watch("aa_csap_involved");
  const assuranceStatementField = reportForm.watch("assurance_statement");

  const mainRef = useRef<HTMLFieldSetElement>(null);
  const assuranceRef = useRef<HTMLFieldSetElement>(null);
  const performanceRef = useRef<HTMLFieldSetElement>(null);
  const statementRef = useRef<HTMLDivElement>(null);

  const canSubmit =
    !!currentUser.super_user_role ||
    (!!engagement.preEngagement.approval_type && isEditable);

  const handleSubmit = reportForm.handleSubmit(
    (values) => {
      if (currentUser.super_user_role) {
        suEditMutation.mutate({
          report_form: convertReportFormValuesToCommand(
            values,
            engagement.report_id
          ).form,
          report_id: engagement.report_id,
        });
      } else {
        submitReportFormMutation.mutate(
          convertReportFormValuesToCommand(values, engagement.report_id)
        );
      }
    },
    () => toast.error("Form has errors")
  );

  function handleSave() {
    const values = reportForm.getValues();
    const command = convertReportFormValuesToCommand(
      values,
      engagement.report_id
    );
    saveReportFormMutation.mutate(command);
  }
  const disableButtons =
    submitReportFormMutation.isLoading ||
    submitReportFormMutation.isSuccess ||
    saveReportFormMutation.isLoading ||
    suEditMutation.isLoading;

  const isRejected = engagement.status === "PostEngagementRejected";

  return (
    <>
      {isEditable && (
        <FormContextualSaveBar
          show={reportForm.formState.isDirty}
          save={
            currentUser.super_user_role || isRejected
              ? undefined
              : { label: "Save", onPress: handleSave }
          }
          submit={
            canSubmit
              ? {
                  label: currentUser.super_user_role
                    ? "Update"
                    : "Submit For Review",
                  onPress: handleSubmit,
                }
              : undefined
          }
          discard={{
            label: "Discard Changes",
            onPress: () =>
              reportForm.reset({ ...reportFormDefaultValues, ...existingData }),
          }}
        />
      )}
      <div className="max-w-screen-lg flex flex-col-reverse xl:flex-row gap-12">
        <Form {...reportForm}>
          <form onSubmit={handleSubmit} className="space-y-12">
            <fieldset
              className="p-4 bg-accent rounded-lg space-y-12"
              ref={mainRef}
            >
              <TypographyH3 className="not-sr-only">Main Details</TypographyH3>
              <legend className="sr-only">Main Details</legend>
              <div className="prose prose-lg">
                <p>
                  Only the highlighted fields below (up to the CSAP item) are to
                  be displayed on the external weblist of company reports:
                </p>
              </div>
              <FormFieldWrapper
                name="org_name"
                control={reportForm.control}
                render={(field: any) => (
                  <Input readOnly={!isEditable} {...field} />
                )}
              />
              <FormFieldWrapper
                name="country_or_region"
                control={reportForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={reportFormSpec.country_or_region.options!}
                  />
                )}
              />
              <FormFieldWrapper
                name="industry_sector"
                control={reportForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={reportFormSpec.industry_sector.options!}
                  />
                )}
              />
              {/* Subsector is disabled until a sector selection is made, which also updates the set of options */}
              <FormFieldWrapper
                name="industry_subsector"
                control={reportForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={reportFormSpec.industry_subsector.getOptions!(
                      industrySectorField
                    )}
                  />
                )}
              />
              <FormFieldWrapper
                name="disclosure_title"
                control={reportForm.control}
                render={(field: any) => (
                  <Input readOnly={!isEditable} {...field} />
                )}
              />
              <FormFieldWrapper
                name="disclosure_period_year"
                control={reportForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={reportFormSpec.disclosure_period_year.options!}
                  />
                )}
              />
              <FormFieldWrapper
                name="disclosure_publication_year"
                control={reportForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={
                      reportFormSpec.disclosure_publication_year.options!
                    }
                  />
                )}
              />
              <FormFieldWrapper
                name="assurance_report_title"
                control={reportForm.control}
                render={(field: any) => (
                  <Input {...field} readOnly={!isEditable} />
                )}
              />
              <FormFieldWrapper
                name="report_url"
                control={reportForm.control}
                render={(field: any) => (
                  <Input {...field} readOnly={!isEditable} />
                )}
              />
              <FormFieldWrapper
                name="aa_csap_involved"
                control={reportForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={reportFormSpec.aa_csap_involved.options!}
                  />
                )}
              />
              {csapInvolvedField === "Yes" ? (
                <CSAPFinder
                  form={reportForm}
                  label={reportFormSpec.csaps.label}
                  name="csaps"
                />
              ) : null}
            </fieldset>
            <TypographyH3 className="border p-8 rounded-lg">
              The fields below are for the purpose of this pre-issue self-check,
              and will NOT be displayed externally.
            </TypographyH3>
            <fieldset
              ref={assuranceRef}
              className="p-4 bg-accent rounded-lg space-y-12"
            >
              <TypographyH3 className="not-sr-only">
                Assurance Information
              </TypographyH3>
              <legend className="sr-only">Assurance Information</legend>
              <div className="prose prose-lg prose-slate">
                <p>
                  An Assurance Statement that is in accordance with the AA1000AS
                  v3 shall include the following information at a minimum, which
                  is extracted from the content of the final assurance statement
                  to be issued to the client. To ensure the Assurance Statement
                  is comprehensive, accurate, and compliant with the AA1000AS
                  v3, we ask you fill in the following fields – copied or
                  derived directly from the statement issued – as a pre-issue
                  self-check regarding the report the licensee proposes to
                  issue.
                </p>
              </div>
              <FormFieldWrapper
                name="intended_users"
                control={reportForm.control}
                render={(field: any) => (
                  <Textarea {...field} readOnly={!isEditable} />
                )}
              />
              <FormFieldWrapper
                name="reporting_org_responsibilities"
                control={reportForm.control}
                render={(field: any) => (
                  <Textarea {...field} readOnly={!isEditable} />
                )}
              />
              <FormFieldWrapper
                name="assurance_provider_responsibilities"
                control={reportForm.control}
                render={(field: any) => (
                  <Textarea {...field} readOnly={!isEditable} />
                )}
              />
              <FormFieldWrapper
                name="refers_aa1000v3"
                control={reportForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={reportFormSpec.refers_aa1000v3.options!}
                  />
                )}
              />
              <FormFieldWrapper
                name="refers_isae3000"
                control={reportForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={reportFormSpec.refers_isae3000.options!}
                  />
                )}
              />
              <FormFieldWrapper
                name="refers_other_standards"
                control={reportForm.control}
                render={(field: any) => (
                  <Textarea {...field} readOnly={!isEditable} />
                )}
              />
              <FormFieldWrapper
                name="description_scope"
                control={reportForm.control}
                render={(field: any) => (
                  <Textarea {...field} readOnly={!isEditable} />
                )}
              />
              <FormFieldWrapper
                name="description_subject_matter"
                control={reportForm.control}
                render={(field: any) => (
                  <Textarea {...field} readOnly={!isEditable} />
                )}
              />
              <FormFieldWrapper
                name="assurance_type"
                control={reportForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={reportFormSpec.assurance_type.options!}
                  />
                )}
              />
              <FormFieldWrapper
                name="assurance_level"
                control={reportForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={reportFormSpec.assurance_level.options!}
                  />
                )}
              />
              <FormFieldWrapper
                name="used_criteria"
                control={reportForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    multiple
                    options={reportFormSpec.used_criteria.options!}
                  />
                )}
              />
              <FormFieldWrapper
                name="used_criteria_other"
                control={reportForm.control}
                render={(field: any) => (
                  <Textarea {...field} readOnly={!isEditable} />
                )}
              />
              <FormFieldWrapper
                name="disclosures_covered_description_sources"
                control={reportForm.control}
                render={(field: any) => (
                  <Textarea {...field} readOnly={!isEditable} />
                )}
              />
              <FormFieldWrapper
                name="methodology_description"
                control={reportForm.control}
                render={(field: any) => (
                  <Textarea {...field} readOnly={!isEditable} />
                )}
              />
              <FormFieldWrapper
                name="limitations_mitigation_approach"
                control={reportForm.control}
                render={(field: any) => (
                  <Textarea {...field} readOnly={!isEditable} />
                )}
              />
              <FormFieldWrapper
                name="assurance_provider_independence_competencies"
                control={reportForm.control}
                render={(field: any) => (
                  <Textarea {...field} readOnly={!isEditable} />
                )}
              />
              <FormFieldWrapper
                name="assurance_provider_name_mentioned"
                control={reportForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={
                      reportFormSpec.assurance_provider_name_mentioned.options!
                    }
                  />
                )}
              />
              <FormFieldWrapper
                name="place"
                control={reportForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={reportFormSpec.place.options!}
                  />
                )}
              />
              <FormFieldWrapper
                name="date"
                control={reportForm.control}
                render={(field: any) => (
                  <DatePicker
                    date={field.value}
                    setDate={field.onChange}
                    {...field}
                  />
                )}
              />
            </fieldset>
            <fieldset
              className="p-4 bg-accent rounded-lg space-y-12"
              ref={performanceRef}
            >
              <TypographyH3 className="not-sr-only">
                Performance-related Information
              </TypographyH3>
              <legend className="sr-only">
                Performance-related Information
              </legend>
              <div className="prose prose-lg">
                <p>
                  Findings and conclusions concerning adherence to the AA1000
                  AccountAbility Principles
                </p>
              </div>
              <FormFieldWrapper
                name="aa1000ap_inclusivity"
                control={reportForm.control}
                render={(field: any) => (
                  <Textarea {...field} readOnly={!isEditable} />
                )}
              />
              <FormFieldWrapper
                name="aa1000ap_materiality"
                control={reportForm.control}
                render={(field: any) => (
                  <Textarea {...field} readOnly={!isEditable} />
                )}
              />
              <FormFieldWrapper
                name="aa1000ap_responsiveness"
                control={reportForm.control}
                render={(field: any) => (
                  <Textarea {...field} readOnly={!isEditable} />
                )}
              />
              <FormFieldWrapper
                name="aa1000ap_impact"
                control={reportForm.control}
                render={(field: any) => (
                  <Textarea {...field} readOnly={!isEditable} />
                )}
              />
              {assuranceTypeField === "Type 2" ? (
                <FormFieldWrapper
                  name="performance_information_reliability_quality"
                  control={reportForm.control}
                  render={(field: any) => (
                    <Textarea {...field} readOnly={!isEditable} />
                  )}
                />
              ) : null}
              <FormFieldWrapper
                name="address_deficiencies"
                control={reportForm.control}
                render={(field: any) => (
                  <Textarea {...field} readOnly={!isEditable} />
                )}
              />
              <FormFieldWrapper
                name="separate_management_report"
                control={reportForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={reportFormSpec.separate_management_report.options!}
                  />
                )}
              />
            </fieldset>
            <div ref={statementRef} className="bg-accent p-4 rounded-lg">
              <FormFieldWrapper
                name="assurance_statement"
                control={reportForm.control}
                render={(field: any) => (
                  <UploadWidget
                    onSuccess={field.onChange}
                    value={assuranceStatementField}
                    disabled={!isEditable}
                    tag="assurance_statement"
                  />
                )}
              />
            </div>
            <div>
              <FormFieldWrapper
                name="self_confirmation"
                control={reportForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={reportFormSpec.self_confirmation.options!}
                  />
                )}
              />
            </div>
            <div className="flex gap-6">
              {!currentUser.super_user_role && isEditable && !isRejected && (
                <Button
                  type="button"
                  variant="secondary"
                  onClick={handleSave}
                  disabled={disableButtons}
                >
                  Save
                </Button>
              )}

              {canSubmit && (
                <Button type="submit" disabled={disableButtons}>
                  {currentUser.super_user_role ? "Update" : "Submit for Review"}
                </Button>
              )}
            </div>
          </form>
        </Form>
        <div className="shrink-0 relative pl-4 lg:pl-0">
          <div className="sticky left-0 top-14 flex flex-col gap-y-6">
            <h5 className="text-lg">Jump To Section</h5>
            <ul className="space-y-2 list-disc">
              <li
                className="cursor-pointer"
                onClick={() =>
                  mainRef.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Main Details
              </li>
              <li
                className="cursor-pointer"
                onClick={() =>
                  assuranceRef.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Assurance Information
              </li>
              <li
                className="cursor-pointer"
                onClick={() =>
                  performanceRef.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Performance-related Information
              </li>
              <li
                className="cursor-pointer"
                onClick={() =>
                  statementRef.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Upload assurance statement
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

function FormFieldWrapper({
  control,
  name,
  render,
}: {
  control: any;
  name: Extract<keyof typeof reportFormSpec, string>;
  render: any;
}) {
  return (
    <FormField
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormItem>
            <FormLabel>{reportFormSpec[name].label}</FormLabel>
            <FormControl>{render(field)}</FormControl>
            <FormDescription>{reportFormSpec[name].helperText}</FormDescription>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
}

function convertReportDataToFormValues(
  report: NonNullable<NonNullable<RouterOutputs["getEngagementById"]>["report"]>
): Partial<ReportFormType> {
  return {
    org_name: report.org_name || "",
    country_or_region: report.country_or_region
      ? getCountryLabelFromCode(report.country_or_region)
      : "",
    disclosure_title: report.disclosure_title || "",
    disclosure_period_year: report.disclosure_period_year || "",
    disclosure_publication_year: report.disclosure_publication_year || "",
    assurance_report_title: report.assurance_report_title || "",
    report_url: report.report_url || "",
    aa_csap_involved: convertBoolToYesNo(report.aa_csap_involved),
    csaps: report.csaps || [],
    intended_users: report.intended_users || "",
    reporting_org_responsibilities: report.reporting_org_responsibilities || "",
    assurance_provider_responsibilities:
      report.assurance_provider_responsibilities || "",
    refers_aa1000v3: convertBoolToYesNo(report.refers_aa1000v3),
    refers_isae3000: convertBoolToYesNo(report.refers_isae3000),
    refers_other_standards: report.refers_other_standards || "",
    description_scope: report.description_scope || "",
    description_subject_matter: report.description_subject_matter || "",
    used_criteria: report.used_criteria || [],
    used_criteria_other: report.used_criteria_other || "",
    disclosures_covered_description_sources:
      report.disclosures_covered_description_sources || "",
    methodology_description: report.methodology_description || "",
    limitations_mitigation_approach:
      report.limitations_mitigation_approach || "",
    assurance_provider_independence_competencies:
      report.assurance_provider_independence_competencies || "",
    assurance_provider_name_mentioned: convertBoolToYesNo(
      report.assurance_provider_name_mentioned
    ),
    place: report.place ? getCountryLabelFromCode(report.place) : "",
    date: report.date ? new Date(report.date) : "",
    aa1000ap_inclusivity: report.aa1000ap_inclusivity || "",
    aa1000ap_materiality: report.aa1000ap_materiality || "",
    aa1000ap_responsiveness: report.aa1000ap_responsiveness || "",
    aa1000ap_impact: report.aa1000ap_impact || "",
    address_deficiencies: report.address_deficiencies || "",
    separate_management_report: convertBoolToYesNo(
      report.separate_management_report
    ),
    self_confirmation: convertBoolToYesNo(report.self_confirmation),
    industry_sector: report.industry_sector || "",
    industry_subsector: report.industry_subsector || "",
    assurance_type: report.assurance_type || "",
    assurance_level: report.assurance_level || "",
    performance_information_reliability_quality:
      report.performance_information_reliability_quality || "",
    assurance_statement: report.assurance_statement?.file_details || undefined,
  };
}

function convertReportFormValuesToCommand(
  values: Partial<ReportFormType>,
  report_id: string
): ReportSubmitFormCommand {
  return {
    report_id,
    form: {
      org_name: values.org_name || null,
      country_or_region: values.country_or_region
        ? getCountryCodeFromLabel(values.country_or_region)
        : null,
      disclosure_title: values.disclosure_title || null,
      disclosure_period_year: values.disclosure_period_year || null,
      disclosure_publication_year: values.disclosure_publication_year || null,
      assurance_report_title: values.assurance_report_title || null,
      report_url: values.report_url || null,
      aa_csap_involved: convertYesNoToBool(values.aa_csap_involved),
      csaps: values.csaps || [],
      intended_users: values.intended_users || null,
      reporting_org_responsibilities:
        values.reporting_org_responsibilities || null,
      assurance_provider_responsibilities:
        values.assurance_provider_responsibilities || null,
      refers_aa1000v3: convertYesNoToBool(values.refers_aa1000v3),
      refers_isae3000: convertYesNoToBool(values.refers_isae3000),
      refers_other_standards: values.refers_other_standards || null,
      description_scope: values.description_scope || null,
      description_subject_matter: values.description_subject_matter || null,
      used_criteria: values.used_criteria || [],
      used_criteria_other: values.used_criteria_other || null,
      disclosures_covered_description_sources:
        values.disclosures_covered_description_sources || null,
      methodology_description: values.methodology_description || null,
      limitations_mitigation_approach:
        values.limitations_mitigation_approach || null,
      assurance_provider_independence_competencies:
        values.assurance_provider_independence_competencies || null,
      assurance_provider_name_mentioned: convertYesNoToBool(
        values.assurance_provider_name_mentioned
      ),
      place: values.place ? getCountryCodeFromLabel(values.place) : null,
      date: values.date ? values.date.toISOString() : null,
      aa1000ap_inclusivity: values.aa1000ap_inclusivity || null,
      aa1000ap_materiality: values.aa1000ap_materiality || null,
      aa1000ap_responsiveness: values.aa1000ap_responsiveness || null,
      aa1000ap_impact: values.aa1000ap_impact || null,
      address_deficiencies: values.address_deficiencies || null,
      separate_management_report: convertYesNoToBool(
        values.separate_management_report
      ),
      assurance_statement: convertFormValueToFileUploadAction(
        values.assurance_statement
      ),
      self_confirmation: convertYesNoToBool(values.self_confirmation),
      industry_sector_and_subsector: values.industry_sector
        ? {
            sector: values.industry_sector,
            subsector: values.industry_subsector || null,
          }
        : null,
      assurance_type:
        values.assurance_type === "Type 1"
          ? "Type1"
          : values.assurance_type === "Type 2"
          ? {
              Type2: {
                performance_information_reliability_quality:
                  values.performance_information_reliability_quality || "",
              },
            }
          : null,
      assurance_level: values.assurance_level || null,
    },
  };
}
