import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";
import { trpc } from "@/lib/trpc";
import { defaultError, wait } from "@/lib/utils";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "sonner";
import { ProviderReplaceLicenseAgreementCommand } from "../../../../../crates/e-licensing/bindings/ProviderReplaceLicenseAgreementCommand";

export default function useProviderLicenseAgreementReplace(org_id: string) {
  const utils = trpc.useUtils();
  const { axiosInstance: axios } = useAuthTokenContext();
  return useMutation({
    mutationFn: (data: ProviderReplaceLicenseAgreementCommand) => {
      return axios(
        `${
          import.meta.env.VITE_ELIC_API_URL
        }/provider/license-agreement/replace`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        }
      );
    },
    onSuccess: (_, variables) => {
      toast.success(
        variables.new_license_agreement === "Standard"
          ? "Standard license agreement applied."
          : "Custom license agreement applied."
      );
      utils.getOrgById.setData(org_id, (oldData) => {
        return oldData && oldData.provider_details
          ? {
              ...oldData,
              provider_details: {
                ...oldData.provider_details,
                custom_license_agreement:
                  variables.new_license_agreement === "Standard"
                    ? null
                    : {
                        file_details:
                          variables.new_license_agreement.Custom.file_details,
                        s3_object_details: null,
                      },
              },
            }
          : oldData;
      });
      wait(2000).then(() => utils.getOrgById.refetch(org_id));
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
