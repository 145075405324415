import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import { RouterOutputs, trpc } from "@/lib/trpc";
import { getUserMembershipFromUser } from "@/lib/utils";
import { Link } from "react-router-dom";
import MultiSelect from "./ui/multi-select";
import { Label } from "./ui/label";
import { TypographyH4 } from "./ui/Typography";

export default function BillingAccountSelector({
  onSelectBillingAccount,
  selectedBillingAccount,
}: {
  onSelectBillingAccount: (
    billingAccount: NonNullable<
      RouterOutputs["getBillingAccountsByOrgId"]
    >[number]
  ) => void;
  selectedBillingAccount?:
    | NonNullable<RouterOutputs["getBillingAccountsByOrgId"]>[number]
    | null;
}) {
  const currentUser = useCurrentUserContext();
  const currentUserMembership = getUserMembershipFromUser(currentUser);
  if (!currentUserMembership) {
    return "Unknown Error";
  }
  const { data, isLoading, isError } = trpc.getBillingAccountsByOrgId.useQuery(
    currentUserMembership.org_id
  );
  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (isError || !data) {
    return <p>Error loading billing accounts</p>;
  }
  if (!data.length) {
    return (
      <p className="bg-accent p-4 text-destructive/80 rounded-lg">
        No billing accounts found. Please{" "}
        <Link to="/dashboard/billing-accounts/new" className="underline">
          create one
        </Link>
      </p>
    );
  }
  return (
    <div>
      <Label className="mb-2">Select Billing Account</Label>
      <MultiSelect
        options={data.map((a) => a.nickname)}
        field={{
          onChange: (nickname: string) =>
            onSelectBillingAccount(data.find((a) => a.nickname === nickname)!),
          value: selectedBillingAccount?.nickname || "",
        }}
      />
    </div>
  );
}
