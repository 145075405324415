export default async function getProviderMarkings(providerId: string) {
  const res = await fetch(
    `${import.meta.env.VITE_TRPC_URL}/getProviderMarkings?batch=1`,
    {
      method: "POST",
      body: JSON.stringify({
        0: { providerId },
      }),
    }
  );
  const json = await res.json();

  const url = json[0].result.data;
  const link = document.createElement("a");
  link.href = url;
  link.download = `AA1000V3_Provider_${providerId}.png`;
  link.click();
}
