import { AlertTriangleIcon } from "lucide-react";
import { Button } from "./ui/button";
import { cn } from "@/lib/utils";

interface FormContextualSaveBarProps {
  save?: {
    label: string;
    onPress: () => void;
  };
  discard: {
    label?: string;
    onPress: () => void;
  };
  submit?: {
    label: string;
    onPress: () => void;
  };
  show: boolean;
}

export default function FormContextualSaveBar({
  save,
  discard,
  submit,
  show,
}: FormContextualSaveBarProps) {
  return (
    <div
      className={cn(
        "fixed text-primary-foreground px-4 sm:pl-6 lg:pl-8 gap-12 z-50 top-0 h-12 bg-primary inset-x-0 mt-16 lg:mt-0 transition-all duration-300",
        !show && "opacity-0 invisible"
      )}
    >
      <div className="flex max-w-2xl items-center justify-between h-12 mx-auto">
        <p className="flex items-center gap-4">
          <AlertTriangleIcon />{" "}
          <span className="hidden sm:block">Unsaved Changes</span>
        </p>
        <div className="flex gap-4 items-center">
          {submit ? (
            <Button
              variant="secondary"
              size="sm"
              className="h-8 hover:bg-secondary/90"
              onClick={submit.onPress}
            >
              {submit.label}
            </Button>
          ) : null}
          {save ? (
            <Button
              variant="secondary"
              size="sm"
              className="h-8 hover:bg-secondary/90"
              onClick={save.onPress}
            >
              {save.label}
            </Button>
          ) : null}
          <Button
            variant="destructive"
            size="sm"
            className="h-8"
            onClick={discard.onPress}
          >
            {discard.label || "Discard"}
          </Button>
        </div>
      </div>
    </div>
  );
}
