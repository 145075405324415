import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { DataTable, ViewEditButtonLink } from "@/components/ui/data-table";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { RouterOutputs, trpc } from "@/lib/trpc";
import { getUserMembershipFromUser } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { ColumnDef } from "@tanstack/react-table";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const defaultValues = {
  first_name: "",
  last_name: "",
  email: "",
};

const userColumns: ColumnDef<RouterOutputs["searchUsers"][number]>[] = [
  {
    id: "view/edit",
    cell: ({ row }) => (
      <ViewEditButtonLink to={`/dashboard/users/${row.original.id}`} />
    ),
  },
  { accessorKey: "profile.first_name", header: "First Name" },
  { accessorKey: "profile.last_name", header: "Last Name" },
  {
    accessorKey: "email",
    header: "Email",
    cell: ({ row }) => (
      <a
        href={`mailto:${row.original.email}`}
        target="_blank"
        className="underline underline-offset-4"
      >
        {row.original.email}
      </a>
    ),
  },
  {
    accessorKey: "profile.phone",
    header: "Phone",
    enableGlobalFilter: false,
  },
  {
    header: "Role",
    enableGlobalFilter: false,
    cell: ({ row }) => {
      if (row.original.super_user_role) return "Super User";
      const userMembership = getUserMembershipFromUser(row.original);
      return userMembership?.role;
    },
  },
  {
    header: "Organization",
    cell: ({ row }) => row.original.orgs[0]?.legal_company_name,
  },
];

export default function UsersTableAA() {
  const [searchQuery, setSearchQuery] = useState<SearchQuery | null>(null);
  const queryObject = {
    first_name: searchQuery?.first_name || undefined,
    last_name: searchQuery?.last_name || undefined,
    email: searchQuery?.email || undefined,
  };

  const { data, isLoading, isError } = trpc.searchUsers.useQuery(queryObject, {
    cacheTime: 1,
  });
  return (
    <div className="space-y-12">
      <UserSearchForm setSearchQuery={setSearchQuery} />
      {isLoading ? (
        <div>Loading...</div>
      ) : isError ? (
        <div>Error loading users</div>
      ) : (
        <DataTable data={data} columns={userColumns} showSearch={false} />
      )}
    </div>
  );
}

const searchQuerySchema = z.object({
  first_name: z.string().optional(),
  last_name: z.string().optional(),
  email: z.string().optional(),
});

type SearchQuery = z.infer<typeof searchQuerySchema>;

function UserSearchForm({
  setSearchQuery,
}: {
  setSearchQuery: (query: any) => void;
}) {
  const form = useForm({
    resolver: zodResolver(searchQuerySchema),
    defaultValues,
  });
  const handleSubmit = form.handleSubmit((data) => {
    setSearchQuery(data);
  });
  return (
    <Accordion
      type="single"
      defaultValue="item-1"
      collapsible
      className="w-full"
    >
      <AccordionItem value="item-1">
        <AccordionTrigger className="pl-2">Search Form</AccordionTrigger>
        <AccordionContent>
          <Form {...form}>
            <form onSubmit={handleSubmit} className="pl-2 pt-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-8 gap-x-8">
                <FormField
                  name="first_name"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>First Name</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  name="last_name"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Last Name</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  name="email"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Email</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </div>
              <div className="flex gap-x-3 mt-12">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => form.reset(defaultValues)}
                >
                  Reset
                </Button>
                <Button type="submit">Search</Button>
              </div>
            </form>
          </Form>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
