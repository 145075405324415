import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import {
  countryLabels,
  getCountryCodeFromLabel,
  getCountryLabelFromCode,
  getCountryNameFromCode,
  getCountryNameFromLabel,
} from "@/lib/countries";
import useTaxCertificates from "@/lib/hooks/queries/useTaxCertificates";
import { zStringRequired } from "@/lib/schemas/helpers";
import { trpc } from "@/lib/trpc";
import { getUserMembershipFromUser, wait } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { AlertCircleIcon, DownloadIcon } from "lucide-react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import * as z from "zod";
import { Alpha2CountryCode } from "../../../crates/iam/bindings/Alpha2CountryCode";
import { TypographyH4 } from "./ui/Typography";
import { Button } from "./ui/button";
import MultiSelect from "./ui/multi-select";
import { Textarea } from "./ui/textarea";

const taxCertificateRequestSchema = z.object({
  country: zStringRequired,
  requestText: zStringRequired,
  relatedInvoiceId: z.string().optional(),
});

export default function TaxCertificateRequestsForm({
  relatedInvoiceId,
}: {
  relatedInvoiceId: string | null;
}) {
  const currentUser = useCurrentUserContext();
  const currentUserMembership = getUserMembershipFromUser(currentUser);
  if (!currentUserMembership) {
    throw new Error("Unauthorized");
  }
  const utils = trpc.useUtils();
  const navigate = useNavigate();
  const requestTaxCertificateMutation = trpc.requestTaxCertificate.useMutation({
    onSuccess() {
      toast.success("Request submitted.");
      wait(1000).then(() => {
        utils.getTaxCertificateRequestsByOrgId.refetch(
          currentUser.orgs[0].org_id
        );
        navigate("/dashboard/your-tax-certificates");
      });
    },
  });
  const orgCountryCode = currentUser.orgs[0].profile
    ?.business_country as Alpha2CountryCode;
  const orgCountryLabel = orgCountryCode
    ? getCountryLabelFromCode(orgCountryCode)
    : null;
  const orgCountryName = orgCountryCode
    ? getCountryNameFromCode(orgCountryCode)
    : null;
  const { isLoading, isError, data } = useTaxCertificates();
  const taxCertificateRequestsForm = useForm({
    resolver: zodResolver(taxCertificateRequestSchema),
    values: {
      country: "",
      requestText: "",
      relatedInvoiceId: relatedInvoiceId || "",
    },
  });
  const selectedCountry = taxCertificateRequestsForm.watch("country");
  const handleSubmitRequestForm = taxCertificateRequestsForm.handleSubmit(
    (values) => {
      requestTaxCertificateMutation.mutate({
        country: getCountryCodeFromLabel(values.country),
        requestText: values.requestText,
        relatedInvoiceId: values.relatedInvoiceId,
        org_id: currentUser.orgs[0].org_id,
      });
    }
  );

  const instructionsForSelectedCountry = data?.data?.find(
    (a) => a.attributes.country === getCountryNameFromLabel(selectedCountry)
  )?.attributes.special_instructions;
  const businessCountryHasFile = !!data?.data?.find(
    (a) => a.attributes.country === orgCountryName
  )?.attributes?.file?.data;
  const selectedCountryFile = data?.data?.find(
    (a) => a.attributes.country === getCountryNameFromLabel(selectedCountry)
  )?.attributes?.file?.data;

  return isLoading ? (
    <div>Loading...</div>
  ) : isError ? (
    <p>Error loading tax certificate information</p>
  ) : (
    <div className="max-w-2xl">
      {businessCountryHasFile && (
        <Alert>
          <AlertCircleIcon className="h-5 w-5" />
          <AlertTitle>Note</AlertTitle>
          <AlertDescription>
            <p>
              Your Business Country from your company profile is{" "}
              {orgCountryLabel}, which has a generic tax certificate available.
              You can download it{" "}
              <Link
                to={`${window.location.origin}/dashboard/tax-certificates#${orgCountryCode}`}
                className="underline"
              >
                here
              </Link>
              . If you have a more specific request, please use the form below.
            </p>
          </AlertDescription>
        </Alert>
      )}
      <Form {...taxCertificateRequestsForm}>
        <form className="space-y-12 mt-12" onSubmit={handleSubmitRequestForm}>
          <FormField
            name="relatedInvoiceId"
            control={taxCertificateRequestsForm.control}
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Related Invoice ID</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  {!relatedInvoiceId && (
                    <FormDescription>
                      If this request relates to a specific invoice you can
                      paste the invoice ID here, or you can find the invoice in
                      your{" "}
                      <Link to="/dashboard/invoices" className="underline">
                        invoices list
                      </Link>{" "}
                      and click the "Request Tax Certificate" button to
                      pre-populate this field.
                    </FormDescription>
                  )}
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            name="country"
            control={taxCertificateRequestsForm.control}
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>
                    Which country does the tax certificate relate request relate
                    to?
                  </FormLabel>
                  <FormControl>
                    <MultiSelect field={field} options={countryLabels} />
                  </FormControl>
                  <FormDescription>
                    Any special instructions will appear below
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          {!!instructionsForSelectedCountry && (
            <div className="bg-accent p-4 rounded-lg space-y-4">
              <TypographyH4>Instructions for {selectedCountry}</TypographyH4>
              <div
                className="prose"
                dangerouslySetInnerHTML={{
                  __html: instructionsForSelectedCountry,
                }}
              />
              {!!selectedCountryFile && (
                <a
                  href={selectedCountryFile.attributes.url}
                  className="cursor-pointer underline flex items-center gap-3 mt-6"
                  target="_blank"
                >
                  Download file <DownloadIcon />
                </a>
              )}
            </div>
          )}
          {!instructionsForSelectedCountry && !!selectedCountryFile && (
            <div className="bg-accent p-4 rounded-lg space-y-4">
              <p>
                {selectedCountry} has a generic tax certificate available. You
                can download it{" "}
                <Link
                  to={`${window.location.origin}/dashboard/tax-certificates`}
                  className="underline"
                >
                  here
                </Link>
                . If you have a more specific request, please use the form
                below.
              </p>
            </div>
          )}
          <FormField
            name="requestText"
            control={taxCertificateRequestsForm.control}
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Request details</FormLabel>
                  <FormControl>
                    <Textarea {...field} />
                  </FormControl>
                  <FormDescription>
                    Make sure to review any specific instructions for your
                    country above, if available
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <Button
            type="submit"
            disabled={requestTaxCertificateMutation.isLoading}
          >
            Submit
          </Button>
        </form>
      </Form>
    </div>
  );
}
