import { PDFDocument, StandardFonts } from "pdf-lib";

export default async function signLicensingPDF({
  doingBusinessAs,
  address,
  timestamp,
}: {
  doingBusinessAs: string;
  address: string;
  timestamp: string;
}) {
  const res = await fetch(
    "https://aa-files.nyc3.cdn.digitaloceanspaces.com/elicensing/AA1000ASv3_Licensing_Agreement_template.pdf"
  );
  const buffer = await res.arrayBuffer();
  const pdfDoc = await PDFDocument.load(buffer);
  const courierFont = await pdfDoc.embedFont(StandardFonts.Courier);

  const canv = document.createElement("canvas");
  canv.width = 650;
  const ctx = canv.getContext("2d");
  if (!ctx) return;
  ctx.font = "12px Courier";

  const nameLines = getLines(ctx, doingBusinessAs, 470);
  const addressLines = getLines(ctx, `LOCATED AT ${address}`, 470);
  const lines = [...nameLines, ...addressLines];
  for (let i = 0; i < lines.length; i++) {
    ctx.fillText(lines[i], 10, 50 + i * 20, 890);
  }
  // ctx.fillText(lines.join("\n"), 10, 50, 890);
  const canvasImage = canv.toDataURL("image/png", 1);

  const pdfImage = await pdfDoc.embedPng(canvasImage);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  const lastPage = pages[pages.length - 1];

  firstPage.drawImage(pdfImage, {
    x: 62,
    y: 485,
    // size: 13,
    // font: courierFont,
    // maxWidth: 480,
    // lineHeight: 16,
    width: pdfImage.width,
    height: pdfImage.height,
  });
  lastPage.drawText(timestamp, {
    x: 173,
    y: 661,
    size: 13,
    font: courierFont,
  });

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes.buffer], { type: "application/pdf" });
  // Create a URL for the blob
  const blobURL = URL.createObjectURL(blob);

  // Use the URL in the download link
  const link = document.createElement("a");
  link.href = blobURL;
  link.download = "AA1000ASv3_Licensing_Agreement_signed.pdf";
  link.click();

  // Clean up
  URL.revokeObjectURL(blobURL);
}

function getLines(
  ctx: CanvasRenderingContext2D,
  text: string,
  maxWidth: number
) {
  const words = text.split(" ");
  const lines = [];
  let currentLine = words[0];

  for (let i = 1; i < words.length; i++) {
    const word = words[i];
    const width = ctx.measureText(currentLine + " " + word).width;
    if (width < maxWidth) {
      currentLine += " " + word;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  }
  lines.push(currentLine);
  return lines;
}
