import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import MultiSelect from "@/components/ui/multi-select";
import {
  getCountryCodeFromLabel,
  getCountryLabelFromCode,
} from "@/lib/countries";
import useUpdateCompanyNameAndProfile from "@/lib/hooks/mutations/useUpdateCompanyNameAndProfile";
import {
  CompanyProfileType,
  companyProfileSchema,
  companyProfileSpec,
} from "@/lib/schemas/company-profile-schema";
import { RouterOutputs } from "@/lib/trpc";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { OrgUpdateCompanyProfileCommand } from "../../../crates/iam/bindings/OrgUpdateCompanyProfileCommand";
import FormContextualSaveBar from "./FormContextualSaveBar";
import { toast } from "sonner";

const blankFormValues = {
  org_id: "",
  legal_company_name: "",
  doing_business_as: "",
  doing_business_as_intl: "",
  registration_number: "",
  tax_number: "",
  company_website: "",
  business_address: "",
  business_country: "",
  business_phone_number: "",
  active_in: [],
  supplier_registration_process: "",
  supplier_contact_name: "",
  supplier_contact_email: "",
  supplier_contact_phone: "",
  org_type: "",
  annual_revenue_gbp: "",
  total_employees: "",
  industry_sector: [],
};

export default function CompanyProfileForm({
  data,
  isEditable,
}: {
  isEditable?: boolean;
  data:
    | NonNullable<RouterOutputs["getOrgById"]>
    | NonNullable<RouterOutputs["getUserById"]>["orgs"][number];
}) {
  const currentUser = useCurrentUserContext();
  const updateProfileMutation = useUpdateCompanyNameAndProfile(!data.profile);

  const existingProfileConverted = data.profile
    ? convertProfileDataToFormValues(data.profile, data.legal_company_name)
    : { legal_company_name: data.legal_company_name };

  const companyProfileForm = useForm({
    resolver: zodResolver(companyProfileSchema),
    values: { ...blankFormValues, ...existingProfileConverted },
  });

  const handleChangeProfile = companyProfileForm.handleSubmit(
    async (values) => {
      const org_id = data.org_id || currentUser.orgs[0].org_id;
      const name = {
        org_id,
        new_company_name: values.legal_company_name,
      };
      const profile = convertCompanyFormValuesToCommand(values, org_id);

      updateProfileMutation.mutate({ name, profile });
    },
    () => toast.error("Form has errors")
  );

  const supplierWatch = companyProfileForm.watch(
    "supplier_registration_process"
  );

  return (
    <>
      <FormContextualSaveBar
        show={companyProfileForm.formState.isDirty}
        submit={{ label: "Save", onPress: handleChangeProfile }}
        discard={{
          onPress: () =>
            companyProfileForm.reset({
              ...blankFormValues,
              ...existingProfileConverted,
            }),
        }}
      />
      <div className="max-w-2xl">
        <div>
          <Form {...companyProfileForm}>
            <form
              onSubmit={handleChangeProfile}
              className="max-w-2xl space-y-12"
            >
              <FormFieldWrapper
                name="legal_company_name"
                control={companyProfileForm.control}
                render={(field: any) => (
                  <Input readOnly={!isEditable} {...field} />
                )}
              />
              <FormFieldWrapper
                name="doing_business_as"
                control={companyProfileForm.control}
                render={(field: any) => (
                  <Input readOnly={!isEditable} {...field} />
                )}
              />
              <FormFieldWrapper
                name="doing_business_as_intl"
                control={companyProfileForm.control}
                render={(field: any) => (
                  <Input readOnly={!isEditable} {...field} />
                )}
              />
              <FormFieldWrapper
                name="registration_number"
                control={companyProfileForm.control}
                render={(field: any) => (
                  <Input readOnly={!isEditable} {...field} />
                )}
              />
              <FormFieldWrapper
                name="tax_number"
                control={companyProfileForm.control}
                render={(field: any) => (
                  <Input readOnly={!isEditable} {...field} />
                )}
              />
              <FormFieldWrapper
                name="company_website"
                control={companyProfileForm.control}
                render={(field: any) => (
                  <Input readOnly={!isEditable} {...field} />
                )}
              />
              <FormFieldWrapper
                name="business_address"
                control={companyProfileForm.control}
                render={(field: any) => (
                  <Input readOnly={!isEditable} {...field} />
                )}
              />
              <FormFieldWrapper
                name="business_country"
                control={companyProfileForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={companyProfileSpec.business_country.options!}
                  />
                )}
              />
              <FormFieldWrapper
                name="business_phone_number"
                control={companyProfileForm.control}
                render={(field: any) => (
                  <Input readOnly={!isEditable} {...field} />
                )}
              />
              <FormFieldWrapper
                name="active_in"
                control={companyProfileForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={companyProfileSpec.active_in.options!}
                    multiple
                  />
                )}
              />
              <FormFieldWrapper
                name="supplier_registration_process"
                control={companyProfileForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={
                      companyProfileSpec.supplier_registration_process.options!
                    }
                  />
                )}
              />
              {supplierWatch === "Yes" ? (
                <div className="p-4 bg-accent rounded-lg space-y-6">
                  <FormFieldWrapper
                    name="supplier_contact_name"
                    control={companyProfileForm.control}
                    render={(field: any) => (
                      <Input readOnly={!isEditable} {...field} />
                    )}
                  />
                  <FormFieldWrapper
                    name="supplier_contact_email"
                    control={companyProfileForm.control}
                    render={(field: any) => (
                      <Input readOnly={!isEditable} {...field} />
                    )}
                  />
                  <FormFieldWrapper
                    name="supplier_contact_phone"
                    control={companyProfileForm.control}
                    render={(field: any) => (
                      <Input readOnly={!isEditable} {...field} />
                    )}
                  />
                </div>
              ) : null}
              <FormFieldWrapper
                name="org_type"
                control={companyProfileForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={companyProfileSpec.org_type.options!}
                  />
                )}
              />
              <FormFieldWrapper
                name="annual_revenue_gbp"
                control={companyProfileForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={companyProfileSpec.annual_revenue_gbp.options!}
                  />
                )}
              />
              <FormFieldWrapper
                name="total_employees"
                control={companyProfileForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={companyProfileSpec.total_employees.options!}
                  />
                )}
              />
              <FormFieldWrapper
                name="industry_sector"
                control={companyProfileForm.control}
                render={(field: any) => (
                  <MultiSelect
                    disabled={!isEditable}
                    field={field}
                    options={companyProfileSpec.industry_sector.options!}
                    multiple
                  />
                )}
              />
              <Button
                type="submit"
                disabled={
                  !companyProfileForm.formState.isDirty ||
                  updateProfileMutation.isLoading
                }
              >
                Save
              </Button>
            </form>
          </Form>
        </div>
      </div>
    </>
  );
}

function FormFieldWrapper({
  control,
  name,
  render,
}: {
  control: any;
  name: Extract<keyof typeof companyProfileSpec, string>;
  render: any;
}) {
  return (
    <FormField
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormItem>
            <FormLabel
              dangerouslySetInnerHTML={{
                __html: companyProfileSpec[name].label,
              }}
            />
            <FormControl>{render(field)}</FormControl>
            <FormDescription>
              {companyProfileSpec[name].helperText}
            </FormDescription>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
}

function convertCompanyFormValuesToCommand(
  values: CompanyProfileType,
  org_id: string
): OrgUpdateCompanyProfileCommand {
  return {
    org_id,
    new_company_profile: {
      doing_business_as: values.doing_business_as,
      doing_business_as_intl: values.doing_business_as_intl || null,
      registration_number: values.registration_number || null,
      tax_number: values.tax_number || null,
      company_website: values.company_website,
      business_address: values.business_address,
      business_country: getCountryCodeFromLabel(values.business_country),
      business_phone_number: values.business_phone_number || "",
      active_in: values.active_in.map((a) => {
        if (a === "Global") return a;
        return { Country: getCountryCodeFromLabel(a) };
      }),
      supplier_registration_process:
        values.supplier_registration_process === "Yes"
          ? {
              supplier_contact_name: values.supplier_contact_name,
              supplier_contact_email: values.supplier_contact_email,
              supplier_contact_phone: values.supplier_contact_phone,
            }
          : null,
      org_type: values.org_type,
      annual_revenue_gbp: values.annual_revenue_gbp,
      total_employees: values.total_employees,
      industry_sector: values.industry_sector,
    },
  };
}

function convertProfileDataToFormValues(
  profile: NonNullable<NonNullable<RouterOutputs["getOrgById"]>["profile"]>,
  legal_company_name: string
): CompanyProfileType {
  return {
    legal_company_name: legal_company_name,
    doing_business_as: profile.doing_business_as || "",
    doing_business_as_intl: profile.doing_business_as_intl || "",
    registration_number: profile.registration_number || "",
    tax_number: profile.tax_number || "",
    company_website: profile.company_website || "",
    business_address: profile.business_address || "",
    business_country: profile.business_country
      ? getCountryLabelFromCode(profile.business_country as any)
      : "",
    business_phone_number: profile.business_phone_number || "",
    active_in: profile.active_in.map((a) => {
      if (a === "Global") return a;
      return getCountryLabelFromCode(a);
    }),
    supplier_registration_process: profile.supplier_registration_process
      ? "Yes"
      : "No",
    supplier_contact_name:
      profile.supplier_registration_process?.supplier_contact_name || "",
    supplier_contact_email:
      profile.supplier_registration_process?.supplier_contact_email || "",
    supplier_contact_phone:
      profile.supplier_registration_process?.supplier_contact_phone || "",
    org_type: profile.org_type || "",
    annual_revenue_gbp: profile.annual_revenue_gbp || "",
    total_employees: profile.total_employees || "",
    industry_sector: profile.industry_sector || [],
  };
}
