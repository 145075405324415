import * as z from "zod";

export const requiredErrorMap = {
  errorMap: () => ({
    message: "Required",
  }),
};

export const zYesNoRequired = z.enum(["Yes", "No"], requiredErrorMap);

export const zStringRequired = z.string().nonempty("Required");

export const yesNo = ["Yes", "No"];

export const uploadSchema = z.object({
  cloudinary_url: z.string(),
  original_file_name: z.string(),
});

export const uploadSchemaRequired = z.object(
  {
    cloudinary_url: z.string(),
    original_file_name: z.string(),
  },
  requiredErrorMap
);
