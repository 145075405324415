import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import { trpc } from "@/lib/trpc";
import { wait } from "@/lib/utils";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

export default function useRaiseInvoiceForProviderLicense() {
  const currentUser = useCurrentUserContext();
  const utils = trpc.useUtils();
  const navigate = useNavigate();
  return trpc.raiseInvoiceForProviderLicense.useMutation({
    onSuccess() {
      toast.success("Invoice raised");
      wait(2000).then(() => {
        utils.getBillingAccountsByOrgId.refetch(currentUser.orgs[0].org_id);
        utils.getOrgById.refetch(currentUser.orgs[0].org_id);
        navigate("/dashboard/reports");
      });
    },
    onError() {
      toast.error(
        "Something went wrong. Please check the reports and try again."
      );
    },
  });
}
