import CompanyProfileForm from "@/components/CompanyProfileForm";
import LicenseApplicationForm from "@/components/LicenseApplicationForm";
import NotesFeed from "@/components/NotesFeed";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { RouterOutputs } from "@/lib/trpc";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable, ViewEditButtonLink } from "./ui/data-table";
import OrgInvoicesTable from "./OrgInvoicesTable";

interface OrgDetailsProps {
  actionsComponent: React.ReactNode;
  companyProfile: {
    isEditable: boolean;
  };
  licenseApplication: {
    isEditable: boolean;
  };
  notes: {
    addNote: (note: string) => void;
  };
  org: NonNullable<RouterOutputs["getOrgById"]>;
}

export default function OrgDetails({
  actionsComponent,
  companyProfile,
  licenseApplication,
  notes,
  org,
}: OrgDetailsProps) {
  const hasLicenseApplication = !!org.license_application;

  return (
    <Tabs defaultValue="profile">
      <TabsList className="mb-12">
        <TabsTrigger value="profile">Company Profile</TabsTrigger>
        {hasLicenseApplication && (
          <TabsTrigger value="application">Application Form</TabsTrigger>
        )}
        <TabsTrigger value="notes">Notes</TabsTrigger>
        <TabsTrigger value="members">Members</TabsTrigger>
        <TabsTrigger value="invoices">Invoices</TabsTrigger>
        <TabsTrigger value="actions">Actions</TabsTrigger>
      </TabsList>
      <TabsContent value="profile">
        <CompanyProfileForm isEditable={companyProfile.isEditable} data={org} />
      </TabsContent>
      {hasLicenseApplication && (
        <TabsContent value="application">
          <LicenseApplicationForm
            isEditable={licenseApplication.isEditable}
            org={org}
          />
        </TabsContent>
      )}
      <TabsContent value="notes">
        <NotesFeed addNote={notes.addNote} notes={org.notes} />
      </TabsContent>
      <TabsContent value="members">
        <OrgMembers org={org} />
      </TabsContent>
      <TabsContent value="invoices">
        <OrgInvoices org={org} />
      </TabsContent>
      <TabsContent value="actions">
        <div className="max-w-2xl">{actionsComponent}</div>
      </TabsContent>
    </Tabs>
  );
}

type OrgUser = NonNullable<RouterOutputs["getOrgById"]>["users"][number] &
  NonNullable<RouterOutputs["getOrgById"]>["memberships"][number];

const userColumns: ColumnDef<OrgUser>[] = [
  {
    id: "view/edit",
    cell: ({ row }) => (
      <ViewEditButtonLink to={`/dashboard/users/${row.original.user_id}`} />
    ),
  },
  { accessorKey: "profile.first_name", header: "First Name" },
  { accessorKey: "profile.last_name", header: "Last Name" },
  {
    accessorKey: "email",
    header: "Email",
    cell: ({ row }) => (
      <a
        href={`mailto:${row.original.email}`}
        target="_blank"
        className="underline underline-offset-4"
      >
        {row.original.email}
      </a>
    ),
  },
  {
    accessorKey: "profile.phone",
    header: "Phone",
    enableGlobalFilter: false,
  },
  {
    header: "Role",
    enableGlobalFilter: false,
    cell: ({ row }) => {
      if (row.original.role) return row.original.role;
    },
  },
];

function OrgMembers({
  org,
}: {
  org: NonNullable<RouterOutputs["getOrgById"]>;
}) {
  const users = org.users.map((a) => {
    const membership = org.memberships.find((m) => m.user_id === a.user_id)!;
    return { ...membership, ...a };
  });
  if (!users) {
    return <div>No users</div>;
  }
  return <DataTable data={users} columns={userColumns} />;
}

function OrgInvoices({
  org,
}: {
  org: NonNullable<RouterOutputs["getOrgById"]>;
}) {
  return <OrgInvoicesTable orgId={org.org_id} />;
}
