import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import UsersTableAA from "@/components/UsersTableAA";
import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { TypographyH3 } from "@/components/ui/Typography";
import { Button } from "@/components/ui/button";
import { DataTable, ViewEditButtonLink } from "@/components/ui/data-table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { RouterOutputs, trpc } from "@/lib/trpc";
import { ColumnDef } from "@tanstack/react-table";
import { MoreHorizontal } from "lucide-react";
import { Link } from "react-router-dom";
import { OrgAcceptMembershipRequestCommand } from "../../../crates/iam/bindings/OrgAcceptMembershipRequestCommand";
import { OrgRemoveInvitationCommand } from "../../../crates/iam/bindings/OrgRemoveInvitationCommand";
import { OrgRemoveMembershipRequestCommand } from "../../../crates/iam/bindings/OrgRemoveMembershipRequestCommand";
import useOrgRemoveInvitation from "@/lib/hooks/mutations/useOrgRemoveInvitation";
import useOrgRemoveMembershipRequest from "@/lib/hooks/mutations/useOrgRemoveMembershipRequest";
import useOrgAcceptMembershipRequest from "@/lib/hooks/mutations/useOrgAcceptMembershipRequest";
import { Separator } from "@/components/ui/separator";
import useOrgGetInvitationToken from "@/lib/hooks/mutations/useOrgGetInvitationToken";
import { getUserMembershipFromUser } from "@/lib/utils";

export default function Users() {
  const currentUser = useCurrentUserContext();
  return (
    <DashboardOutlet title="Users">
      {currentUser.super_user_role ? <UsersTableAA /> : <UsersOrg />}
    </DashboardOutlet>
  );
}

type MembershipRequestDetails = NonNullable<
  RouterOutputs["getOrgById"]
>["pending_membership_requests"][number] & {
  first_name: string;
  last_name: string;
  email: string;
};

type MembershipInvitationDetails = NonNullable<
  RouterOutputs["getOrgById"]
>["pending_membership_invitations"][number];

type OrgMember = NonNullable<
  RouterOutputs["getOrgById"]
>["memberships"][number] &
  NonNullable<RouterOutputs["getOrgById"]>["users"][number];

const membershipRequestColumns: ColumnDef<MembershipRequestDetails>[] = [
  { accessorKey: "first_name", header: "First Name" },
  { accessorKey: "last_name", header: "Last Name" },
  { accessorKey: "email", header: "Email" },
  { accessorKey: "details.position_at_company", header: "Position" },
  { accessorKey: "details.business_unit", header: "Business Unit" },
  {
    id: "memberRequestActions",
    cell: ({ row }) => {
      const removeRequestMutation = useOrgRemoveMembershipRequest();
      const acceptRequestMutation = useOrgAcceptMembershipRequest();
      async function handleAcceptMember(role: "Admin" | "Basic") {
        const body: OrgAcceptMembershipRequestCommand = {
          member_role: role,
          membership_request_id: row.original.membership_request_id,
          org_id: row.original.details.org_id,
        };
        acceptRequestMutation.mutate(body);
      }
      async function handleRejectMembershipRequest() {
        const body: OrgRemoveMembershipRequestCommand = {
          membership_request_id: row.original.membership_request_id,
          org_id: row.original.details.org_id,
        };
        removeRequestMutation.mutate(body);
      }
      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="w-8 h-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="w-4 h-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={() => handleAcceptMember("Basic")}>
              Accept As Basic Member
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => handleAcceptMember("Admin")}>
              Accept As Admin Member
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="bg-red-100"
              onClick={handleRejectMembershipRequest}
            >
              Reject Request
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  },
];

const memberColumns: ColumnDef<OrgMember>[] = [
  {
    id: "view/edit",
    cell: ({ row }) => (
      <ViewEditButtonLink to={`/dashboard/users/${row.original.user_id}`} />
    ),
  },
  { accessorKey: "profile.first_name", header: "First Name" },
  { accessorKey: "profile.last_name", header: "Last Name" },
  { accessorKey: "email", header: "Email" },
  { accessorKey: "role", header: "Role" },
  { accessorKey: "business_unit", header: "Business Unit" },
  { accessorKey: "position_at_company", header: "Position" },
];

const invitedMemberColumns: ColumnDef<MembershipInvitationDetails>[] = [
  { accessorKey: "new_member_email", header: "Email" },
  { accessorKey: "new_member_role", header: "Role" },
  {
    id: "invitedMemberActions",
    cell: ({ row }) => {
      const removeInvitationMutation = useOrgRemoveInvitation();
      async function handleRemoveInvitation() {
        const body: OrgRemoveInvitationCommand = {
          invitation_id: row.original.id,
          org_id: row.original.org_id,
        };
        removeInvitationMutation.mutate(body);
      }
      const getInvitationTokenMutation = useOrgGetInvitationToken();
      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="w-8 h-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="w-4 h-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onClick={() =>
                getInvitationTokenMutation.mutate({
                  org_id: row.original.org_id,
                  invitation_id: row.original.id,
                  email: row.original.new_member_email,
                })
              }
            >
              Copy Invitation Link
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="bg-red-100"
              onClick={handleRemoveInvitation}
            >
              Cancel Invitation
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  },
];

function UsersOrg() {
  const currentUser = useCurrentUserContext();
  const userMembership = getUserMembershipFromUser(currentUser);

  const { isLoading, isError, data } = trpc.getOrgById.useQuery(
    currentUser.orgs[0].org_id
  );
  if (isLoading) return <p>Loading...</p>;
  if (isError || !data) return <p>Error loading org</p>;

  return (
    <div className="space-y-6 lg:space-y-12">
      {userMembership?.role === "Admin" && (
        <Button asChild>
          <Link to="/dashboard/users/invite">Invite New Member</Link>
        </Button>
      )}
      <MembershipRequestsTable org={data} />
      <MembershipInvitationsTable org={data} />
      <OrgMembersTable org={data} />
    </div>
  );
}

function OrgMembersTable({ org }: { org: RouterOutputs["getOrgById"] }) {
  if (!org?.memberships?.length) return null;
  const orgMembers = org.memberships.map((membership) => {
    const user = org.users.find((u) => u.id === membership.user_id)!;
    return {
      ...membership,
      ...user,
    };
  });
  return (
    <div className="space-y-6">
      <TypographyH3 className="text-xl font-bold">Members</TypographyH3>
      <DataTable data={orgMembers} columns={memberColumns} />
    </div>
  );
}

function MembershipRequestsTable({
  org,
}: {
  org: RouterOutputs["getOrgById"];
}) {
  if (!org?.pending_membership_requests?.length) return null;
  const users = trpc.getUsersByIds.useQuery(
    org.pending_membership_requests.map((mr) => mr.details.user_id)
  );
  if (users.isLoading) return <p>Loading...</p>;
  if (users.isError || !users.data) return <p>Error loading users</p>;
  const membershipRequests: MembershipRequestDetails[] = users.data.map(
    (user) => {
      const membershipRequest = org.pending_membership_requests.find(
        (mr) => mr.details.user_id === user.id
      )!;
      return {
        ...membershipRequest,
        first_name: user.profile.first_name,
        last_name: user.profile.last_name,
        email: user.email,
      };
    }
  );
  return (
    <div className="space-y-6">
      <TypographyH3 className="text-xl font-bold">
        Membership Requests
      </TypographyH3>
      <DataTable data={membershipRequests} columns={membershipRequestColumns} />
      <Separator />
    </div>
  );
}

function MembershipInvitationsTable({
  org,
}: {
  org: RouterOutputs["getOrgById"];
}) {
  if (!org?.pending_membership_invitations?.length) return null;

  return (
    <div className="space-y-6">
      <TypographyH3 className="text-xl font-bold">Invitations</TypographyH3>
      <DataTable
        data={org.pending_membership_invitations}
        columns={invitedMemberColumns}
      />
      <Separator />
    </div>
  );
}
