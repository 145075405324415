import {
  Link,
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from "react-router-dom";
import { Button } from "./ui/button";
import DashboardOutlet from "./templates/DashboardOutlet";

const FALLBACK_ERROR_MESSAGE =
  "Sorry, an unexpected error has occurred. Please refresh the page and try again to sign in again.";

export default function DashboardError() {
  const error = useRouteError();
  const actuallyIsrouteErrorResponse = isRouteErrorResponse(error);
  const navigate = useNavigate();

  console.error({ error, actuallyIsrouteErrorResponse });
  const errorMessage = actuallyIsrouteErrorResponse
    ? error?.message || FALLBACK_ERROR_MESSAGE
    : FALLBACK_ERROR_MESSAGE;
  return (
    <DashboardOutlet title="">
      <div className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-slate-900 sm:text-5xl">
            Something went wrong
          </h1>
          <p className="mt-6 text-base leading-7 text-slate-700">
            {errorMessage}
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Button asChild variant="outline">
              <Link to="/dashboard">Home</Link>
            </Button>
            <Button onClick={() => navigate(-1)}>Go Back</Button>
          </div>
        </div>
      </div>
    </DashboardOutlet>
  );
}
