import { Alpha2CountryCode } from "../../../crates/iam/bindings/Alpha2CountryCode";
type Countries = { [k in Alpha2CountryCode]: string[] };
export const countries: Countries = {
  AF: ["Afghanistan"],
  AL: ["Albania"],
  DZ: ["Algeria"],
  AS: ["American Samoa"],
  AD: ["Andorra"],
  AO: ["Angola"],
  AI: ["Anguilla"],
  AQ: ["Antarctica"],
  AG: ["Antigua and Barbuda"],
  AR: ["Argentina"],
  AM: ["Armenia"],
  AW: ["Aruba"],
  AU: ["Australia"],
  AT: ["Austria"],
  AZ: ["Azerbaijan"],
  BS: ["Bahamas (the)", "Bahamas"],
  BH: ["Bahrain"],
  BD: ["Bangladesh"],
  BB: ["Barbados"],
  BY: ["Belarus"],
  BE: ["Belgium"],
  BZ: ["Belize"],
  BJ: ["Benin"],
  BM: ["Bermuda"],
  BT: ["Bhutan"],
  BO: ["Bolivia (Plurinational State of)"],
  BQ: ["Bonaire, Sint Eustatius and Saba"],
  BA: ["Bosnia and Herzegovina"],
  BW: ["Botswana"],
  BV: ["Bouvet Island"],
  BR: ["Brazil"],
  IO: [
    "British Indian Ocean Territory (the)",
    "British Indian Ocean Territory",
  ],
  BN: ["Brunei Darussalam"],
  BG: ["Bulgaria"],
  BF: ["Burkina Faso"],
  BI: ["Burundi"],
  CV: ["Cabo Verde"],
  KH: ["Cambodia"],
  CM: ["Cameroon"],
  CA: ["Canada"],
  KY: ["Cayman Islands (the)", "Cayman Islands"],
  CF: ["Central African Republic (the)", "Central African Republic"],
  TD: ["Chad"],
  CL: ["Chile"],
  CN: ["China"],
  CX: ["Christmas Island"],
  CC: ["Cocos (Keeling) Islands (the)", "Cocos (Keeling) Islands"],
  CO: ["Colombia"],
  KM: ["Comoros (the)"],
  CD: ["Congo (the Democratic Republic of the)"],
  CG: ["Congo (the)"],
  CK: ["Cook Islands (the)"],
  CR: ["Costa Rica"],
  HR: ["Croatia"],
  CU: ["Cuba"],
  CW: ["Curaçao"],
  CY: ["Cyprus"],
  CZ: ["Czechia"],
  CI: ["Côte d'Ivoire"],
  DK: ["Denmark"],
  DJ: ["Djibouti"],
  DM: ["Dominica"],
  DO: ["Dominican Republic (the)", "Dominican Republic"],
  EC: ["Ecuador"],
  EG: ["Egypt"],
  SV: ["El Salvador"],
  GQ: ["Equatorial Guinea"],
  ER: ["Eritrea"],
  EE: ["Estonia"],
  SZ: ["Eswatini"],
  ET: ["Ethiopia"],
  FK: ["Falkland Islands (the) [Malvinas]", "Falkland Islands [Malvinas]"],
  FO: ["Faroe Islands (the)", "Faroe Islands"],
  FJ: ["Fiji"],
  FI: ["Finland"],
  FR: ["France"],
  GF: ["French Guiana"],
  PF: ["French Polynesia"],
  TF: ["French Southern Territories (the)"],
  GA: ["Gabon"],
  GM: ["Gambia (the)"],
  GE: ["Georgia"],
  DE: ["Germany"],
  GH: ["Ghana"],
  GI: ["Gibraltar"],
  GR: ["Greece"],
  GL: ["Greenland"],
  GD: ["Grenada"],
  GP: ["Guadeloupe"],
  GU: ["Guam"],
  GT: ["Guatemala"],
  GG: ["Guernsey"],
  GN: ["Guinea"],
  GW: ["Guinea-Bissau"],
  GY: ["Guyana"],
  HT: ["Haiti"],
  HM: ["Heard Island and McDonald Islands"],
  VA: ["Holy See (the)"],
  HN: ["Honduras"],
  HK: ["Hong Kong"],
  HU: ["Hungary"],
  IS: ["Iceland"],
  IN: ["India"],
  ID: ["Indonesia"],
  IR: ["Iran (Islamic Republic of)"],
  IQ: ["Iraq"],
  IE: ["Ireland"],
  IM: ["Isle of Man"],
  IL: ["Israel"],
  IT: ["Italy"],
  JM: ["Jamaica"],
  JP: ["Japan"],
  JE: ["Jersey"],
  JO: ["Jordan"],
  KZ: ["Kazakhstan"],
  KE: ["Kenya"],
  KI: ["Kiribati"],
  KP: ["Korea (the Democratic People's Republic of)"],
  KR: ["Korea (the Republic of)", "South Korea"],
  KW: ["Kuwait"],
  KG: ["Kyrgyzstan"],
  LA: ["Lao People's Democratic Republic (the)"],
  LV: ["Latvia"],
  LB: ["Lebanon"],
  LS: ["Lesotho"],
  LR: ["Liberia"],
  LY: ["Libya"],
  LI: ["Liechtenstein"],
  LT: ["Lithuania"],
  LU: ["Luxembourg"],
  MO: ["Macao"],
  MG: ["Madagascar"],
  MW: ["Malawi"],
  MY: ["Malaysia"],
  MV: ["Maldives"],
  ML: ["Mali"],
  MT: ["Malta"],
  MH: ["Marshall Islands (the)"],
  MQ: ["Martinique"],
  MR: ["Mauritania"],
  MU: ["Mauritius"],
  YT: ["Mayotte"],
  MX: ["Mexico"],
  FM: ["Micronesia (Federated States of)"],
  MD: ["Moldova (the Republic of)"],
  MC: ["Monaco"],
  MN: ["Mongolia"],
  ME: ["Montenegro"],
  MS: ["Montserrat"],
  MA: ["Morocco"],
  MZ: ["Mozambique"],
  MM: ["Myanmar"],
  NA: ["Namibia"],
  NR: ["Nauru"],
  NP: ["Nepal"],
  NL: ["Netherlands (Kingdom of the)", "Netherlands"],
  NC: ["New Caledonia"],
  NZ: ["New Zealand"],
  NI: ["Nicaragua"],
  NE: ["Niger (the)", "Niger"],
  NG: ["Nigeria"],
  NU: ["Niue"],
  NF: ["Norfolk Island"],
  MK: ["North Macedonia"],
  MP: ["Northern Mariana Islands (the)"],
  NO: ["Norway"],
  OM: ["Oman"],
  PK: ["Pakistan"],
  PW: ["Palau"],
  PS: ["Palestine, State of"],
  PA: ["Panama"],
  PG: ["Papua New Guinea"],
  PY: ["Paraguay"],
  PE: ["Peru"],
  PH: ["Philippines (the)", "Philippines"],
  PN: ["Pitcairn"],
  PL: ["Poland"],
  PT: ["Portugal"],
  PR: ["Puerto Rico"],
  QA: ["Qatar"],
  RO: ["Romania"],
  RU: ["Russian Federation (the)"],
  RW: ["Rwanda"],
  RE: ["Réunion"],
  BL: ["Saint Barthélemy"],
  SH: ["Saint Helena, Ascension and Tristan da Cunha"],
  KN: ["Saint Kitts and Nevis"],
  LC: ["Saint Lucia"],
  MF: ["Saint Martin (French part)"],
  PM: ["Saint Pierre and Miquelon"],
  VC: ["Saint Vincent and the Grenadines"],
  WS: ["Samoa"],
  SM: ["San Marino"],
  ST: ["Sao Tome and Principe"],
  SA: ["Saudi Arabia"],
  SN: ["Senegal"],
  RS: ["Serbia"],
  SC: ["Seychelles"],
  SL: ["Sierra Leone"],
  SG: ["Singapore"],
  SX: ["Sint Maarten (Dutch part)"],
  SK: ["Slovakia"],
  SI: ["Slovenia"],
  SB: ["Solomon Islands"],
  SO: ["Somalia"],
  ZA: ["South Africa"],
  GS: ["South Georgia and the South Sandwich Islands"],
  SS: ["South Sudan"],
  ES: ["Spain"],
  LK: ["Sri Lanka"],
  SD: ["Sudan (the)"],
  SR: ["Suriname"],
  SJ: ["Svalbard and Jan Mayen"],
  SE: ["Sweden"],
  CH: ["Switzerland"],
  SY: ["Syrian Arab Republic (the)"],
  TW: ["Taiwan (Province of China)", "Taiwan"],
  TJ: ["Tajikistan"],
  TZ: ["Tanzania, the United Republic of"],
  TH: ["Thailand"],
  TL: ["Timor-Leste"],
  TG: ["Togo"],
  TK: ["Tokelau"],
  TO: ["Tonga"],
  TT: ["Trinidad and Tobago"],
  TN: ["Tunisia"],
  TM: ["Turkmenistan"],
  TC: ["Turks and Caicos Islands (the)"],
  TV: ["Tuvalu"],
  TR: ["Türkiye"],
  UG: ["Uganda"],
  UA: ["Ukraine"],
  AE: ["United Arab Emirates (the)", "United Arab Emirates"],
  GB: [
    "United Kingdom of Great Britain and Northern Ireland (the)",
    "United Kingdom",
  ],
  UM: ["United States Minor Outlying Islands (the)"],
  US: ["United States of America (the)", "USA"],
  UY: ["Uruguay"],
  UZ: ["Uzbekistan"],
  VU: ["Vanuatu"],
  VE: ["Venezuela (Bolivarian Republic of)", "Venezuela"],
  VN: ["Viet Nam"],
  VG: ["Virgin Islands (British)"],
  VI: ["Virgin Islands (U.S.)"],
  WF: ["Wallis and Futuna"],
  EH: ["Western Sahara*"],
  YE: ["Yemen"],
  ZM: ["Zambia"],
  ZW: ["Zimbabwe"],
  AX: ["Åland Islands"],
};

export const countryNames = Object.values(countries).map((a) => a[0]);
export const countryLabels = Object.values(countries).map((a) => a[1] || a[0]);

export function getCountryLabelFromName(name: string): string {
  const index = countryNames.indexOf(name);
  return countryLabels[index] || countryLabels[index] || "";
}
export function getCountryLabelFromCode(code: Alpha2CountryCode): string {
  return countries[code]?.[1] || countries[code]?.[0] || "";
}
export function getCountryCodeFromName(name: string): Alpha2CountryCode {
  const index = countryNames.indexOf(name);
  return Object.keys(countries)[index] as Alpha2CountryCode;
}
export function getCountryCodeFromLabel(label: string): Alpha2CountryCode {
  const index = countryLabels.indexOf(label);
  return Object.keys(countries)[index] as Alpha2CountryCode;
}
export function getCountryNameFromCode(code: Alpha2CountryCode): string {
  return countries[code][0];
}
export function getCountryNameFromLabel(label: string): string {
  const index = countryLabels.indexOf(label);
  return countryNames[index];
}
