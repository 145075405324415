import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { DataTable, ViewEditButtonLink } from "@/components/ui/data-table";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import { getCountryLabelFromCode } from "@/lib/countries";
import { RouterOutputs, trpc } from "@/lib/trpc";
import { ColumnDef } from "@tanstack/react-table";

type OrgRow = RouterOutputs["getAllOrgs"][number];

const orgTableColumns: ColumnDef<OrgRow>[] = [
  {
    id: "view/edit",
    cell: ({ row }) => (
      <ViewEditButtonLink to={`/dashboard/orgs/${row.original.org_id}`} />
    ),
  },
  {
    accessorFn: (row) =>
      `${row.legal_company_name} ${row.profile?.doing_business_as || ""} ${
        row.profile?.doing_business_as_intl || ""
      }`,
    header: "Name(s)",
    cell: ({ row }) => (
      <div className="space-y-1">
        <p className="text-sm truncate">{row.original?.legal_company_name}</p>
        <p className="text-xs text-muted-foreground truncate">
          {row.original.profile?.doing_business_as}
        </p>
        {row.original.profile?.doing_business_as_intl ? (
          <p className="text-xs text-muted-foreground truncate">
            {row.original.profile.doing_business_as_intl}
          </p>
        ) : null}
      </div>
    ),
  },
  {
    accessorKey: "provider_details.provider_id",
    header: "License Number",
    accessorFn: (row) => row.provider_details?.provider_id || "",
  },
  {
    id: "business_country",
    accessorFn: (row) =>
      getCountryLabelFromCode(row.profile?.business_country as any),
    header: "Business Country",
    cell: ({ row }) =>
      row.original.profile?.business_country
        ? getCountryLabelFromCode(row.original.profile.business_country as any)
        : null,
  },
];

export default function OrgsPage() {
  const currentUser = useCurrentUserContext();
  if (!currentUser.super_user_role) {
    throw new Response("Unauthorized", { status: 401 });
  }
  const latestOrgsQuery = trpc.getAllOrgs.useQuery();

  return (
    <DashboardOutlet title="Organizations">
      {latestOrgsQuery.isLoading ? (
        <div>Loading...</div>
      ) : latestOrgsQuery.isError || !latestOrgsQuery.data ? (
        <div>Error loading orgs</div>
      ) : (
        <DataTable columns={orgTableColumns} data={latestOrgsQuery.data} />
      )}
    </DashboardOutlet>
  );
}
