import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { DataTable, ViewEditButtonLink } from "@/components/ui/data-table";
import { getCountryLabelFromCode } from "@/lib/countries";
import { RouterOutputs, trpc } from "@/lib/trpc";
import { formatFullDate } from "@/lib/utils";
import { ColumnDef } from "@tanstack/react-table";
import { Link } from "react-router-dom";

const taxCertificateRequestColumns: ColumnDef<
  NonNullable<RouterOutputs["getTaxCertificateRequests"]>[number]
>[] = [
  {
    id: "view/edit",
    cell: ({ row }) => (
      <ViewEditButtonLink
        to={`/dashboard/tax-certificate-requests/${row.original.id}`}
      />
    ),
  },
  {
    id: "provider",
    header: "Provider",
    cell: ({ row }) => (
      <Link to={`/dashboard/orgs/${row.original.org.org_id}`}>
        <ul className="text-xs list-none">
          <li>{row.original.org.legal_company_name}</li>
          <li>
            {row.original.org.profile?.doing_business_as}

            {row.original.org.profile?.doing_business_as_intl ? (
              <span> / {row.original.org.profile.doing_business_as_intl}</span>
            ) : null}
          </li>
          <li>
            {getCountryLabelFromCode(
              row.original.org.profile?.business_country as any
            )}
          </li>
        </ul>
      </Link>
    ),
  },
  {
    header: "Country",
    cell: ({ row }) => getCountryLabelFromCode(row.original.country as any),
  },
  {
    header: "Request Text",
    cell: ({ row }) => (
      <p className="truncate max-w-sm">{row.original.requestText}</p>
    ),
  },
  {
    header: "Created At",
    cell: ({ row }) => (
      <p>{formatFullDate(new Date(row.original.created_at))}</p>
    ),
  },
  {
    header: "Resolved At",
    cell: ({ row }) =>
      row.original.resolved_at
        ? formatFullDate(new Date(row.original.resolved_at))
        : "Pending",
  },
  {
    header: "Response",
    cell: ({ row }) => <p className="truncate">{row.original.responseText}</p>,
  },
  {
    header: "Download",
    cell: ({ row }) =>
      row.original.fileUpload ? (
        <a
          href={row.original.fileUpload.file_details.cloudinary_url}
          target="_blank"
          className="underline"
        >
          {row.original.fileUpload.file_details.original_file_name}
        </a>
      ) : null,
  },
];

export default function TaxCertificateRequestsPage() {
  const { data, isLoading, isError } =
    trpc.getTaxCertificateRequests.useQuery();
  console.log(data);

  return (
    <DashboardOutlet title="Tax Certificate Requests">
      {isLoading ? (
        <div>Loading...</div>
      ) : isError ? (
        <p>Error loading tax certificate requests</p>
      ) : (
        <DataTable
          columns={taxCertificateRequestColumns}
          data={data.sort((a, b) => {
            if (a.resolved_at && !b.resolved_at) return -1;
            if (!a.resolved_at && b.resolved_at) return 1;
            return 0;
          })}
        />
      )}
    </DashboardOutlet>
  );
}
