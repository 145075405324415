import { useMutation } from "@tanstack/react-query";
import { UserUpdateEmailCommand } from "../../../../../crates/iam/bindings/UserUpdateEmailCommand";
import { toast } from "sonner";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import { trpc } from "@/lib/trpc";
import { defaultError, wait } from "@/lib/utils";
import { AxiosError } from "axios";
import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";

export default function useUserUpdateEmail() {
  const currentUser = useCurrentUserContext();
  const utils = trpc.useUtils();
  const { axiosInstance: axios } = useAuthTokenContext();
  return useMutation({
    mutationFn: (data: UserUpdateEmailCommand) => {
      return axios(`${import.meta.env.VITE_IAM_API_URL}/user/update-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data,
      });
    },
    onSuccess: (_, variables) => {
      toast.success("Email updated");
      utils.getUserById.setData(currentUser.id, (oldData) => {
        return oldData
          ? {
              ...oldData,
              email: variables.new_email,
            }
          : oldData;
      });
      wait(2500).then(() => utils.getUserById.refetch(variables.user_id));
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
