import DashboardOutlet from "@/components/templates/DashboardOutlet";
import PageLoader from "@/components/ui/PageLoader";
import { TypographyH3, TypographyList } from "@/components/ui/Typography";
import {
  getCountryCodeFromName,
  getCountryLabelFromName,
} from "@/lib/countries";
import useTaxCertificates from "@/lib/hooks/queries/useTaxCertificates";
import { DownloadIcon } from "lucide-react";

export default function TaxCertificatesPage() {
  const { isLoading, isError, data } = useTaxCertificates();
  const filteredData = data?.data
    ?.map((a) => a.attributes)
    .filter((b) => !!b.file.data?.attributes?.url && !b.disable)
    .sort((a, b) => a.country.localeCompare(b.country));

  return (
    <DashboardOutlet title="Tax Certificates">
      {isLoading ? (
        <PageLoader />
      ) : isError ? (
        <div>There was an error, please refresh the page.</div>
      ) : (
        <div>
          <TypographyH3 className="mb-12">
            Downloadable Tax Certificates
          </TypographyH3>
          <TypographyList>
            {filteredData?.map(({ country, file }) => (
              <li
                key={country}
                id={getCountryCodeFromName(country)}
                className="flex gap-3"
              >
                <span>{getCountryLabelFromName(country)}</span>
                <a href={file.data!.attributes.url} target="_blank">
                  <DownloadIcon />
                </a>
              </li>
            ))}
          </TypographyList>
        </div>
      )}
    </DashboardOutlet>
  );
}
