import { UseTRPCMutationResult } from "@trpc/react-query/shared";
import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { FileUploadAction } from "../../../crates/e-licensing/bindings/FileUploadAction";
import { RouterOutputs } from "./trpc";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function copyStringToClipboard(text: string) {
  navigator.clipboard.writeText(text);
}

export function getUserMembershipFromUser(
  user:
    | NonNullable<RouterOutputs["getUserById"]>
    | RouterOutputs["getLatestUsers"][number]
) {
  return user.orgs?.[0]?.memberships.find((a) => a.user_id === user.id);
}

export function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const defaultError = "Something went wrong, please try again.";

export function convertNullToEmptyString<T>(obj: T): T {
  return Object.fromEntries(
    Object.entries(obj as any).map(([key, value]) => [
      key,
      value === null ? "" : value,
    ])
  ) as T;
}

export function convertEmptyStringToNull<T>(obj: T): T {
  return Object.fromEntries(
    Object.entries(obj as any).map(([key, value]) => [
      key,
      value === "" ? null : value,
    ])
  ) as T;
}

export function convertYesNoToBool(field?: "Yes" | "No" | "") {
  if (field === "Yes") {
    return true;
  }
  if (field === "No") {
    return false;
  }
  return null;
}

export function convertBoolToYesNo(field: boolean | null): "Yes" | "No" {
  if (field === true) {
    return "Yes";
  }
  if (field === false) {
    return "No";
  }
  return "" as "Yes" | "No";
}

export function convertFormValueToFileUploadAction(
  input: any
): FileUploadAction {
  if (!input) {
    return "RemoveFile";
  } else {
    return {
      UploadFile: {
        file_details: input,
      },
    };
  }
}

export function generateReportId() {
  const characters = "1234567890ABCDEFGHIJKLNOPQRSTUVWXYZ";
  let result = "";
  for (let i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

const excludedStrings = ["shit", "fuck", "cunt", "cock", "dick"];

function isStringExcluded(str: string) {
  return excludedStrings.some((a) => str.toLowerCase().includes(a));
}

/**
 * Infinitely tries to reserve a random report ID until it succeeds.
 * @param mutation reserveReportId mutation
 * @returns reserved report ID
 */
export async function getReportId(
  mutation: UseTRPCMutationResult<any, any, any, any>
) {
  while (true) {
    const reportId = generateReportId();
    if (isStringExcluded(reportId)) continue;

    try {
      await mutation.mutateAsync(reportId);
      return reportId;
    } catch (e) {
      console.log(e);
      continue;
    }
  }
}

export function demoteHeadings(htmlString: string) {
  return htmlString.replace(
    /<(\/?)h([1-6])>/gi,
    function (_match, closingSlash, headingNumber) {
      if (headingNumber === "6") {
        return `<${closingSlash}p>`;
      } else {
        const newHeadingNumber = parseInt(headingNumber, 10) + 1;
        return `<${closingSlash}h${newHeadingNumber}>`;
      }
    }
  );
}

export function formatFullDate(date: Date, includeYear = true) {
  return new Intl.DateTimeFormat("en-GB", {
    month: "short",
    day: "2-digit",
    year: includeYear ? "numeric" : undefined,
  }).format(date);
}

export const throwErrors = {
  auth: () => {
    throw new Response("You are not authorized to view this page.", {
      status: 401,
    });
  },
};
