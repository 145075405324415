import * as z from "zod";
import { countryLabels } from "../countries";
import { zStringRequired } from "./helpers";

export const billingAccountFormSpec: {
  [key: string]: {
    label: string;
    helperText?: string;
    options?: string[];
  };
} = {
  nickname: {
    label: "Account Nickname / Identifier",
    helperText: "This is for your reference only.",
  },
  email: {
    label: "Email",
    helperText: "This is the email address that will receive invoices",
  },
  company_name: {
    label: "Business Unit or Company Name",
  },
  fao: {
    label: "For the Attention Of (FAO)",
  },
  line1: {
    label: "Address",
    helperText: "Address line 1 (e.g., street, PO Box, or company name).",
  },
  line2: {
    label: "Address 2",
    helperText: "Address line 2 (e.g., apartment, suite, unit, or building).",
  },
  city: {
    label: "City",
    helperText: "City, district, suburb, town, or village.",
  },
  state: {
    label: "State",
    helperText: "State, county, province, or region.",
  },
  postal_code: {
    label: "Postal Code",
    helperText: "ZIP or postal code.",
  },
  country: {
    label: "Country",
    options: countryLabels,
    helperText:
      "This must be included for the address to be included on invoices.",
  },
  phone: {
    label: "Phone",
    helperText: "Please add your full phone number, including country code.",
  },
};

const stripeAddressSchema = z
  .object({
    line1: z.string().optional(),
    line2: z.string().optional(),
    city: z.string().optional(),
    state: z.string().optional(),
    postal_code: z.string().optional(),
    country: z.string().optional(),
  })
  .optional();

export type StripeAddress = z.infer<typeof stripeAddressSchema>;

export const billingAccountSchema = z
  .object({
    company_name: z.string(),
    nickname: zStringRequired,
    email: z.string().email(),
    fao: z.string(),
    phone: z.string().max(20),
  })
  .and(stripeAddressSchema);

export type BillingAccount = z.infer<typeof billingAccountSchema>;
