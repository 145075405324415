import { RouterOutputs } from "@/lib/trpc";
export type UserStatus = "isProvider" | "isOrgMember" | "isNew" | "isSU";

export function getUserStatus(
  currentUser: NonNullable<RouterOutputs["getUserById"]>
): UserStatus {
  if (currentUser.super_user_role) {
    return "isSU";
  }
  if (currentUser.orgs[0]?.provider_details) {
    return "isProvider";
  }
  if (currentUser.orgs.length > 0) {
    return "isOrgMember";
  }
  return "isNew";
}
