import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import FormContextualSaveBar from "@/components/FormContextualSaveBar";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import MultiSelect from "@/components/ui/multi-select";
import {
  countryLabels,
  getCountryCodeFromLabel,
  getCountryLabelFromCode,
} from "@/lib/countries";
import useBillingAccountCreate from "@/lib/hooks/mutations/useBillingAccountCreate";
import useBillingAccountUpdate from "@/lib/hooks/mutations/useBillingAccountUpdate";
import {
  BillingAccount,
  billingAccountFormSpec,
  billingAccountSchema,
} from "@/lib/schemas/billing-account-schema";
import { RouterOutputs } from "@/lib/trpc";
import { zodResolver } from "@hookform/resolvers/zod";
import { Info } from "lucide-react";
import { useForm } from "react-hook-form";

const defaultBillingFormValues = {
  nickname: "",
  email: "",
  company_name: "",
  fao: "",
  line1: "",
  line2: "",
  city: "",
  state: "",
  postal_code: "",
  country: "",
  phone: "",
};

export default function BillingAccountForm({
  data,
  isFirstAccount,
  isNew,
}: {
  data?: NonNullable<RouterOutputs["getOrgById"]>["billingAccounts"][number];
  isFirstAccount?: boolean;
  isNew?: boolean;
}) {
  const currentUser = useCurrentUserContext();
  const billingAccountsCreateMutation = useBillingAccountCreate();
  const billingAccountsUpdateMutation = useBillingAccountUpdate(
    currentUser.orgs[0].org_id
  );
  const existingData = data ? convertBillingAccountDataToFormValues(data) : {};
  const form = useForm({
    resolver: zodResolver(billingAccountSchema),
    defaultValues: defaultBillingFormValues,
    values: isFirstAccount
      ? { ...defaultBillingFormValues, nickname: "Default" }
      : { ...defaultBillingFormValues, ...existingData },
  });
  const handleSubmit = form.handleSubmit(
    async ({ email, company_name, fao, nickname, phone, ...address }) => {
      if (!data) {
        billingAccountsCreateMutation.mutate({
          creation_data: {
            org_id: currentUser.orgs[0].org_id,
            primary_email: email,
            nickname,
            fao: fao || null,
            company_name,
            phone: phone || null,
            address: {
              ...address,
              country: address.country
                ? getCountryCodeFromLabel(address.country)
                : null,
            },
          },
        });
      } else {
        billingAccountsUpdateMutation.mutate({
          billing_account_id: data.billing_account_id,
          update_data: {
            primary_email: email,
            nickname,
            fao: fao || null,
            company_name,
            phone: phone || null,
            address: {
              ...address,
              country: address.country
                ? getCountryCodeFromLabel(address.country)
                : null,
            },
          },
        });
      }
    }
  );

  const showSaveBar = form.formState.isDirty;
  const disableButtons =
    !form.formState.isDirty ||
    billingAccountsCreateMutation.isLoading ||
    billingAccountsUpdateMutation.isLoading;

  return (
    <>
      <FormContextualSaveBar
        show={showSaveBar}
        submit={{ label: isNew ? "Create" : "Update", onPress: handleSubmit }}
        discard={{
          onPress: () =>
            form.reset({ ...defaultBillingFormValues, ...existingData }),
        }}
      />
      <div className="max-w-2xl">
        <div className="bg-accent p-4 rounded-lg prose mb-12">
          <p>
            Please note: only information you enter here will appear on
            invoices.
          </p>
          <p>You can also add notes to invoices on a per-invoice basis.</p>
        </div>
        <Form {...form}>
          <form className="space-y-12" onSubmit={handleSubmit}>
            <FormFieldWrapper
              control={form.control}
              name="nickname"
              render={(field: any) => <Input {...field} />}
            />
            <FormFieldWrapper
              control={form.control}
              name="email"
              render={(field: any) => <Input {...field} />}
            />
            <FormFieldWrapper
              control={form.control}
              name="company_name"
              render={(field: any) => <Input {...field} />}
            />
            <FormFieldWrapper
              control={form.control}
              name="fao"
              render={(field: any) => <Input {...field} />}
            />
            <FormFieldWrapper
              control={form.control}
              name="phone"
              render={(field: any) => <Input {...field} />}
            />
            <div className="bg-accent p-4 rounded-lg space-y-6">
              <p className="mb-6 text-xl font-semibold flex items-center gap-2">
                <Info />
                <span>
                  Address fields will be included on invoices (if supplied).
                  Please note: you must include at least the country for the
                  address to be included on invoices.
                </span>
              </p>
              <FormFieldWrapper
                control={form.control}
                name="line1"
                render={(field: any) => <Input {...field} />}
              />
              <FormFieldWrapper
                control={form.control}
                name="line2"
                render={(field: any) => <Input {...field} />}
              />
              <FormFieldWrapper
                control={form.control}
                name="city"
                render={(field: any) => <Input {...field} />}
              />
              <FormFieldWrapper
                control={form.control}
                name="state"
                render={(field: any) => <Input {...field} />}
              />
              <FormFieldWrapper
                control={form.control}
                name="postal_code"
                render={(field: any) => <Input {...field} />}
              />
              <FormFieldWrapper
                control={form.control}
                name="country"
                render={(field: any) => (
                  <MultiSelect field={field} options={countryLabels} />
                )}
              />
            </div>
            <Button type="submit" disabled={disableButtons}>
              {isNew ? "Create" : "Update"}
            </Button>
          </form>
        </Form>
      </div>
    </>
  );
}

function FormFieldWrapper({
  control,
  name,
  render,
}: {
  control: any;
  name: Extract<keyof typeof billingAccountFormSpec, string>;
  render: any;
}) {
  return (
    <FormField
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormItem>
            <FormLabel
              dangerouslySetInnerHTML={{
                __html: billingAccountFormSpec[name].label,
              }}
            />
            <FormControl>{render(field)}</FormControl>
            <FormDescription>
              {billingAccountFormSpec[name].helperText}
            </FormDescription>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
}

function convertBillingAccountDataToFormValues(
  data: NonNullable<RouterOutputs["getOrgById"]>["billingAccounts"][number]
): BillingAccount {
  return {
    nickname: data.nickname,
    fao: data.fao || "",
    email: data.primary_email,
    company_name: data.company_name,
    country: data.address?.country
      ? getCountryLabelFromCode(data.address.country as any)
      : "",
    city: data.address?.city || "",
    line1: data.address?.line1 || "",
    line2: data.address?.line2 || "",
    postal_code: data.address?.postal_code || "",
    state: data.address?.state || "",
    phone: data.phone || "",
  };
}
