import { RouterOutputs, trpc } from "@/lib/trpc";
import { DataTable, ViewEditButtonLink } from "./ui/data-table";
import { ExternalLinkIcon } from "lucide-react";
import { formatFullDate } from "@/lib/utils";
import { ColumnDef } from "@tanstack/react-table";

const columns: ColumnDef<
  NonNullable<
    RouterOutputs["getBillingAccountsByOrgId"]
  >[number]["invoices"][number]
>[] = [
  {
    id: "view",
    cell: ({ row }) => (
      <ViewEditButtonLink
        to={`/dashboard/invoices/${row.original.invoice_id}`}
      />
    ),
  },
  { header: "Status", accessorKey: "status" },
  {
    header: "Amount",
    cell: ({ row }) => <p>£{Number(row.original.amount_due) / 100}</p>,
  },
  {
    header: "Created",
    cell: ({ row }) => (
      <p>
        {/* @ts-ignore */}
        {formatFullDate(new Date(row.original.stripe_invoice!.created * 1000))}
      </p>
    ),
  },
  {
    header: "Invoice PDF",
    cell: ({ row }) => (
      <a href={row.original.invoice_pdf_link} target="_blank">
        <ExternalLinkIcon className="w-4 h-4" />
      </a>
    ),
  },
  {
    header: "Report IDs",
    cell: ({ row }) => (
      <p>
        {/* @ts-ignore */}
        {row.original.stripe_invoice?.metadata?.aa_elicensing_report_ids
          ?.split(",")
          .join(" ")}
      </p>
    ),
    // @ts-ignore
    accessorFn: (row) => row.stripe_invoice?.metadata?.aa_elicensing_report_ids,
  },
];

export default function OrgInvoicesTable({ orgId }: { orgId: string }) {
  const billingAccountsQuery = trpc.getBillingAccountsByOrgId.useQuery(orgId);
  return billingAccountsQuery.isLoading ? (
    <div>Loading...</div>
  ) : billingAccountsQuery.error ? (
    <div>Error loading invoices.</div>
  ) : (
    <DataTable
      columns={columns}
      data={billingAccountsQuery.data
        .flatMap((a) => a.invoices)
        // @ts-ignore
        .sort((a, b) => b.stripe_invoice!.created - a.stripe_invoice!.created)}
    />
  );
}
