import { RouterOutputs } from "./trpc";

export const earlyMarkingsDownloadsLabelMap = {
  PostReportApproval: "Download after report approved",
  PostReportInvoiceRaised: "Download after report invoiced",
  PostReportPaymentReceipt: "Download after payment received",
} as const;

export const preEngagementCheckLabelMap = {
  NoCheckRequired: "No check",
  FullCheckRequired: "Full check",
} as const;

export const reportStatuses: NonNullable<
  RouterOutputs["getEngagementById"]
>["status"][] = [
  "Published",
  "ReportStarted",
  "ReportSubmitted",
  "Unpublished",
  "Archived",
  "ApplicationStarted",
  "ApplicationSubmitted",
  "PreEngagementApproved",
  "PreEngagementFlaggedForReview",
  "PreEngagementRejected",
  "PostEngagementFlaggedForReview",
  "PostEngagementRejected",
  "PostEngagementApproved",
];
type ReportStatus = (typeof reportStatuses)[number];
type ReportLabelMap = Record<ReportStatus, string>;

export const userReportStatusLabelMap: ReportLabelMap = {
  Published: "Published",
  ReportStarted: "Assurance Statement info: Saved",
  ReportSubmitted: "Assurance Statement info: in AccountAbility Review",
  Unpublished: "Unpublished",
  Archived: "Archived",
  ApplicationStarted: "Pre-engagement: Application Saved",
  ApplicationSubmitted: "Pre-engagement: Application in AccountAbility Review",
  PreEngagementApproved: "Pre-engagement: Application Approved",
  PreEngagementFlaggedForReview:
    "Pre-engagement: Application in AccountAbility Review",
  PreEngagementRejected: "Pre-engagement: Application Rejected",
  PostEngagementFlaggedForReview:
    "Assurance Statement info: in AccountAbility Review",
  PostEngagementRejected: "Assurance Statement info: Rejected",
  PostEngagementApproved: "Proceed to invoicing",
};

export const aaReportStatusLapelMap: ReportLabelMap = {
  Published: "Published",
  ReportStarted: "Statement Saved",
  ReportSubmitted: "Statement in Review",
  Unpublished: "Unpublished",
  Archived: "Archived",
  ApplicationStarted: "Application Saved",
  ApplicationSubmitted: "Application in Review",
  PreEngagementApproved: "Application Approved",
  PreEngagementFlaggedForReview: "Application Flagged",
  PreEngagementRejected: "Application Rejected",
  PostEngagementFlaggedForReview: "Statement Flagged",
  PostEngagementRejected: "Statement Rejected",
  PostEngagementApproved: "Statement Approved",
};

export function getUserReportStatusLabel(
  engagement:
    | NonNullable<RouterOutputs["getEngagementById"]>
    | NonNullable<RouterOutputs["getReportsByProviderId"]>[number]
) {
  if (engagement.status === "ReportStarted") {
    if (!engagement.preEngagement.approval_type) {
      return userReportStatusLabelMap.ApplicationSubmitted;
    }
  }
  if (engagement.status === "PostEngagementApproved") {
    if (engagement.payment_details?.status === "InvoiceRaised") {
      return "Invoice Raised";
    }
    if (engagement.payment_details?.status === "InvoiceVoided") {
      return "Invoice Voided";
    }
  }
  return userReportStatusLabelMap[engagement.status];
}

type PaymentStatusLabel = NonNullable<
  NonNullable<RouterOutputs["getEngagementById"]>["payment_details"]
>["status"];

const paymentStatusLabelMap: Record<PaymentStatusLabel, string> = {
  InvoiceRaised: "Invoice Raised",
  InvoicePaid: "Invoice Paid",
  InvoiceVoided: "Invoice Voided",
  InvoiceMarkedAsUncollectable: "Invoice Marked as Uncollectable",
  PaymentExpected: "Payment Expected",
  PaymentReceivedOffline: "Payment Received Offline",
  ProviderHasUnlimited: "Provider Has Unlimited",
  PaidViaCheckoutComplete: "Paid Online",
};

export function getAAReportStatusLabel(
  engagement:
    | NonNullable<RouterOutputs["getEngagementById"]>
    | NonNullable<RouterOutputs["getReportsByProviderId"]>[number]
    | NonNullable<RouterOutputs["searchReports"]>[number]
) {
  if (engagement.status === "ReportStarted") {
    if (!engagement.preEngagement.approval_type) {
      return aaReportStatusLapelMap.ApplicationSubmitted;
    }
  }
  return aaReportStatusLapelMap[engagement.status];
}

export function getAAPaymentStatusLabel(
  engagement:
    | NonNullable<RouterOutputs["getEngagementById"]>
    | NonNullable<RouterOutputs["getReportsByProviderId"]>[number]
    | NonNullable<RouterOutputs["searchReports"]>[number]
) {
  if (!engagement.payment_details) return "";
  return paymentStatusLabelMap[engagement.payment_details.status];
}

export const licenseApplicationLabelMap = {
  ApplicationStarted: "Application Started",
  ApplicationSubmitted: "Application Submitted",
  ApplicationRejected: "Application Rejected",
  ApplicationApproved: "Application Approved",
  Complete: "Application Complete",
  CustomLicenseAgreementAdded: "Custom License Agreement Added",
  StandardLicenseAgreementAccepted: "Standard License Agreement Accepted",
};

export const postEngagementAIAnalysisLabelMap = {
  inclusivityAnalysis: "Inclusivity",
  materialityAnalysis: "Materiality",
  responsivenessAnalysis: "Responsiveness",
  impactAnalysis: "Impact",
  performanceAnalysis: "Performance",
};
