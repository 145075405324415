import { trpc } from "@/lib/trpc";
import { defaultError, wait } from "@/lib/utils";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";
import { toast } from "sonner";
import { RejectApplicationCommand } from "../../../../../crates/e-licensing/bindings/RejectApplicationCommand";

export default function useLicenseApplicationReject(orgId: string) {
  const utils = trpc.useUtils();
  const { axiosInstance: axios } = useAuthTokenContext();
  return useMutation({
    mutationFn: (data: RejectApplicationCommand) => {
      return axios(
        `${import.meta.env.VITE_ELIC_API_URL}/license-application/reject`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        }
      );
    },
    onSuccess: () => {
      toast.success("Application rejected.");
      wait(2000).then(() => utils.getOrgById.refetch(orgId));
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
