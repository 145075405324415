import * as z from "zod";
import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { TypographyH3 } from "@/components/ui/Typography";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import useOrgAcceptMembershipInvitationExistingUser from "@/lib/hooks/mutations/useOrgAcceptMembershipInvitationExistingUser";
import { zStringRequired } from "@/lib/schemas/helpers";
import { trpc } from "@/lib/trpc";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { OrgAcceptInvitationExistingUserCommand } from "../../../../crates/iam/bindings/OrgAcceptInvitationExistingUserCommand";

const existingUserInvitationSchema = z.object({
  business_unit: z.string().optional(),
  position_at_company: zStringRequired,
});

export default function UserInvitationsPage() {
  const currentUser = useCurrentUserContext();
  const invitations = currentUser.invitations;
  return (
    <DashboardOutlet title="Invitation To Join Organization">
      {invitations.length ? (
        <div className="prose xl:prose-lg prose-slate">
          <p>
            You've been invited to join the organization below to provide
            AA1000AS v3 sustainability assurance via the online platform.
          </p>
          {invitations.length > 1 ? (
            <p>
              <span className="font-semibold">Please note: </span>you can only
              accept one invitation
            </p>
          ) : null}
        </div>
      ) : (
        <p>No invitations available</p>
      )}
      <div className="max-w-2xl">
        <div className="space-y-8">
          {invitations.map((invitationId) => (
            <JoinOrgForm invitationId={invitationId} />
          ))}
        </div>
      </div>
    </DashboardOutlet>
  );
}

function JoinOrgForm({ invitationId }: { invitationId: string }) {
  const { isLoading, isError, data } =
    trpc.getInvitationDataForUser.useQuery(invitationId);
  const currentUser = useCurrentUserContext();
  const acceptInvitationMutation =
    useOrgAcceptMembershipInvitationExistingUser();
  const userForm = useForm({
    resolver: zodResolver(existingUserInvitationSchema),
    defaultValues: {
      business_unit: "",
      position_at_company: "",
    },
  });
  const handleSubmit = userForm.handleSubmit(async (data) => {
    const body: OrgAcceptInvitationExistingUserCommand = {
      ...data,
      user_id: currentUser.user_id,
      org_id: invitationId,
    };
    acceptInvitationMutation.mutate(body);
  });

  if (isLoading) return <p>Loading...</p>;
  if (isError || !data) return <p>Error loading invitation</p>;

  return (
    <div className="p-4 my-12 bg-accent text-accent-foreground">
      <div className="mb-12">
        Org: <span className="font-semibold">{data.legal_company_name}</span>
      </div>
      <Form {...userForm}>
        <TypographyH3 as="h2" className="mb-2">
          Sign up
        </TypographyH3>
        <p className="mb-8">Fill in the form below to accept the invitation.</p>
        <form className="space-y-6" onSubmit={handleSubmit}>
          <FormField
            name="position_at_company"
            control={userForm.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Position At Company</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormDescription></FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="business_unit"
            control={userForm.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Business Unit</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormDescription>Optional.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit" disabled={acceptInvitationMutation.isLoading}>
            Submit
          </Button>
        </form>
      </Form>
    </div>
  );
}
