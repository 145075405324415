import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import { OrgAcceptInvitationNewUserCommand } from "../../../../../crates/iam/bindings/OrgAcceptInvitationNewUserCommand";
import { useNavigate } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { defaultError } from "@/lib/utils";

export default function useOrgAcceptMembershipInvitationNewUser() {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (data: OrgAcceptInvitationNewUserCommand) => {
      return axios({
        url: `${
          import.meta.env.VITE_IAM_API_URL
        }/org/accept-membership-invitation-new-user`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data,
      });
    },
    onSuccess: () => {
      toast.success(
        "Success! You must now log in to verify ownership of this email address."
      );
      navigate("/sign-in");
    },
    onError: (error: AxiosError<{ message: string; code: string }>) => {
      toast.error(error.response?.data?.message || defaultError);
    },
  });
}
