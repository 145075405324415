import * as z from "zod";
import {
  uploadSchema,
  yesNo,
  zStringRequired,
  zYesNoRequired,
} from "./helpers";

export const licenseApplicationFormSpec: {
  [key: string]: {
    label: string;
    helperText?: string;
    options?: string[];
  };
} = {
  why_become_provider: {
    label:
      "Why would your organization like to become a Licensed Assurance Provider?",
  },
  sustainability_esg_years: {
    label: "Sustainability/ESG in general (years)",
  },
  audit_assurance_years: {
    label: "Audit/Assurance (years)",
  },
  certificates_reports_details: {
    label:
      "Provide details of certifications / assurance / audit reports issued in the last 3 years",
  },
  projects_details: {
    label:
      "List any relevant sustainability services not related to assurance carried out in the last 3 years",
  },
  familiar_ap: {
    label:
      "AA1000AP (2018)? <a class='text-primary/60' href='https://aa-files.nyc3.cdn.digitaloceanspaces.com/elicensing/aa1000_accountability_principles_2018.pdf' target='_blank'>View</a>",
    options: yesNo,
  },
  familiar_as: {
    label:
      "AA1000AS v3? <a class='text-primary/60' href='https://aa-files.nyc3.cdn.digitaloceanspaces.com/elicensing/aa1000as_v3_final.pdf' target='_blank'>View</a>",
    options: yesNo,
  },
  familiar_as_guidance: {
    label:
      "Guidance on Applying the AA1000AS v3 for Assurance Providers? <a class='text-primary/60' href='https://aa-files.nyc3.cdn.digitaloceanspaces.com/elicensing/AA1000AS%20v3%20Guidance%20for%20Assurance%20Providers_Feb%202024.pdf' target='_blank'>View</a>",
    options: yesNo,
  },
  familiar_ghg: {
    label:
      "GHG Emissions Guidance? <a class='text-primary/60' href='#' target='_blank'>View</a>",
    options: yesNo,
  },
  have_done_training: {
    label:
      "Have any of your team members completed the official AA1000 Training?",
    options: yesNo,
  },
  trainees: {
    label:
      "Please select the team members who have completed the official AA1000 Training",
  },
  other_names_of_trainees: {
    label:
      "Please add the names of any team members who have completed the official AA1000 Training but are not listed above",
  },
  future_training_plans: {
    label:
      "Please explain your plan to attend the training and/or train your team members in the future",
    helperText:
      "From 1 January 2025, at least one member from the licensed assurance provider engagement team needs to be qualified at AccountAbility Associate CSAP level or above to receive approval to carry out an AA1000AS v3 engagement. Please refer to <a href='https://www.accountability.org/standards/aa1000-individual-certification/' target='_blank' class='underline'>AA1000 Individual Certification options.</a>",
  },
  interest_live_seminars: {
    label: "Live seminars",
  },
  interest_online_learning: {
    label: "Self-paced online learning",
  },
  interest_other: {
    label: "Other specific wishes/needs",
  },
  how_work_code_practice: {
    label:
      "Please read the Code of Practice and describe the internal measures put in place to ensure your organization adheres to the Code. <a class='text-primary/60' href='https://aa-files.nyc3.cdn.digitaloceanspaces.com/elicensing/aa1000as_v3_final.pdf' target='_blank'>View (Appendix D, pp 41-42)</a>",
  },
  company_reg_doc: {
    label:
      "Please attach the local company registration document for the entity the account will be registered under. Maximum file size: 10mb. Supported formats: pdf, docx, png, jpg, wepb, avif.",
  },
  org_application_rejected: {
    label:
      "Has your organization had an application rejected or certification / license refused, withdrawn, or suspended by another Certifying / Standards body? If yes, please give details, including the reason, year and organization which enforced the measures:",
  },
  org_warned: {
    label:
      "Has your organization been formally warned or fined for non-compliance of local / international laws, or any terms of agreements with external parties in the last 3 years? If yes, please give details, including total fines paid, with corrective actions made:",
  },
};

const trainingSectionSchema = z.discriminatedUnion(
  "have_done_training",
  [
    z.object({
      have_done_training: z.literal("Yes"),
      trainees: z.array(z.object({ registration_number: z.string() })),
      other_names_of_trainees: z.string().optional(),
      future_training_plans: z.string(),
    }),
    z.object({
      have_done_training: z.literal("No"),
      future_training_plans: zStringRequired,
      other_names_of_trainees: z.string().optional(),
      trainees: z
        .array(z.object({ registration_number: z.string() }))
        .optional(),
    }),
  ],
  { errorMap: () => ({ message: "Required" }) }
);

export const licenseApplicationSchema = z
  .object({
    why_become_provider: zStringRequired,
    sustainability_esg_years: zStringRequired,
    audit_assurance_years: zStringRequired,
    certificates_reports_details: zStringRequired,
    projects_details: zStringRequired,
    familiar_ap: zYesNoRequired,
    familiar_as: zYesNoRequired,
    familiar_as_guidance: zYesNoRequired,
    familiar_ghg: zYesNoRequired,
    interest_live_seminars: z.boolean(),
    interest_online_learning: z.boolean(),
    interest_other: z.string().optional(),
    how_work_code_practice: zStringRequired,
    company_reg_doc: uploadSchema,
    org_application_rejected: zStringRequired,
    org_warned: zStringRequired,
  })
  .and(trainingSectionSchema);

export type LicenseApplicationType = z.infer<typeof licenseApplicationSchema>;
export type CompletedLicenseApplication = LicenseApplicationType & {
  id: string;
};
