import { TypographyH4 } from "@/components/ui/Typography";
import { Separator } from "@/components/ui/separator";

export default function ActionsSection({
  title,
  children,
}: {
  title?: string;
  children: React.ReactNode | React.ReactNode[];
}) {
  return (
    <div className="space-y-6 mt-6">
      {!!title && <TypographyH4>{title}</TypographyH4>}
      {children}
      <Separator />
    </div>
  );
}
