import AlertDialogWithActions from "@/components/AlertDialogWithActions";
import CopyTextButton from "@/components/CopyTextButton";
import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import MultiSelect from "@/components/ui/multi-select";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import useOrgInviteNewMember from "@/lib/hooks/mutations/useOrgInviteNewMember";
import { requiredErrorMap } from "@/lib/schemas/helpers";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as z from "zod";
import { MemberRole } from "../../../../crates/iam/bindings/MemberRole";

const userInviteFormSchema = z.object({
  new_member_email: z.string().email().describe("Email"),
  new_member_role: z.enum(["Basic", "Admin"], requiredErrorMap),
});

const areYouSureText =
  "Are you sure you authorize this user to register AA1000AS v3 sustainability assurance via the online platform? The user will be able to log assurance engagements and will be invoiced under your organization's account if they submit assurance statements.";

// user fills out invite form
// warning / confirmation dialog
// user clicks continue
// backend generates invite and returns invite ID
// User copies link

export default function InviteUser() {
  const [inviteLink, setInviteLink] = React.useState<string>("");
  const currentUser = useCurrentUserContext();
  const inviteUserMutation = useOrgInviteNewMember(setInviteLink);

  const form = useForm({
    resolver: zodResolver(userInviteFormSchema),
    defaultValues: {
      new_member_email: "",
      new_member_role: "" as MemberRole,
    },
  });
  const handleSubmit = form.handleSubmit(async (data) => {
    inviteUserMutation.mutate({ ...data, org_id: currentUser.orgs[0].org_id });
  });

  function resetAll() {
    form.reset();
    setInviteLink("");
  }

  return (
    <DashboardOutlet title="Invite User">
      <div className="prose xl:prose-lg prose-slate max-w-2xl">
        <p>
          Use the form below to invite individuals in your organization to
          register AA1000AS v3 sustainability assurance via the online platform.
        </p>
      </div>
      <div className="max-w-2xl">
        {inviteLink ? (
          <div className="mt-12 prose xl:prose-lg prose-slate">
            <h2>Success!</h2>
            <p>
              Send the link below to the new user. They will be given
              instructions when they click the link.
            </p>
            <p>
              Please note: you must send the link to the user yourself, it will
              not be sent automatically.
            </p>
            <p>
              You can also manage users and invitations in the{" "}
              <Link to="/dashboard/users">Users Section</Link>.
            </p>
            <div className="space-x-6">
              <CopyTextButton text={inviteLink} label="Copy Link" />
              <Button variant="ghost" onClick={resetAll}>
                Invite Another User
              </Button>
            </div>
            <p>
              You can also right-click <a href={inviteLink}>here</a> to copy the
              link
            </p>
          </div>
        ) : (
          <>
            <Form {...form}>
              <form onSubmit={handleSubmit} className="space-y-12">
                <FormField
                  name="new_member_email"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Email</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  name="new_member_role"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Role</FormLabel>
                        <FormControl>
                          <MultiSelect
                            field={field}
                            options={["Basic", "Admin"]}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <AlertDialogWithActions
                  title="Invite"
                  action={handleSubmit}
                  triggerText="Invite"
                  buttonProps={{
                    type: "button",
                    disabled:
                      inviteUserMutation.isLoading || !form.formState.isValid,
                  }}
                  description={areYouSureText}
                />
              </form>
            </Form>
          </>
        )}
      </div>
    </DashboardOutlet>
  );
}
