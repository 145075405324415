import { useAuthTokenContext } from "@/lib/context/AuthTokenContext";
import { useMutation } from "@tanstack/react-query";
import { ReportSuDownloadMarkingsUncheckedCommand } from "../../../../../crates/e-licensing/bindings/ReportSuDownloadMarkingsUncheckedCommand";
import { toast } from "sonner";
import getReportMarkings from "@/lib/markings/getReportMarkings";

export default function useReportSuDownloadMarkingsUnchecked(
  providerId: string
) {
  const { axiosInstance: axios } = useAuthTokenContext();
  return useMutation({
    mutationFn: (data: ReportSuDownloadMarkingsUncheckedCommand) => {
      return axios({
        url: `${
          import.meta.env.VITE_ELIC_API_URL
        }/report/su-download-markings-unchecked`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data,
      });
    },
    onSuccess(_, variables) {
      getReportMarkings(providerId, variables.report_id);
    },
    onMutate() {
      toast.info("Downloading...");
    },
  });
}
