import ActionsSection from "@/components/ActionsSection";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import MultiSelect from "@/components/ui/multi-select";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import useOrgChangeMemberRole from "@/lib/hooks/mutations/useOrgChangeMemberRole";
import useOrgRemoveUserMembership from "@/lib/hooks/mutations/useOrgRemoveUserMembership";
import useUserUpdateEmail from "@/lib/hooks/mutations/useUserUpdateEmail";
import { RouterOutputs } from "@/lib/trpc";
import { getUserMembershipFromUser } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as z from "zod";
import { ChangeMemberRoleCommand } from "../../../crates/iam/bindings/ChangeMemberRoleCommand";
import { OrgRemoveUserMembershipCommand } from "../../../crates/iam/bindings/OrgRemoveUserMembershipCommand";
import { UserUpdateEmailCommand } from "../../../crates/iam/bindings/UserUpdateEmailCommand";
import AlertDialogWithActions from "./AlertDialogWithActions";
import { useImpersonateContext } from "@/lib/context/ImpersonateContext";

export default function UserActions({
  data,
}: {
  data: NonNullable<RouterOutputs["getUserById"]>;
}) {
  const updateEmailMutation = useUserUpdateEmail();
  const changeRoleMutation = useOrgChangeMemberRole();
  const removeMembershipMutation = useOrgRemoveUserMembership();

  const currentUser = useCurrentUserContext();
  const currentUserMembership = getUserMembershipFromUser(currentUser);
  const userMembership = getUserMembershipFromUser(data);

  const { setImpersonateUserId } = useImpersonateContext();

  const emailForm = useForm({
    resolver: zodResolver(z.object({ email: z.string().email() })),
    values: { email: data?.email || "" },
  });
  const handleChangeEmail = emailForm.handleSubmit(async function ({ email }) {
    const body: UserUpdateEmailCommand = {
      new_email: email,
      user_id: data.id,
    };
    updateEmailMutation.mutate(body);
  });

  const roleForm = useForm({
    resolver: zodResolver(z.object({ role: z.enum(["Basic", "Admin"]) })),
    values: { role: userMembership?.role || ("" as any) },
  });
  const handleChangeRole = roleForm.handleSubmit(async function ({ role }) {
    const body: ChangeMemberRoleCommand = {
      membership_id: userMembership!.id,
      org_id: userMembership!.org_id,
      new_member_role: role,
    };

    changeRoleMutation.mutate(body);
  });

  const canRemoveMembership =
    currentUserMembership?.role === "Admin" || !!currentUser.super_user_role;
  const canUpdateEmail =
    currentUser.id === data.id || !!currentUser.super_user_role;

  async function handleRemoveMembership() {
    const body: OrgRemoveUserMembershipCommand = {
      membership_id: userMembership!.id,
      org_id: data.orgs?.[0]?.org_id,
    };
    removeMembershipMutation.mutate(body);
  }

  return (
    <div className="flex flex-col w-full items-stretch gap-y-6 max-w-2xl">
      <ActionsSection title="Email">
        <Form {...emailForm}>
          <form onSubmit={handleChangeEmail} className="space-y-6">
            <FormField
              name="email"
              control={emailForm.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input readOnly={!canUpdateEmail} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {canUpdateEmail && (
              <AlertDialogWithActions
                title="Update Email"
                triggerText="Update Email"
                buttonProps={{
                  type: "button",
                  disabled:
                    !emailForm.formState.isDirty ||
                    updateEmailMutation.isLoading,
                }}
                action={handleChangeEmail}
              />
            )}
          </form>
        </Form>
      </ActionsSection>
      {!!userMembership?.role && (
        <ActionsSection title="Update Role">
          <Form {...roleForm}>
            <form onSubmit={handleChangeRole}>
              <FormField
                name="role"
                control={roleForm.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Role</FormLabel>
                    <FormControl>
                      <MultiSelect
                        disabled={
                          currentUserMembership?.role !== "Admin" &&
                          !currentUser.super_user_role
                        }
                        field={field}
                        options={["Basic", "Admin"]}
                      />
                    </FormControl>
                    <FormDescription></FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button
                type="submit"
                disabled={
                  !roleForm.formState.isDirty || changeRoleMutation.isLoading
                }
              >
                Update Role
              </Button>
            </form>
          </Form>
        </ActionsSection>
      )}
      {canRemoveMembership && !!userMembership && (
        <ActionsSection title="Organization">
          {!!currentUser.super_user_role && (
            <p>
              Member of:{" "}
              <Link
                className="font-semibold underline"
                to={`/dashboard/orgs/${data.orgs[0].org_id}`}
              >
                {data.orgs[0].legal_company_name}
              </Link>
            </p>
          )}
          <AlertDialogWithActions
            title="Remove Membership"
            triggerText="Remove Membership"
            buttonProps={{
              type: "button",
              variant: "destructive",
            }}
            action={handleRemoveMembership}
          />
        </ActionsSection>
      )}
      {!!currentUser.super_user_role && (
        <ActionsSection title="Impersonate User">
          <Button
            variant="outline"
            onClick={() => setImpersonateUserId(data.user_id)}
          >
            Impersonate this user
          </Button>
        </ActionsSection>
      )}
    </div>
  );
}
